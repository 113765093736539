export interface LaunchBarData {
  applications: {
    name: string;
    title: string;
    url: string;
  }[];
  expiry: { contactSupportLink: string; nonzs: string; zs: string };
  myAccount: string;
  signOut: string;
}

export interface LaunchBarApiResponse {
  applications: LaunchBarData;
  status: string;
}
export interface PatientStageInfo {
  data_source: string;
  therapeutic_area: string;
  sub_therapeutic_area: string;
}

export interface UserDetails {
  auth_status?: string;
  username?: string;
  user_role?: string[];
  session_cookie_details?: any;
  auth_cookie_details?: any;
  permissions?: string[];
}

export enum AppPages {
  EXECUTIVESUMMARY = 'exectuiveSummary',
  CAREGAPDEEPDIVE = 'careGapDeepDive',
  DRIVERS = 'drivers',
  OUTCOMES = 'outcomes',
  ADMIN = 'admin',
  PATIENT_SEGMENTS = 'patientSegments',
  SYSTEMIC_BARRIERS = 'systemicBarriers',
  PROVIDER_INSIGHTS = 'providerInsights',
}

export const SIDEBAR_MENU_ITEMS = {
  [AppPages.CAREGAPDEEPDIVE]: {
    label: 'Care Gaps',
    path: '/care-gaps',
    icon: 'custom:careGapDropdown',
  },
  [AppPages.SYSTEMIC_BARRIERS]: {
    label: 'Systemic Barriers',
    path: '/systemic-barriers',
    icon: 'custom:error-diamond',
  },
  [AppPages.EXECUTIVESUMMARY]: {
    label: 'Executive Summary',
    path: '/datasets',
    icon: 'custom:executiveSummary',
  },
  [AppPages.PROVIDER_INSIGHTS]: {
    label: 'Provider Insights',
    path: '/provider-insights',
    icon: 'custom:hospital',
  },
  [AppPages.DRIVERS]: {
    label: 'Drivers',
    path: '/datasets',
    icon: 'custom:drivers',
  },
  [AppPages.OUTCOMES]: {
    label: 'Outcomes',
    path: '/datasets',
    icon: 'custom:outcomes',
  },
  [AppPages.ADMIN]: {
    label: 'Admin',
    path: '/admin',
    icon: 'custom:team-settings',
  },
  [AppPages.PATIENT_SEGMENTS]: {
    label: 'Patient Segments',
    path: '/patient-segment',
    icon: 'custom:executiveSummary',
  },
};
