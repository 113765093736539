<div
  class="chart-wrapper"
  [ngClass]="!isLoading ? '' : 'invisible'"
>
  <div id="container"></div>
</div>

<div
  class="chart-wrapper-loader"
  [ngClass]="isLoading ? '' : 'invisible'"
>
  <rd-spin
    [spinSize]="'large'"
    [spinnerStyle]="{ 'font-size': '40px', color: '#27a6a4' }"  
  ></rd-spin>
</div>
