import { Component, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import Drilldown from 'highcharts/modules/drilldown';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { CareGapService } from '../../../core/services/care-gap.service';
import { Subscription } from 'rxjs';
NoDataToDisplay(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  Drilldown(Highcharts);
}
declare var require: any;

@Component({
  selector: 'app-state-heatmap',
  templateUrl: './state-heatmap.component.html',
  styleUrls: ['./state-heatmap.component.scss'],
})
export class StateHeatmapComponent {
  chartConstructor = 'mapChart';
  chartOptions = {};
  chart: any;
  drilldown: any;
  properties: any;
  value: any;
  locationDataSub: Subscription;

  Highcharts = Highcharts;

  screenSub: Subscription;

  currentScreen: string = 'careGap';

  @Input() isLoading = false;

  @Input() location;
  @Input() geographyData: any = {};
  @Input() selectedRadio?: string = 'Volume';
  constructor(private careGapService: CareGapService) {}
  ngOnInit() {
    this.screenSub = this.careGapService.currentScreen$.subscribe(
      (data: any) => {
        this.currentScreen = data;
      }
    );
    this.locationDataSub = this.careGapService.locationData$.subscribe(
      (data: any) => {
        if (Object.keys(data).length !== 0) {
          this.location = data;
        }
        // if (this.location.selectedValue !== 'All Locations (National View)' && !this.location.selectedValue.includes(",")) {
        //   this.drillDown(this.location.selectedValue, true);
        // }
      }
    );
    Highcharts.setOptions({
      lang: {
        resetZoom: '',
        noData: 'DATA NOT AVAILABLE',
      },
    });

    Highcharts.wrap(
      Highcharts.Chart.prototype,
      'showResetZoom',
      function (proceed) {
        proceed.apply(this, [].slice.call(arguments, 1));

        var btn = this.resetZoomButton,
          rect = btn.box.element.attributes;
        this.renderer
          .image('assets/images/currentLocationIcon.png', 7, 7, 15, 15)
          .add(btn);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['geographyData'] &&
      Object.keys(this.geographyData)?.length !== 0
    ) {
      if (Object.keys(changes['geographyData'])?.length === 0) {
        this.drillDown(this.location?.selectedValue, false);
      } else {
        if (
          this.location?.selectedValue === 'All Locations (National View)' ||
          changes['geographyData']['currentValue'][
            this.location.selectedValue.toUpperCase()
          ]['total_pat_ct']
        )
          this.drillDown(this.location.selectedValue, true);
      }
    }
    if (
      !changes['selectedRadio']?.firstChange &&
      changes['selectedRadio']?.currentValue !==
        changes['selectedRadio']?.previousValue
    ) {
      if (Object.keys(this.geographyData)?.length === 0) {
        this.drillDown(this.location.selectedValue, false);
      } else {
        if (
          this.location.selectedValue === 'All Locations (National View)' ||
          this.geographyData[this.location.selectedValue.toUpperCase()][
            'total_pat_ct'
          ]
        )
          this.drillDown(this.location.selectedValue, true);
      }
    }
  }

  prepareChart(dataAvailable: any) {
    const usMapData = require('@highcharts/map-collection/countries/us/us-all.topo.json');
    const usMap = Highcharts.geojson(usMapData);
    const mapView = usMapData.objects.default['hc-recommended-mapview'];
    usMap.forEach((el: any, i: any) => {
      el.value = this.geographyData[el.name.toUpperCase()]
        ? this.currentScreen === 'careGap' && this.selectedRadio === 'Volume'
          ? this.geographyData[el.name.toUpperCase()]['total_pat_ct']
          : this.currentScreen === 'careGap' &&
            this.selectedRadio === 'Percentage'
          ? this.geographyData[el.name.toUpperCase()]['total_pat_percent_ct']
          : this.geographyData[el.name.toUpperCase()]['total_pat_ct']
        : 0;
      el.drilldown = el.properties['hc-key'];
      el.segments = this.geographyData[el.name.toUpperCase()]
        ? this.geographyData[el.name.toUpperCase()]['segments']
        : '-';
      el.total_geo_ct = this.geographyData[el.name.toUpperCase()]?.total_geo_ct
        ? this.geographyData[el.name.toUpperCase()]?.total_geo_ct
        : 0;
      el.total_geo_percent_ct = this.geographyData[el.name.toUpperCase()]
        ?.total_geo_percent_ct
        ? this.geographyData[el.name.toUpperCase()]?.total_geo_percent_ct
        : 0;
    });
    let componentThis = this;
    this.chartOptions = {
      chart: {
        zooming: {
          resetButton: {
            position: {
              align: 'right',
              verticalAlign: 'top',
              x: -13,
              y: 195,
            },
          },
        },
        credits: {
          enabled: false,
        },
        events: {
          render(e) {
            if (this.series && this.series.length > 0) {
              if (this.mapView.minZoom !== this.mapView.zoom) {
                if (this.resetZoomButton) this.resetZoomButton.hide();
                this.showResetZoom();
              } else if (this.resetZoomButton) {
                this.resetZoomButton.hide();
              }
            }
          },
        },
      },
      title: {
        text: '',
      },
      mapView,
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'middle',
          align: 'right',
        },
      },
      plotOptions: {
        // boostThreshold: 1,
        map: {
          states: {
            hover: {
              color: '#4dcbc9',
            },
          },
        },
      },
      drilldown: {
        activeDataLabelStyle: {
          color: '#FFFFFF',
          textDecoration: 'none',
          textOutline: '1px #000000',
        },
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: 'rgba(0, 0, 0, 0.85)',
        },
      },
      legend: {
        title: {
          text:
            this.currentScreen === 'careGap' &&
            this.selectedRadio === 'Percentage'
              ? '% of Patients Impacted:'
              : 'No. of Patients Impacted:',
          style: {
            translate: '-170px 20px',
          },
        },
      },
      tooltip: {
        formatter: function () {
          if (componentThis.currentScreen === 'patientSegment')
            return (
              '<b>' +
              this.point.name +
              ': </b>' +
              this.point.value.toLocaleString() +
              '<br>' +
              '<b>' +
              'Segments: </b>' +
              this.point.segments
            );
          else if (
            componentThis.currentScreen === 'careGap' &&
            componentThis.selectedRadio === 'Volume'
          )
            return (
              '<b>' +
              this.point.name +
              ' </b>' +
              '<br>' +
              'Patients Impacted: ' +
              (this.point.value?.toLocaleString() || 0) +
              '<br>' +
              componentThis.location.geoLevels.geo_level_3.toUpperCase() +
              's Impacted: ' +
              this.point.total_geo_ct?.toLocaleString()
            );
          else if (
            componentThis.currentScreen === 'careGap' &&
            componentThis.selectedRadio === 'Percentage'
          )
            return (
              '<b>' +
              this.point.name +
              ' </b>' +
              '<br>' +
              'Patients Impacted: ' +
              this.point.value +
              '% <br>' +
              componentThis.location.geoLevels.geo_level_3.toUpperCase() +
              's Impacted: ' +
              this.point.total_geo_percent_ct +
              '%'
            );
          else
            return (
              '<b>' +
              this.point.name +
              ': </b>' +
              this.point.value.toLocaleString()
            );
        },
      },
    };
    this.chart = Highcharts.mapChart('container', this.chartOptions);
    this.chart.showResetZoom();
    this.chart.resetZoomButton?.hide();
    if (dataAvailable) {
      const values = usMap.map((obj) => obj.value);
      const minVal = Math.min(...values);
      const maxVal = Math.max(...values);

      this.chart.mapNavigation.enabled = false;

      this.chart.addSeries({
        data: usMap,
        name: 'USA',
        custom: {
          mapView,
        },
      });

      this.chart.addColorAxis({
        min: minVal,
        max: maxVal,

        minColor: '#A5ECF3',
        // minColor: '#A5ECF3',
        maxColor: '#2D535F',
        // type:
        //   componentThis &&
        //   componentThis.selectedRadio &&
        //   componentThis.selectedRadio === 'Percentage'
        //     ? 'linear'
        //     : 'logarithmic',
        labels: {
          enabled: true,
          style: {
            textOverflow: 'none',
          },
          formatter: function () {
            if (this.isFirst) return 'Low';
            else if (this.isLast) return 'High';
            else return '';
          },
        },
      });
    }
  }

  resetChart(dataAvailable: any) {
    if (this.chart) this.chart.destroy();
    this.prepareChart(dataAvailable);
  }

  drillDown(pointName: any, dataAvailable: any) {
    this.resetChart(dataAvailable);
    if (dataAvailable && this.chart.series.length > 0) {
      var names = pointName.split(',');
      var state = names[names.length - 1].trim();
      var point = this.chart.series[0].points.find(
        (el: any) => el.name.toUpperCase() === state.toUpperCase()
      );
      if (point) {
        this.doDrillDown(point);
        if (names.length === 2) {
          var county = names[0].trim();
          var countyPoint = this.chart.series[0].points.find(
            (el: any) => el.name.toUpperCase() === county.toUpperCase()
          );
          if (countyPoint) countyPoint.zoomTo();
          this.chart.mapZoom(3);
        }
      }
    }
  }

  doDrillDown(point: any) {
    let mapKey = `countries/us/${point.drilldown}-all`;

    // Handle error, the timeout is cleared on success
    let fail = setTimeout(() => {
      if (!Highcharts.maps[mapKey]) {
        this.chart.showLoading(`
                            <i class="icon-frown"></i>
                            Failed loading ${point.name}
                        `);
        fail = setTimeout(() => {
          this.chart.hideLoading();
        }, 1000);
      }
    }, 3000);

    // Show the Font Awesome spinner
    this.chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>');

    // Load the drilldown map
    const topology = require(`@highcharts/map-collection/${mapKey}.topo.json`);
    const data = Highcharts.geojson(topology);

    // Set a non-random bogus value
    data.forEach((d: any, i: any) => {
      d.value = this.geographyData[point.name.toUpperCase()]
        ? this.geographyData[point.name.toUpperCase()][d.name.toUpperCase()] ||
          0
        : 0;
    });

    // Apply the recommended map view if any
    this.chart.mapView.update(
      Highcharts.merge(
        {
          insets: undefined,
          padding: 0,
        },
        topology.objects.default['hc-recommended-mapview']
      )
    );

    // Hide loading and add series
    this.chart.hideLoading();
    clearTimeout(fail);
    this.chart.addSeriesAsDrilldown(point, {
      name: point.name,
      data: data,
      // dataLabels: {
      //     enabled: true,
      //     format: '{point.name}'
      // }
    });
  }
  ngOnDestroy(): void {
    this.locationDataSub?.unsubscribe();
  }
}
