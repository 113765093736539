import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements OnInit {
  constructor(private elementRef: ElementRef) {}
  @Input() chartData: any;
  @Input() isLoaded = false;
  @Input() graphId: string;
  @Input() screen = '';
  myOptions: any = {
    chart: {
      type: 'bar',
      height: 125,
    },
    title: {
      text: '',
    },
    xAxis: {
      showAxis: false,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      showAxis: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
    },
    colors: ['#A5ECF3', '#62D2D1', '#27A6A4', '#2F6F7B', '#DEDCDE'],
    legend: {
      reversed: true,
      symbolWidth: 16,
      symbolRadius: 0,
      squareSymbol: false,
      // floating: true,
      verticalAlign: 'bottom',
      align: 'left',
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
      column: {
        colorByPoint: true,
      },
    },
    series: [],
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + ': ' + '</b>' + this.y;
      },
      positioner: function (labelWidth, labelHeight, point) {
        var tooltipX, tooltipY;
        tooltipX = this.chart.plotLeft;
        tooltipY = point.plotY + this.chart.plotTop - labelHeight / 2;

        return {
          x: tooltipX,
          y: tooltipY,
        };
      },
    },
  };
  ngOnInit(): void {
    if (this.isLoaded) {
      this.createElement();
      this.generateChartData();
      Highcharts.chart(this.graphId, this.myOptions);
    }
  }

  generateChartData() {
    let screen = this.screen;
    // this.myOptions.xAxis.categories.push(this.chartData?.category);
    this.myOptions.series = this.chartData?.metrics.map((item) => {
      return {
        name: item.category_nm,
        screen: screen,
        data:
          screen === 'care-gap-popup'
            ? [item.percent_metric_val]
            : [item.metric_val],
      };
    });

    this.myOptions.tooltip.formatter = function () {
      return (
        '<b>' +
        this.series.name +
        ': ' +
        '</b>' +
        this.y +
        (screen === 'care-gap-popup' ? '%' : '')
      );
    };
  }
  createElement(): void {
    const elem = this.elementRef.nativeElement.querySelector('.graph');
    elem.setAttribute('id', this.graphId);
  }
}
