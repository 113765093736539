<ng-template #successNotification let-data="data">
  <div class="align-center">
    <rd-icon
      [type]="'check-circle'"
      [iconStyle]="{
        fontSize: '20px',
        'margin-right': '1rem',
        color: '#45C03D'
      }"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>
<ng-template #errorNotification let-data="data">
  <div class="align-center">
    <rd-icon
      [type]="'warning'"
      [iconStyle]="{
        fontSize: '20px',
        'margin-right': '1rem',
        color: '#ffa39e'
      }"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>
<ng-template #warningNotification let-data="data">
  <div class="align-center">
    <rd-icon
      [type]="'exclamation-circle'"
      [iconStyle]="{
        fontSize: '20px',
        'margin-right': '1rem',
        color: '#fffd591'
      }"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>
<ng-template #infoNotification let-data="data">
  <div class="align-center">
    <rd-icon
      [type]="'info-circle'"
      [iconStyle]="{
        fontSize: '20px',
        'margin-right': '1rem',
        color: '#3B9FE7'
      }"
    ></rd-icon>
    <span style="margin-right: 30px;">{{data.message}}</span>
  </div>
</ng-template>
