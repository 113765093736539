import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
} from '@angular/core';
import * as L from 'leaflet';
import * as L1 from 'leaflet.markercluster';
import { SOMETHING_WENT_WRONG, NO_DATA_FOUND } from 'src/app/core/constants';
import { Subscription } from 'rxjs';
import { CareGapService } from 'src/app/core/services/care-gap.service';
@Component({
  selector: 'app-geo-map',
  templateUrl: './geo-map.component.html',
  styleUrls: ['./geo-map.component.scss'],
})
export class GeoMapComponent {
  @Input() mapList: any[];
  @Input() mapName?: string;
  @Input() drillDownValue?: any[];
  private map!: any;
  marker: any;
  noDataFound = NO_DATA_FOUND;
  cluster: any;
  summaryToggleValue = '';
  summaryToggleSub: Subscription;
  constructor(private careGapService: CareGapService) {}

  ngOnInit() {
    this.summaryToggleSub = this.careGapService.summaryToggle$.subscribe(
      (data: any) => {
        this.summaryToggleValue = data;
      }
    );
  }
  ngAfterViewInit(): void {
    this.initMap();
  }
  ngOnChanges(): void {
    this.map?.removeLayer(this.cluster);
    // this.intializeMapComponent()
    if (this.cluster) {
      this.resetView();
      this.map?.closePopup();
    }
    this.setMap();
    if (this.drillDownValue && this.drillDownValue.length !== 0) {
      this.initMap();
      this.drillDownMap();
    } else {
      this.resetView();
    }
  }
  drillDownMap() {
    this.map.setView(this.drillDownValue, 13);
  }
  initMap() {
    this.intializeMapComponent();
    this.setMap();
  }
  intializeMapComponent(): void {
    // if(this.map) {
    //   this.map.remove();
    // }
    this.map = L.map('map', {
      center: { lat: 39.8283, lng: -98.5795 },
      zoom: 4,
      maxZoom: 12,
      fullscreenControl: true,
      attributionControl: false,
    });
    const tiles = L.tileLayer(
      'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }
    );
    tiles.addTo(this.map);
  }
  setMap() {
    this.cluster = new L1.MarkerClusterGroup({
      spiderfyOnMaxZoom: false,
      // showCoverageOnHover: false,
      spiderfyDistanceMultiplier: 3,
      iconCreateFunction: function (cluster) {
        let childCount = cluster.getChildCount();
        let iconSize =
          childCount > 100000
            ? 45
            : childCount > 10000
            ? 40
            : childCount > 1000
            ? 35
            : childCount > 100
            ? 33
            : 30;
        return L.divIcon({
          className: 'cluster',
          html:
            "<div class='cluster-icon' style='width:" +
            iconSize +
            'px; height:' +
            iconSize +
            'px; background-size:' +
            iconSize +
            "px;'>" +
            cluster.getChildCount().toLocaleString() +
            '</div>',
        });
      },
    });

    this.mapList.forEach((item, index) => {
      item.lat !== 'None' && item.long !== 'None' && item.lat && item.long
        ? this.cluster.addLayer(
            (this.marker = L.marker([item.lat, item.long], {
              title: item.nm,
              icon: new L.DivIcon({
                className: 'my-div-icon',
                html:
                  this.mapName !== 'Summary'
                    ? '<div class="marker-icon">' +
                      '<div class="marker-section">' +
                      (this.mapName === 'HCP'
                        ? item.hcp_nm
                        : item.account_name) +
                      '</div></div>'
                    : '<div class="summary-marker-icon">' +
                      '<div class="marker-section">' +
                      item.category_nm +
                      '</div></div>',
              }),
              id: index,
            }).on(
              'click',
              function (e) {
                this.openPopup([item]);
                const id = e.sourceTarget.options.id;
              },
              this
            ))
          )
        : '';
    });
    this.map?.addLayer(this.cluster);
    this.cluster?.on(
      'clusterclick',
      function (e) {
        let points = e.layer.getAllChildMarkers();
        let sameCluster = false;
        let ids = [];
        points.forEach((item) => {
          if (
            (item._latlng.lat === points[0]._latlng.lat &&
              item._latlng.lng === points[0]._latlng.lng) ||
            (item._latlng.lat - points[0]._latlng.lat < 0.0003 &&
              item._latlng.lng - points[0]._latlng.lng < 0.0003)
          ) {
            sameCluster = true;
            ids.push(item.options.id);
          } else {
            sameCluster = false;
          }
        });
        if (sameCluster && e.layer._zoom == 12) {
          let clickedCluster = this.mapList.filter((item, index) => {
            if (ids.includes(index)) {
              return item;
            }
          });
          this.openPopup(clickedCluster);
        }
      },

      this
    );
  }
  resetView(): void {
    const bound = this.cluster?.getBounds();
    let center;
    if (bound._southWest && bound._northEast) {
      center = bound.getCenter();
      this.map?.setView(center, 3);
    } else {
      this.map?.setView([39.61, -105.02], 3); //focusing to map center when no items found
    }
  }
  openPopup(list, notZooming?) {
    if (list?.length === 1 && list[0].lat && list[0].long) {
      L.popup()
        .setLatLng([list[0]?.lat, list[0]?.long])
        .setContent(
          this.mapName !== 'Summary'
            ? this.createAccountContent(list[0])
            : this.createSummaryContent(list[0])
        )
        .openOn(this.map);
    } else if (list?.length > 1) {
      let content =
        '<div class="cluster-title">' +
        (this.mapName === 'Summary' ? this.summaryToggleValue : this.mapName) +
        ' List' +
        '</div><div class="cluster-popup">';
      list.forEach((item) => {
        content =
          content +
          '<div class="map-popup">' +
          (this.mapName !== 'Summary'
            ? this.createAccountContent(item)
            : this.createSummaryContent(item)) +
          '</div>';
      });
      L.popup()
        .setLatLng([list[0]?.lat, list[0]?.long])
        .setContent(content)
        .openOn(this.map);
      content = content + '</div>';
    }
    if (
      list?.length > 1 ||
      (list?.length === 1 && list[0].lat && list[0].long)
    ) {
      if (notZooming) {
        this.map.setView([list[0]?.lat, list[0]?.long]);
      } else {
        this.map.setView([list[0]?.lat + 0.0005, list[0]?.long], 18);
      }
    }
  }
  createSummaryContent(summaryObj) {
    let topHeader =
      "<div class='header-section'> " +
      "<div class='summary-name'>" +
      summaryObj?.category_nm +
      '</div>';

    topHeader = topHeader + '</div>';

    let middleSection =
      "<div class='middle-section'> " +
      'Total Impacted Patients: ' +
      summaryObj?.total_impacted_pat_ct;
    const currentContent =
      "<div class='map-popout'>" + topHeader + middleSection + '</div>';
    return currentContent;
  }
  createAccountContent(accountObj) {
    let topHeader =
      "<div class='header-section'> " +
      "<div class='account-name'>" +
      (this.mapName === 'HCP' ? accountObj?.hcp_nm : accountObj?.account_name) +
      '</div>';

    topHeader = topHeader + '</div>';

    let middleSection =
      "<div class='middle-section'> " +
      "<div class='spec-name'>" +
      accountObj?.state +
      ', ' +
      accountObj?.county +
      '</div>';
    const bottomSection =
      "<div class='middle-section'> " +
      "<div class='spec-name'>" +
      'Total Impacted Patients: ' +
      accountObj?.total_impacted_pat_ct +
      '</div>';
    const currentContent =
      "<div class='map-popout'>" +
      topHeader +
      middleSection +
      bottomSection +
      '</div>';
    return currentContent;
  }
  ngOnDestroy(): void {
    this.summaryToggleSub?.unsubscribe();
  }
}
