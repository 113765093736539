<rd-drawer
  [isVisible]="isVisible"
  [title]="title"
  [width]="'35vw'"
  (drawerClose)="onDrawerClose($event)"
  [maskClosable]="false"
>
  <ng-template #title>
    <div class="title-container">
      <div class="title">HCP Profile</div>
      <rd-button
        [btnType]="'text'"
        [iconStyle]="{ fontSize: '14px', color: '#9C9AA1' }"
        iconType="close"
        (click)="onDrawerClose(true)"
      ></rd-button>
    </div>
  </ng-template>
  <div *ngIf="!isAPIFailed">
    <div class="account-profile">
      <div
        class="basic-details"
        *ngIf="
          isHcpDataLoaded && objectKeys(hcpDetails?.hcp_details)?.length > 0
        "
      >
        <div class="left-details">
          <div class="name">
            <p [title]="hcpDetails?.hcp_details?.hcp_nm">
              {{ hcpDetails?.hcp_details?.hcp_nm }}
            </p>
          </div>
          <label *ngIf="hcpDetails?.hcp_details?.account_name && hcpDetails?.hcp_details?.account_name !== ''" [title]="hcpDetails?.hcp_details?.account_name"
            ><b>Account:</b>
            <span class="account-name" (click)="onAccountNameClick()">{{
              hcpDetails?.hcp_details?.account_name
            }}</span></label
          >
          <label
            *ngIf="
              hcpDetails?.hcp_details?.parent_account_name &&
              hcpDetails?.hcp_details?.parent_account_name !== 'Not Available'
            "
            [title]="hcpDetails?.hcp_details?.parent_account_name"
            ><b>Parent Account:</b>
            {{ hcpDetails?.hcp_details?.parent_account_name }}</label
          >
        </div>
        <div>
          <rd-button
            [iconType]="'download'"
            [iconStyle]="{ 'font-size': '16px' }"
            (btnClick)="onDownloadClick()"
          ></rd-button>
        </div>
      </div>
      <div
        class="account-details"
        *ngIf="
          isHcpDataLoaded && objectKeys(hcpDetails?.hcp_details).length > 0
        "
      >
        <div class="section-title">
          <rd-icon
            [type]="'custom:hospital-outline'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <h3>HCP Details</h3>
        </div>

        <div class="details">
          <div class="item">
            <span>Priority</span>
            <rd-tag
              [mode]="'default'"
              [color]="'#E2FFE3'"
              *ngIf="hcpDetails?.hcp_details?.hcp_priority === 'High'"
              [tagStyle]="{ borderRadius: '8px', color: '#24AF29' }"
            >
              <b>{{ hcpDetails?.hcp_details?.hcp_priority }}</b>
            </rd-tag>
            <rd-tag
              [mode]="'default'"
              [color]="'#FFFADF'"
              [tagStyle]="{ borderRadius: '8px', color: '#E3A900' }"
              *ngIf="hcpDetails?.hcp_details?.hcp_priority === 'Medium'"
            >
              <b>{{ hcpDetails?.hcp_details?.hcp_priority }}</b>
            </rd-tag>
            <rd-tag
              [mode]="'default'"
              [color]="'#FFDEDF'"
              [tagStyle]="{ borderRadius: '8px', color: '#FD595F' }"
              *ngIf="hcpDetails?.hcp_details?.hcp_priority === 'Low'"
              ><b>{{ hcpDetails?.hcp_details?.hcp_priority }}</b></rd-tag
            >
            <rd-tag
              [mode]="'default'"
              [color]="'#D3D3D3'"
              [tagStyle]="{ borderRadius: '8px', color: '#818589' }"
              *ngIf="
                hcpDetails?.hcp_details?.hcp_priority === 'Not Available' ||
                hcpDetails?.hcp_details?.hcp_priority === ''
              "
              ><b>NA</b></rd-tag
            >
          </div>
          <div class="item">
            <span>HCP Speciality</span>
            <p>
              {{ hcpDetails?.hcp_details?.hcp_speciality | titlecase }}
            </p>
          </div>
          <div class="item">
            <span>Practice Setting</span>
            <p>{{ hcpDetails?.hcp_details?.practice_setting | titlecase }}</p>
          </div>
          <div class="row-item">
            <span>{{ hcpDetails?.hcp_details?.call_activity === -1 ? 'NA' : hcpDetails?.hcp_details?.call_activity }}</span>
            <p>Calls/visits made in the last quarter</p>
          </div>
        </div>
      </div>
      <div
        class="patient-chart"
        *ngIf="
          isHcpDataLoaded && hcpDetails?.hcp_caregap_population.length !== 0
        "
      >
        <div class="patient-chart-heading section-title">
          <rd-icon
            [type]="'custom:segment-summary'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <h3>Impacted Patients</h3>
        </div>
        <div class="patient-chart-subheading">
          <span class="total-patient">{{
            hcpDetails?.hcp_details?.total_impacted_pat_ct | number
          }}</span>
          Total Impacted Patients Managed by the HCP
        </div>
        <div class="chart">
          <app-bar-chart
            [chartId]="'hcpCgBarChart'"
            [isLoaded]="isHcpDataLoaded"
            [patientData]="hcpDetails?.hcp_caregap_population"
          ></app-bar-chart>
        </div>

        <div
          class="patient-segment"
          *ngIf="
            isHcpDataLoaded &&
            objectKeys(hcpDetails.patient_segments).length > 0
          "
        >
          <h3>Patient Segment</h3>
          <div class="details">
            <div
              class="segment-id"
              [style.background]="hcpDetails.patient_segments.color"
            >
              {{ hcpDetails.patient_segments.segment_id }}
            </div>
            <div>{{ hcpDetails.patient_segments.segment_name }}</div>
          </div>
        </div>
      </div>

      <div class="demographics">
        <rd-collapse
          *ngIf="isHcpDataLoaded && hcpDetails?.demographics?.length !== 0"
          [expandLocation]="'right'"
          [panels]="demographicPanel"
        >
        </rd-collapse>
        <ng-template #panelHeader>
          <div class="panel-header">
            <rd-icon
              [type]="'custom:data'"
              [iconStyle]="{ 'font-size': '24px' }"
            ></rd-icon>
            <span>Demographic Details</span>
          </div>
        </ng-template>
        <ng-template #demographicContent>
          <div class="demographic-section">
            <div class="chart-heading">Demographic Details</div>
            <div
              class="stacked-chart"
              *ngFor="let item of hcpDetails?.demographics"
            >
              <app-stacked-bar-chart
                [graphId]="item.category + '_hcp'"
                [isLoaded]="isHcpDataLoaded"
                [chartData]="item"
                [screen]="'care-gap-popup'"
              ></app-stacked-bar-chart>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- <div
        class="top-barriers"
        *ngIf="isHcpDataLoaded && barrierGrid.tableData.length > 0"
      >
        <div class="barrier-label">
          <rd-icon
            [type]="'custom:systematic-barriers'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <span class="label">Top 5 Barriers</span>
        </div>
        <div class="barrier-content">
          <rd-table
            [tableColumns]="barrierGrid.tableColumns"
            [tableData]="barrierGrid.tableData"
          ></rd-table>
        </div>
      </div> -->
      <div
        class="top-barriers"
        *ngIf="isHcpDataLoaded && hcpDetails?.systemic_barriers?.length > 0"
      >
        <div class="barrier-label">
          <rd-icon
            [type]="'custom:systematic-barriers'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <span class="label">Top 5 Barriers Across Care Gaps</span>
        </div>
        <div class="barrier-content">
          <div class="sub-heading">
            Barriers correspond to Geography of HCP and are applicable for
            prioritized Hotspots only
          </div>
          <div class="barrier-header">
            <div class="title-header-item">Barriers</div>
            <div class="title-header-item">Relative Importance</div>
          </div>
          <div class="care-gap-section">
            <div
              class="caregap-item"
              *ngFor="let item of hcpDetails?.systemic_barriers"
            >
              <div class="care-gap-name">{{ item?.care_gap_name }}</div>
              <rd-table
                [tableData]="item?.barriers"
                [tableColumns]="barrierGrid.tableColumns"
                [showPagination]="false"
              ></rd-table>
            </div>
          </div>
          <!-- <rd-table
            [tableColumns]="barrierGrid.tableColumns"
            [tableData]="barrierGrid.tableData"
          ></rd-table> -->
        </div>
      </div>
    </div>
  </div>
  <div class="message-section" *ngIf="isAPIFailed || !isHcpDataLoaded">
    <rd-alert
      [closable]="false"
      [type]="'error'"
      [message]="errorMessage"
      [showIcon]="true"
      *ngIf="isAPIFailed"
    ></rd-alert>
    <rd-spin
      [spinSize]="'large'"
      [spinnerStyle]="{ 'font-size': '40px', color: '#27a6a4' }"
      *ngIf="!isHcpDataLoaded"
    ></rd-spin>
  </div>
</rd-drawer>
