<div class="heading">
  <div class="header">
    <div class="heading-text">{{ heading }}</div>
    <div class="new-location-dropdown">
      <!-- <app-cascader-dropdown
        [nodes]="location.items"
        [placeHolder]="'All Locations(National View)'"
        [selectedValue]="location.selectedValue"
        (selectedValueEmitter)="onLocationChange($event, true)"
      >
      </app-cascader-dropdown> -->

      <div
        nz-dropdown
        [nzDropdownMenu]="menu"
        [nzTrigger]="'click'"
        [(nzVisible)]="showDropdown"
        class="dropdown-selector d-flex align-items-center"
        (nzVisibleChange)="visibleDropdownChange($event)"
      >
        <div class="selected-values">
          <img src="assets/images/newLocation.svg" />
          <span>{{ location.selectedValue }}</span>
        </div>
        <i nz-icon nzType="down"></i>
      </div>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="dropdown-section">
          <div class="search-input-container">
            <nz-input-group
              [nzAddOnAfter]="suffixIconButton"
              class="search-input"
            >
              <input
                type="text"
                nz-input
                placeholder="Search Location"
                [(ngModel)]="locationSearchValue"
                (ngModelChange)="valueChange($event)"
              />
            </nz-input-group>
          </div>
          <ng-template #suffixIconButton>
            <button
              nz-button
              nzType="default"
              nzSearch
              style="background-color: white; border: none"
              (ngModelChange)="valueChange($event)"
            >
              <span nz-icon nzType="search"></span>
            </button>
          </ng-template>
          <div
            class="location-data-display"
            *ngIf="locationSearchValue?.length === 0"
          >
            <div class="search-data-container" *ngIf="!location.isLoading">
              <div
                class="data-section"
                *ngIf="savedLocations?.geo_level_3?.length > 0"
              >
                <div class="label">
                  Saved
                  {{
                    location?.geoLevels?.geo_level_3 === "zip"
                      ? "Zips"
                      : "Counties"
                  }}
                </div>
                <div
                  class="location-data"
                  *ngFor="
                    let item of savedLocations?.geo_level_3 | slice : 0 : 4
                  "
                >
                  <div
                    class="location-text"
                    [innerHtml]="
                      item?.geo_level_3_nm + ' (' + item?.geo_level_2_code + ')'
                    "
                    (click)="
                      onLocationChange(
                        item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm,
                        true
                      )
                    "
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:favourite'"
                      [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                      *ngIf="isLocationSaved(item, 'geo_level_3', true)"
                      (click)="
                        unsaveLocation(
                          item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm
                        )
                      "
                    ></rd-icon>
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                      *ngIf="!isLocationSaved(item, 'geo_level_3', true)"
                      (click)="
                        saveLocation(
                          item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm
                        )
                      "
                    ></rd-icon>
                  </div>
                </div>
                <div
                  *ngIf="
                    savedLocations?.geo_level_3?.length > 4 &&
                    !showMoreGeo3Clicked
                  "
                >
                  <div class="more-text" (click)="showMoreGeo3Clicked = true">
                    Show + {{ savedLocations?.geo_level_3?.length - 4 }} more
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    showMoreGeo3Clicked &&
                    savedLocations?.geo_level_3?.length > 4
                  "
                >
                  <div
                    class="location-data"
                    *ngFor="let item of savedLocations?.geo_level_3 | slice : 4"
                  >
                    <div
                      class="location-text"
                      [innerHtml]="
                        item?.geo_level_3_nm +
                        ' (' +
                        item?.geo_level_2_code +
                        ')'
                      "
                      (click)="
                        onLocationChange(
                          item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm,
                          true
                        )
                      "
                    ></div>
                    <div class="saved-location-icon">
                      <rd-icon
                        [type]="'custom:favourite'"
                        [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                        *ngIf="isLocationSaved(item, 'geo_level_3', true)"
                        (click)="
                          unsaveLocation(
                            item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm
                          )
                        "
                      ></rd-icon>
                      <rd-icon
                        [type]="'custom:saveLocation'"
                        [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                        *ngIf="!isLocationSaved(item, 'geo_level_3', true)"
                        (click)="
                          saveLocation(
                            item?.geo_level_3_nm + ', ' + item?.geo_level_2_nm
                          )
                        "
                      ></rd-icon>
                    </div>
                  </div>
                </ng-container>
                <div
                  *ngIf="
                    savedLocations?.geo_level_3?.length > 4 &&
                    showMoreGeo3Clicked
                  "
                >
                  <div class="more-text" (click)="showMoreGeo3Clicked = false">
                    Show less
                  </div>
                </div>
              </div>
              <div
                class="data-section"
                *ngIf="savedLocations?.geo_level_2?.length > 0"
              >
                <div class="label">Saved States</div>
                <div
                  class="location-data"
                  *ngFor="
                    let item of savedLocations?.geo_level_2 | slice : 0 : 4
                  "
                >
                  <div
                    class="location-text"
                    [innerHtml]="item?.geo_level_2_nm"
                    (click)="onLocationChange(item?.geo_level_2_nm, true)"
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:favourite'"
                      [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                      *ngIf="isLocationSaved(item, 'geo_level_2', true)"
                      (click)="unsaveLocation(item?.geo_level_2_nm)"
                    ></rd-icon>
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                      *ngIf="!isLocationSaved(item, 'geo_level_2', true)"
                      (click)="saveLocation(item?.geo_level_2_nm)"
                    ></rd-icon>
                  </div>
                </div>
                <div
                  *ngIf="
                    savedLocations?.geo_level_2?.length > 4 &&
                    !showMoreStateClicked
                  "
                >
                  <div class="more-text" (click)="showMoreStateClicked = true">
                    Show + {{ savedLocations?.geo_level_2?.length - 4 }} more
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    showMoreStateClicked &&
                    savedLocations?.geo_level_2?.length > 4
                  "
                >
                  <div
                    class="location-data"
                    *ngFor="let item of savedLocations?.geo_level_2 | slice : 4"
                  >
                    <div
                      class="location-text"
                      [innerHtml]="item?.geo_level_2_nm"
                      (click)="onLocationChange(item?.geo_level_2_nm, true)"
                    ></div>
                    <div class="saved-location-icon">
                      <rd-icon
                        [type]="'custom:favourite'"
                        [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                        *ngIf="isLocationSaved(item, 'geo_level_2', true)"
                        (click)="unsaveLocation(item?.geo_level_2_nm)"
                      ></rd-icon>
                      <rd-icon
                        [type]="'custom:saveLocation'"
                        [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                        *ngIf="!isLocationSaved(item, 'geo_level_2', true)"
                        (click)="saveLocation(item?.geo_level_2_nm)"
                      ></rd-icon>
                    </div>
                  </div>
                </ng-container>
                <div
                  *ngIf="
                    savedLocations?.geo_level_2?.length > 4 &&
                    showMoreStateClicked
                  "
                >
                  <div class="more-text" (click)="showMoreStateClicked = false">
                    Show less
                  </div>
                </div>
              </div>
              <div
                class="data-section"
                *ngIf="location.geo_level_2?.length > 0"
              >
                <div class="label">All Locations</div>
                <div
                  class="location-data"
                  [ngClass]="
                    location.selectedValue === 'All Locations (National View)'
                      ? 'selectedLocation'
                      : ''
                  "
                >
                  <div
                    class="location-text"
                    [innerHtml]="'All Locations (National View)'"
                    (click)="
                      onLocationChange('All Locations (National View)', true)
                    "
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{
                        fontSize: '16px',
                        cursor: 'default',
                        opacity: '0.5'
                      }"
                    ></rd-icon>
                  </div>
                </div>
                <div
                  class="location-data"
                  *ngFor="let item of location?.geo_level_2"
                  [ngClass]="
                    location?.selectedValue === item?.name
                      ? 'selectedLocation'
                      : ''
                  "
                >
                  <div
                    class="location-text"
                    style="padding-left: 16px"
                    [innerHtml]="item?.name"
                    (click)="onLocationChange(item?.name, true)"
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:favourite'"
                      [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                      *ngIf="isLocationSaved(item, 'geo_level_2')"
                      (click)="unsaveLocation(item?.name)"
                    ></rd-icon>
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                      *ngIf="!isLocationSaved(item, 'geo_level_2')"
                      (click)="saveLocation(item?.name)"
                    ></rd-icon>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="location.isLoading" class="search-spinner">
              <rd-spin></rd-spin>
            </div>
          </div>
          <div
            class="location-search-result-display"
            *ngIf="locationSearchValue?.length > 0 && !location?.isLoading"
          >
            <div class="search-result-number">
              {{
                geolevel2SeachResults?.length + geolevel3SeachResults?.length
              }}
              Search Results
            </div>
            <div
              class="search-data-container"
              *ngIf="!isSearchResultLoading && locationSearchValue?.length > 2"
            >
              <div
                class="data-section"
                *ngIf="geolevel3SeachResults?.length > 0"
              >
                <div class="label">
                  {{
                    location?.geoLevels?.geo_level_3 === "zip"
                      ? "Zips"
                      : "Counties"
                  }}
                </div>
                <div
                  class="location-data"
                  *ngFor="let item of geolevel3SeachResults"
                >
                  <div
                    class="location-text"
                    [innerHtml]="
                      item?.name + ' (' + item?.geo_level_2_code + ')'
                        | highlighter : locationSearchValue : ''
                    "
                    (click)="
                      onLocationChange(
                        item?.name + ', ' + item?.geo_level_2,
                        true
                      );
                      updateRecentSearch(item, 'geo_level_3')
                    "
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:favourite'"
                      [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                      *ngIf="isLocationSaved(item, 'geo_level_3')"
                      (click)="
                        unsaveLocation(item?.name + ', ' + item?.geo_level_2)
                      "
                    ></rd-icon>
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                      *ngIf="!isLocationSaved(item, 'geo_level_3')"
                      (click)="
                        saveLocation(item?.name + ', ' + item?.geo_level_2)
                      "
                    ></rd-icon>
                  </div>
                </div>
              </div>
              <div
                class="data-section"
                *ngIf="geolevel2SeachResults?.length > 0"
              >
                <div class="label">States</div>
                <div
                  class="location-data"
                  *ngFor="let item of geolevel2SeachResults"
                >
                  <div
                    class="location-text"
                    [innerHtml]="
                      item?.name | highlighter : locationSearchValue : ''
                    "
                    (click)="
                      onLocationChange(item?.name, true);
                      updateRecentSearch(item, 'geo_level_2')
                    "
                  ></div>
                  <div class="saved-location-icon">
                    <rd-icon
                      [type]="'custom:favourite'"
                      [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                      *ngIf="isLocationSaved(item, 'geo_level_2')"
                      (click)="unsaveLocation(item?.name)"
                    ></rd-icon>
                    <rd-icon
                      [type]="'custom:saveLocation'"
                      [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                      *ngIf="!isLocationSaved(item, 'geo_level_2')"
                      (click)="saveLocation(item?.name)"
                    ></rd-icon>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="isSearchResultLoading && locationSearchValue?.length <= 2"
              class="search-spinner"
            >
              <rd-spin></rd-spin>
            </div>
            <div
              class="result-not-found"
              *ngIf="
                locationSearchValue?.length > 2 &&
                geolevel2SeachResults?.length +
                  geolevel3SeachResults?.length ===
                  0
              "
            >
              '{{ locationSearchValue }}' did not return any results
            </div>
            <div
              class="result-not-found"
              *ngIf="locationSearchValue?.length <= 2"
            >
              Please type atleast 3 character to get any results
            </div>
            <div
              class="recent-search-section"
              *ngIf="
                locationSearchValue?.length > 2 &&
                geolevel2SeachResults?.length +
                  geolevel3SeachResults?.length ===
                  0
              "
            >
              <div
                class="section-heading"
                *ngIf="
                  recentSearches?.geo_level_2?.length > 0 &&
                  recentSearches?.geo_level_3?.length > 0
                "
              >
                Recent Searches
              </div>
              <div class="search-data-container" *ngIf="!isSearchResultLoading">
                <div
                  class="data-section"
                  *ngIf="recentSearches?.geo_level_3?.length > 0"
                >
                  <div class="label">
                    {{
                      location?.geoLevels?.geo_level_3 === "zip"
                        ? "Zips"
                        : "Counties"
                    }}
                  </div>
                  <div
                    class="location-data"
                    *ngFor="let item of recentSearches?.geo_level_3"
                  >
                    <div
                      class="location-text"
                      [innerHtml]="
                        item?.name + ' (' + item?.geo_level_2_code + ')'
                      "
                      (click)="
                        onLocationChange(
                          item?.name + ', ' + item?.geo_level_2,
                          true
                        )
                      "
                    ></div>
                    <div class="saved-location-icon">
                      <rd-icon
                        [type]="'custom:favourite'"
                        [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                        *ngIf="isLocationSaved(item, 'geo_level_3')"
                        (click)="
                          unsaveLocation(item?.name + ', ' + item?.geo_level_2)
                        "
                      ></rd-icon>
                      <rd-icon
                        [type]="'custom:saveLocation'"
                        [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                        *ngIf="!isLocationSaved(item, 'geo_level_3')"
                        (click)="
                          saveLocation(item?.name + ', ' + item?.geo_level_2)
                        "
                      ></rd-icon>
                    </div>
                  </div>
                </div>
                <div
                  class="data-section"
                  *ngIf="recentSearches?.geo_level_2?.length > 0"
                >
                  <div class="label">States</div>
                  <div
                    class="location-data"
                    *ngFor="let item of recentSearches?.geo_level_2"
                  >
                    <div
                      class="location-text"
                      [innerHtml]="item?.name"
                      (click)="onLocationChange(item?.name, true)"
                    ></div>
                    <div class="saved-location-icon">
                      <rd-icon
                        [type]="'custom:favourite'"
                        [iconStyle]="{ fontSize: '18px', cursor: 'pointer' }"
                        *ngIf="isLocationSaved(item, 'geo_level_2')"
                        (click)="unsaveLocation(item?.name)"
                      ></rd-icon>
                      <rd-icon
                        [type]="'custom:saveLocation'"
                        [iconStyle]="{ fontSize: '16px', cursor: 'pointer' }"
                        *ngIf="!isLocationSaved(item, 'geo_level_2')"
                        (click)="saveLocation(item?.name)"
                      ></rd-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-dropdown-menu>
    </div>
  </div>
  <div class="reset-section" *ngIf="currentScreen !== 'patientSegment'">  
    <div class="reset">
      <rd-button
        btnText="Reset All Filters"
        btnType="default"
        [iconStyle]="{ fontSize: '1rem' }"
        [btnStyle]="{ padding: '0.5rem', fontSize: '1rem' }"
        iconType="custom:undo"
        (btnClick)="onResetClick()"
      ></rd-button>
    </div>
  </div>
</div>

<!-- Stages card code starts here -->

<div class="filtering-section">
  <div class="stages-section">
    <div class="stage-heading">
      <label>Total Vs. Patients Impacted Across Stages</label>
    </div>
    <div class="tiles" *ngIf="stageListStatus === false">
      <rd-tag [mode]="'default'" *ngFor="let index of [1, 2, 3, 4, 5, 6]">
        <div class="tile-content p-8">
          <rd-skeleton-element
            [active]="true"
            [size]="'small'"
            [type]="'input'"
          ></rd-skeleton-element>
          <div class="skeleton-tile-values mt-25">
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
          </div>
          <div class="skeleton-tile-values">
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
            <rd-skeleton-element
              [active]="true"
              [shape]="'default'"
              [size]="'small'"
              [type]="'button'"
            >
            </rd-skeleton-element>
          </div>
        </div>
      </rd-tag>
    </div>
    <div
      class="tiles card-tile"
      *ngIf="stageListStatus"
      [ngStyle]="{
        position: 'relative',
        height: '100%'
      }"
    >
      <drag-scroll
        #headerfilters
        [scrollbar-hidden]="true"
        (reachesLeftBound)="onDragEndLeft($event)"
      >
        <rd-tile
          [mode]="
            stage['total_impacted_pat_ct'] == 0 ||
            currentScreen === 'patientSegment'
              ? 'default'
              : 'checkable'
          "
          *ngFor="let stage of stages"
          [checked]="stage?.['stage_name'] === pageParams.screen"
          (checkedChange)="onStageChange(stage)"
          drag-scroll-item
        >
          <div
            class="tile-content"
            [ngClass]="currentScreen === 'patientSegment' ? 'patientSegmentDisableTile' : stage['total_impacted_pat_ct'] == 0 ? 'disable' : stage?.['stage_name'] === pageParams.screen ? 'checked' : ''"
          >
            <div class="title-header card">
              <div class="title-header-name">
                <p class="stage-name" title="{{ stage?.stage_name }}">
                  {{ stage["stage_name"] }}
                </p>
                <rd-tooltip
                  [tooltipText]="
                    stage['name'] === 'All Stages'
                      ? 'Total patients can be less than 
                sum of patients across Stages 
                owing to one patient with 
                multiple care gaps'
                      : customTooltip
                  "
                  [tooltipPlacement]="'right'"
                  [tooltipOverlayStyle]="{ 'max-width': 'fit-content' }"
                >
                </rd-tooltip>
                <ng-template #customTooltip let-thing>
                  <span style="font-weight: 600; margin-bottom: 8px"
                    >Care Gaps:</span
                  >
                  <br />
                  <div
                    *ngFor="let item of stage['caregaps']"
                    style="
                      margin-bottom: 8px;
                      display: flex;
                      align-items: center;
                    "
                  >
                    <span style="font-weight: 400"
                      >{{ item.care_gap_nm }}:
                    </span>
                    <span>
                      <rd-icon
                        [type]="'custom:person-block'"
                        [iconStyle]="{
                          'margin-right': '5px',
                          'margin-left': '5px',
                          'font-size': '20px'
                        }"
                      ></rd-icon>
                    </span>
                    <span style="font-weight: 700">
                      {{ utils.abbreviateNumber(item.total_impacted_pat_ct) }}
                    </span>
                    <br />
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="tile-values">
              <div class="patient-div">
                <div class="icon">
                  <rd-icon
                    [type]="'custom:population'"
                    [iconStyle]="
                      currentScreen === 'patientSegment'
                        ? { fontSize: '16px', cursor: 'default' }
                        : stage['total_impacted_pat_ct'] == 0
                        ? { fontSize: '16px', cursor: 'not-allowed' }
                        : { fontSize: '16px', cursor: 'pointer' }
                    "
                  ></rd-icon>
                </div>
                <div class="summary-text">
                  <div class="row-2">
                    <h3>
                      Patients:
                      <span>{{
                        utils.abbreviateNumber(stage["total_pat_ct"])
                      }}</span>
                    </h3>
                    <div class="separator">|</div>
                    <div class="danger-icon">
                      <rd-icon
                        [type]="'custom:danger'"
                        [iconStyle]="
                          currentScreen === 'patientSegment'
                            ? { fontSize: '16px', cursor: 'default' }
                            : stage['total_impacted_pat_ct'] == 0
                            ? { fontSize: '16px', cursor: 'not-allowed' }
                            : { fontSize: '16px', cursor: 'pointer' }
                        "
                      ></rd-icon>
                    </div>
                    <div class="impacted-cnt">
                      {{
                        utils.abbreviateNumber(stage["total_impacted_pat_ct"])
                      }}
                    </div>
                    <div class="patient-perc">
                      ({{ stage["percent_impacted_pat_ct"] }}%)
                    </div>
                  </div>
                </div>
              </div>
              <div class="patient-div">
                <div class="icon">
                  <rd-icon
                    [type]="'custom:county'"
                    [iconStyle]="
                      currentScreen === 'patientSegment'
                        ? { fontSize: '16px', cursor: 'default' }
                        : stage['total_impacted_pat_ct'] == 0
                        ? { fontSize: '16px', cursor: 'not-allowed' }
                        : { fontSize: '16px', cursor: 'pointer' }
                    "
                  ></rd-icon>
                </div>
                <div class="summary-text">
                  <!-- <div class="row-1">
                  <div class="label">
                    Total
                    {{
                      this.location.geoLevels["geo_level_3"] === "zip"
                        ? "ZIPs"
                        : "Counties"
                    }}
                    <span>{{
                      utils.abbreviateNumber(stage["total_geo_ct"])
                    }}</span>
                  </div>
                </div>
                <div class="row-2">
                  <h3>
                    Impacted
                    <span>{{
                      utils.abbreviateNumber(stage["total_impacted_geo_ct"])
                    }}</span>
                  </h3>
                  <div class="separator">|</div>
                  <div class="patient-perc">
                    {{ stage["percent_impacted_geo_ct"] }}%
                  </div>
                </div> -->
                  <div class="row-2">
                    <h3>
                      {{
                        this.location.geoLevels["geo_level_3"] === "zip"
                          ? "ZIPs"
                          : "Counties"
                      }}:
                      <span>{{
                        utils.abbreviateNumber(stage["total_geo_ct"])
                      }}</span>
                    </h3>
                    <div class="separator">|</div>
                    <div class="danger-icon">
                      <rd-icon
                        [type]="'custom:danger'"
                        [iconStyle]="
                          currentScreen === 'patientSegment'
                            ? { fontSize: '16px', cursor: 'default' }
                            : { fontSize: '16px', cursor: 'pointer' }
                        "
                      ></rd-icon>
                    </div>
                    <div class="impacted-cnt">
                      {{
                        utils.abbreviateNumber(stage["total_impacted_geo_ct"])
                      }}
                    </div>
                    <div class="patient-perc">
                      ({{ stage["percent_impacted_geo_ct"] }}%)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </rd-tile>
        <button
          class="prev-button"
          [class.hide]="leftHide"
          (click)="arrowLeft()"
        >
          <rd-icon
            [type]="'left'"
            [iconStyle]="{
              'font-size': '10px',
              color: 'white'
            }"
          ></rd-icon>
        </button>
        <button
          class="next-button"
          [class.hide]="rightHide"
          (click)="arrowRight()"
        >
          <rd-icon
            [type]="'right'"
            [iconStyle]="{
              'font-size': '10px',
              color: 'white'
            }"
          ></rd-icon>
        </button>
      </drag-scroll>
    </div>
  </div>
  <div class="care-gap-dropdown-section">
    <div class="filters" *ngIf="displayCareGapDropdown()">
      <label>Care Gaps:</label>
      <div class="filter-items">
        <nz-select
          [nzPlaceHolder]="'All Care Gaps'"
          [(nzOpen)]="isCareGapOpen"
          [ngModel]="careGaps.selectedValue"
          [nzLoading]="careGaps.isLoading"
          [nzCustomTemplate]="multiTemplate"
          [nzMaxTagCount]="1"
          [nzMaxTagPlaceholder]="blankTemplate"
          [nzDropdownRender]="renderTemplate"
          [nzAllowClear]="false"
          [nzAutoClearSearchValue]="false"
          [nzAutoFocus]="true"
          [nzNotFoundContent]="'DATA NOT AVAILABLE'"
          [nzShowArrow]="true"
          (nzOnSearch)="onCareGapsSearch($event)"
          nzShowSearch
          nzMode="multiple"
        >
          <nz-option
            nzCustomContent
            [nzLabel]="'All Care Gaps'"
            [nzValue]="'all'"
          >
            <div class="option-left">
              <label
                nz-checkbox
                [(ngModel)]="careGaps.allChecked"
                [nzIndeterminate]="careGaps.indeterminate"
                (ngModelChange)="updateAllChecked()"
              >
                <p>All Care Gaps</p>
              </label>
            </div>
            <div class="option-right"></div>
          </nz-option>
          <nz-option
            nzCustomContent
            [nzLabel]="option.care_gap_nm"
            [nzValue]="option.care_gap_id"
            *ngFor="let option of careGaps.items"
          >
            <div class="option-left">
              <label
                nz-checkbox
                [(ngModel)]="option.checked"
                (ngModelChange)="updateSingleChecked()"
              >
                <p>{{ option.care_gap_nm }}</p>
              </label>
            </div>
            <div
              class="option-right"
              style="text-align: right"
              *ngIf="option.total_impacted_pat_ct > 0"
            >
              <img
                src="assets/images/careGapHigh.svg"
                *ngIf="option.priority == 'High'"
                title="High priority"
              />
              <img
                src="assets/images/careGapLowPriority.svg"
                *ngIf="option.priority == 'Low'"
                title="Low Priority"
              />
              <img
                src="assets/images/careGapMedium.svg"
                *ngIf="option.priority == 'Medium'"
                title="Low Priority"
              />
              <span>{{
                utils.abbreviateNumber(option.total_impacted_pat_ct)
              }}</span>
            </div>
          </nz-option>
        </nz-select>
        <ng-template #blankTemplate></ng-template>
        <ng-template #multiTemplate>
          <rd-icon
            [type]="'custom:careGap'"
            [iconStyle]="{ 'padding-right': '0.5rem', 'padding-left': '0.5rem' }"
          ></rd-icon>
          <span *ngIf="!careGaps.searchText"
            ><b>{{ careGaps.uiValue }}</b></span
          >
          <!-- <span *ngIf="!careGaps.searchText && careGaps.allChecked"><b>All Care Gaps</b></span>
            <span *ngIf="!careGaps.searchText && !careGaps.allChecked"><b>{{ careGaps.selCount }} Care Gaps
                    Selected</b></span> -->
        </ng-template>
        <ng-template #renderTemplate>
          <nz-divider></nz-divider>
          <div class="container">
            <!-- <button nz-button nzType="default" (click)="onResetClick()">
          Reset
        </button> -->
            <rd-button
              [btnStyle]="{ height: '32px' }"
              [btnType]="'primary'"
              [btnText]="'Apply'"
              (btnClick)="onApplyClick()"
              [isDisabled]="careGaps.isApplyDisabled"
            ></rd-button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="selected-chip" *ngIf="currentScreen !== 'patientSegment'">
      <div class="tag" *ngIf="displaySystemicBarrierTags()">
        <div
          *ngIf="
            careGaps?.previousSelListNames?.length !== careGaps?.items?.length &&
            !careGaps?.allChecked &&
            currentScreen !== 'systemicBarriers'
          "
        >
          <rd-tag
            [mode]="'closeable'"
            [color]="'#ECF8FF'"
            (tagCloseClicked)="onTagRemoved(tag?.name)"
            [tagStyle]="{ borderRadius: '8px', color: '#0952CA' }"
            *ngFor="let tag of careGaps?.selectedChips"
          >
            {{ tag?.name + ": " }}<b>{{ utils.abbreviateNumber(tag?.count) }}</b>
          </rd-tag>
          <!-- <rd-tag *ngIf="isSecondTagVisible()" [mode]="'default'" [color]="'#ECF8FF'"
            [tagStyle]="{ borderRadius: '8px', color: '#0952CA' }">{{ careGaps.previousSelListNames[1] }}</rd-tag> -->
    
          <rd-tag
            *ngIf="
              careGaps?.previousSelListNames?.length -
                careGaps?.selectedChips?.length >
                0 &&
              careGaps?.items?.length > 0 &&
              careGaps?.selectedChips?.length > 0
            "
            [mode]="'default'"
            [color]="'#ECF8FF'"
            [tagStyle]="{ borderRadius: '8px', color: '#0952CA' }"
          >
            +{{
              careGaps?.previousSelListNames?.length -
                careGaps?.selectedChips?.length
            }}
          </rd-tag>
        </div>
      </div>
      </div>
  </div>
</div>

<app-saved-locations
  *ngIf="showLocationsModal"
  [savedLocations]="savedLocations"
  [isVisible]="showLocationsModal"
  (onClose)="onSavedLocationsClose($event)"
></app-saved-locations>
