import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterComponent } from './toaster.component';
import { RDIconModule } from '@zs-ca/angular-cd-library';
@NgModule({
  declarations: [ToasterComponent],
  imports: [CommonModule, RDIconModule],
  exports: [ToasterComponent],
})
export class ToasterModule {}
