import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchBarData } from 'src/app/core/models/sider.model';
import { ApiEndpoints, API_SUCCESS } from 'src/app/core/constants';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';

@Component({
  selector: 'app-sider',
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
})
export class SiderComponent {
  @Input() siderData?: LaunchBarData;

  @Input() menuItemsList: RdSiderMenuItem[] = [];

  showHelpModal = false;

  documentFetchInProgress = false;

  readonly contactSupportList = [
    {
      label: 'Email',
      value: 'support@zs.com',
      href: 'mailto:support@zs.com',
      icon: 'mail',
    },
    {
      label: 'Phone',
      value: 'U.S.: 877-393-9775',
      href: 'tel:8773939775',
      icon: 'phone',
    },
  ];
  readonly businessQueryList = [
    {
      label: 'Email',
      value: 'support@zs.com',
      href: 'mailto:support@zs.com',
      icon: 'mail',
    },
    {
      label: 'Phone',
      value: 'U.S.: 877-393-9775',
      href: 'tel:8773939775',
      icon: 'phone',
    },
  ];

  readonly releaseVersion = 'R1.0';

  constructor(private http: HttpClient, private router: Router) {}

  toggleSupportModal($event: boolean): void {
    this.showHelpModal = $event;
  }

  handleBrandClick(): void {
    this.router.navigateByUrl('/');
  }
  fetchUserGuide() {
    this.documentFetchInProgress = true;
    this.http.get<FaqApiResponse>(ApiEndpoints.FAQ).subscribe({
      next: (response) => {
        this.documentFetchInProgress = false;
        if (response?.status === API_SUCCESS && response?.s3Path?.length) {
          window.open(response.s3Path, '_blank');
        } else {
          // TODO: Handle error.
        }
      },
      error: () => {
        this.documentFetchInProgress = false;
        // TODO: Handle error.
      },
    });
  }

  fetchGlossary() {}
  copyText(copiedText) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', copiedText);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  onLogoutClick(){
    this.http.get(ApiEndpoints.LOGOUT).subscribe({
      next: (res) => {
        window.location.href = res['logout_url'];
      }, error: () => {
      }
    });
  }
}



interface FaqApiResponse {
  status: string;
  message: string;
  s3Path: string;
}
