<div class="chart-wrapper" [ngClass]="isLoading ? 'loading-bg' : ''">
  <div #mapContainer style="height: inherit; width: 100%"></div>
</div>

<div
  class="chart-wrapper-loader"
  [ngClass]="isLoading ? '' : 'invisible'"
  [ngStyle]="{ position: 'absolute', top: 0 }"
>
  <rd-spin
    [spinSize]="'large'"
    [spinnerStyle]="{ 'font-size': '40px', color: '#27a6a4' }"
  ></rd-spin>
</div>
