import { Component, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import { CareGapService } from '../../../core/services/care-gap.service';
import { Subscription } from 'rxjs';

declare var require: any;

@Component({
  selector: 'app-zip-heatmap',
  templateUrl: './zip-heatmap.component.html',
  styleUrls: ['./zip-heatmap.component.scss'],
})
export class ZipHeatmapComponent {
  // drilldown to state basis zip or territory
  chartConstructor = 'mapChart';
  chartOptions = {};
  chart: any;

  Highcharts = Highcharts;

  @Input() zipData: any = {};

  @Input() isLoading = false;

  @Input() selectedLocation = '';

  @Input() isSytemicBarrier = false;

  @Input() selectedRadio?: string = 'Volume';

  screenSub: Subscription;

  currentScreen: string = 'careGap';

  isColorLegendVisible: boolean = true;

  isInitDone: boolean = false;

  zoomcolor: any = '';

  constructor(private careGapService: CareGapService) {}

  ngOnInit() {
    this.screenSub = this.careGapService.currentScreen$.subscribe(
      (data: any) => {
        this.currentScreen = data;
        if (this.currentScreen === 'patientSegment') {
          this.isColorLegendVisible = false;
        } else {
          this.isColorLegendVisible = true;
        }
      }
    );
    Highcharts.setOptions({
      lang: {
        resetZoom: '',
        noData: 'DATA NOT AVAILABLE',
      },
    });
    Highcharts.wrap(
      Highcharts.Chart.prototype,
      'showResetZoom',
      function (proceed) {
        proceed.apply(this, [].slice.call(arguments, 1));

        var btn = this.resetZoomButton,
          rect = btn.box.element.attributes;
        this.renderer
          .image('assets/images/currentLocationIcon.png', 7, 7, 15, 15)
          .add(btn);
      }
    );
    if (!this.isInitDone && Object.keys(this.zipData).length > 0) {
      this.prepareChart();
    }
    this.isInitDone = true;
  }

  ngOnChanges(change: SimpleChanges) {
    if (
      change['zipData'] &&
      Object.keys(this.zipData).length > 0 &&
      this.isInitDone
    ) {
      this.prepareChart();
    }
    if (
      !change['selectedRadio']?.firstChange &&
      change['selectedRadio']?.currentValue !==
        change['selectedRadio']?.previousValue
    ) {
      this.prepareChart();
    }
  }

  prepareChart() {
    const topology = this.zipData;
    const usMap = Highcharts.geojson(topology);
    usMap.forEach((el: any, i: any) => {
      let properties = el.properties;
      el.state = properties.state;
      el.code = properties.zip;
      el.value =
        this.currentScreen === 'careGap'
          ? this.selectedRadio === 'Volume'
            ? properties.value || 0
            : properties.percentage || 0
          : properties.value || 0;
      el.color =
        this.currentScreen === 'patientSegment'
          ? properties.color || '#DEDCDE'
          : properties.color;
    });
    this.chartOptions = {
      chart: {
        zooming: {
          resetButton: {
            position: {
              align: 'right',
              verticalAlign: 'top',
              x: -13,
              y: 205,
            },
          },
        },
        credits: {
          enabled: false,
        },
        events: {
          render(e) {
            if (this.series && this.series.length > 0) {
              if (this.mapView.minZoom !== this.mapView.zoom) {
                if (this.resetZoomButton) this.resetZoomButton.hide();
                this.showResetZoom();
              } else if (this.resetZoomButton) {
                this.resetZoomButton.hide();
              }
            }
          },
        },
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'middle',
          align: 'right',
        },
      },
      legend: {
        enabled: this.isColorLegendVisible,
        title: {
          text:
            this.currentScreen === 'careGap' &&
            this.selectedRadio === 'Percentage'
              ? '% of Patients Impacted:'
              : 'No. of Patients Impacted:',

          style: {
            translate: '-170px 20px',
          },
        },
      },
      plotOptions: {
        map: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          cursor: 'pointer',
          tooltip: {
            headerFormat: '{point.state}',
            pointFormat:
              this.currentScreen === 'careGap'
                ? '{point.code} <br> Impacted Patients: {point.value}' +
                  (this.selectedRadio === 'Volume' ? '' : '%')
                : '{point.state} <br> {point.code}: {point.value}',
          },
        },
      },
      series: [
        {
          data: usMap,
        },
      ],
    };

    // Create the chart
    this.chart = Highcharts.mapChart('zipMapContainer', this.chartOptions);
    this.chart.showResetZoom();
    this.chart.resetZoomButton?.hide();
    if (this.isSytemicBarrier) {
      this.chart.addColorAxis({
        min: 0,
        minColor: this.getColor('minColor'),
        maxColor: this.getColor('maxColor'),
        labels: {
          enabled: true,
          style: {
            textOverflow: 'none',
          },
          formatter: function () {
            if (this.isFirst) return 'Low';
            else if (this.isLast) return 'High';
            else return '';
          },
        },
      });
    } else {
      this.chart.addColorAxis({
        min: 0,
        minColor: '#A5ECF3',
        maxColor: '#2D535F',
        labels: {
          enabled: true,
          style: {
            textOverflow: 'none',
          },
          formatter: function () {
            if (this.isFirst) return 'Low';
            else if (this.isLast) return 'High';
            else return '';
          },
        },
      });
    }
    this.zoomChart();
  }

  getColor(type) {
    let geo_level_3 = '';
    if (this.selectedLocation.includes(',')) {
      geo_level_3 = this.selectedLocation.split(',')[0];
    }
    if (geo_level_3 !== '') {
      this.chart.series[0].points.forEach((point) => {
        let code = point['code'];
        if (geo_level_3 === code) {
          this.zoomcolor = point?.color;
        }
      });
    }

    let minColor = '';
    if (!this.selectedLocation.includes(',')) {
      for (
        let i = 0;
        i < this.zipData?.objects?.zip_codes_for_the_usa?.geometries.length;
        i++
      ) {
        if (
          this.zipData?.objects?.zip_codes_for_the_usa?.geometries[i]
            ?.properties?.value !== 0
        ) {
          minColor =
            this.zipData?.objects?.zip_codes_for_the_usa?.geometries[i]
              ?.properties?.color;
          break;
        }
      }
    }
    return (type = 'minColor'
      ? this.selectedLocation.includes(',')
        ? this.zoomcolor
        : minColor || '#A5ECF3'
      : this.selectedLocation.includes(',')
      ? this.zoomcolor
      : minColor || '#2D535F');
  }

  zoomChart() {
    let geo_level_3 = '';
    if (this.selectedLocation.includes(',')) {
      geo_level_3 = this.selectedLocation.split(',')[0];
    }
    if (geo_level_3 !== '') {
      this.chart.series[0].points.forEach((point) => {
        let code = point['code'];
        if (geo_level_3 === code) {
          point.zoomTo();
          this.chart.mapView.zoomBy(-2);
          return;
        }
      });
    }
  }
}
