<div id="map">
  <div
    class="reset-button"
    id="reset"
    title="Click to Reset Zoom"
    (click)="resetView()"
  >
    <button class="button-style">
      <img src="../../../../assets/images/reset.svg" />
    </button>
  </div>
</div>
