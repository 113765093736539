<div nz-dropdown [nzDropdownMenu]='menu' [nzTrigger]='"click"' [(nzVisible)]='showDropdown'
     class='dropdown-selector d-flex align-items-center'
     [ngStyle]='style'
     (nzVisibleChange)='nzVisibleChange($event)'>
    <div  class='selected-values'>
      <img src="assets/images/newLocation.svg" />
      <span>{{selectedValue}}</span>
    </div>
    <i nz-icon nzType='down'></i>
</div>
<nz-dropdown-menu #menu='nzDropdownMenu'>
    <div class='search-input-container' *ngIf='showTree'>
        <nz-input-group [nzSuffix]='suffixIcon' class='search-input'>
            <input type='text' nz-input placeholder='Search' [(ngModel)]='tempSearchValue' (ngModelChange)="valueChange($event)" />
        </nz-input-group>
    </div>
    <ng-template #suffixIcon>
        <i nz-icon nzType='search' (click)="onSearch()"></i>
    </ng-template>
    <nz-tree *ngIf='showTree'
             [nzData]='nodes'
             [nzHideUnMatched]='true'
             [nzSearchValue]='searchValue'
             [nzTreeTemplate]='nzTreeTemplate'
             [nzSelectedKeys]="selectedLabels"
             (nzClick)="onNodeClick($event)">
    </nz-tree>
    <ng-template #nzTreeTemplate let-node let-origin='origin'>
        <div class='custom-node'>
            <span>
                {{ origin.title}}
            </span>
        </div>
    </ng-template>
</nz-dropdown-menu>
