import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Subject,
  BehaviorSubject,
  catchError,
  map,
  Observable,
  throwError,
} from 'rxjs';
import { ApiEndpoints } from '../constants';
import {
  PatientStageInfo,
  SummaryStageInfo,
  demographicInfo,
} from '../models/care-gap.model';
@Injectable({
  providedIn: 'root',
})
export class CareGapService {
  careGaps = {
    selectedValue: [] as any[],
    items: [] as any[],
    searchText: '',
    isLoading: false,
    selCount: 0,
    allChecked: true,
    indeterminate: false,
    templateValue: [] as any[],
    uiValue: 'All Care Gaps',
    previousSelList: [] as any[],
    previousSelListNames: [] as any[],
    isApplyDisabled: false,
    selectedChips: [] as any[],
  };

  location = {
    selectedValue: 'All Locations (National View)',
    geo_level_3: [] as any[],
    geo_level_2: [] as any[],
    searchText: '',
    isLoading: false,
    items: [] as any[],
    backendValue: [],
    isSaved: false,
    isSaveIconVisible: false,
    subNationId: 100001,
    stateCode: '',
    allNationId: 0,
    geoLevels: {},
    selectedGeoLevel: 1,
    callStages: true,
  };

  provider_oppr_flag;

  stages = [];

  selectedSegments = [];

  selectedSubTherapy$ = new BehaviorSubject('');
  selectedStage$ = new BehaviorSubject({
    // stage_id: 1,
    // stage_name: 'All Stages',
  });
  locationData$ = new BehaviorSubject(this.location);
  stagesData$ = new BehaviorSubject({});
  careGapsData$ = new BehaviorSubject(this.careGaps);
  callAccountList$ = new BehaviorSubject(false);
  callGeographyApi$ = new BehaviorSubject(false);
  updateFilter$ = new BehaviorSubject(false);

  summaryToggle$ = new BehaviorSubject('State');

  selectedZipState$ = new BehaviorSubject(null);
  selectedCountyState$ = new BehaviorSubject(null);
  currentScreen$ = new BehaviorSubject('careGap');
  areCareGapFiltersLoaded$ = new BehaviorSubject(false);

  careDropdownUpdate$ = new Subject();
  updateSegmentProfiler$ = new BehaviorSubject(false);
  updateCareGapProfile$ = new BehaviorSubject(false);

  isRouteChanged$ = new BehaviorSubject(false);
  isSegmentSummaryLoaded$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}
  getStagesInfo(stageInfo: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.PATIENT_JOURNEY_STAGES, stageInfo)
      .pipe(catchError(this.handleError));
  }

  getDemographicSummary(demographicInfo: demographicInfo): Observable<any> {
    return this.http
      .post(ApiEndpoints.DEMOGRAPHIC_SUMMARY, demographicInfo)
      .pipe(catchError(this.handleError));
  }

  getSummaryInfo(summaryInfo: SummaryStageInfo): Observable<any> {
    let body = {
      data_source: summaryInfo.data_source,
      therapeutic_area: summaryInfo.therapeutic_area,
      sub_therapeutic_area: summaryInfo.sub_therapeutic_area,
      stage_id: summaryInfo.stage_id,
      sub_nation_id: summaryInfo.sub_nation_id,
      geo_level: summaryInfo.geo_level,
      care_gap_id: summaryInfo.care_gap_id,
    };
    if (summaryInfo.sub_nation_id) {
      body['sub_nation_id'] = summaryInfo.sub_nation_id;
    }

    return this.http
      .post(ApiEndpoints.SUMMARY, body)
      .pipe(catchError(this.handleError));
  }

  getLocations(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.LOCATION, payload)
      .pipe(catchError(this.handleError));
  }

  getGeography(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.GEOGRAPHY, payload)
      .pipe(catchError(this.handleError));
  }

  getCareGaps(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.CARE_GAPS, payload)
      .pipe(catchError(this.handleError));
  }

  getAccountsList(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ACCOUNT_LIST, payload)
      .pipe(catchError(this.handleError));
  }
  getAccountDrawerDetails(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ACCOUNT_DETAILS, payload)
      .pipe(catchError(this.handleError));
  }

  handleError(errorObj: HttpErrorResponse) {
    return throwError(() => {
      return errorObj.message;
    });
  }

  getCounty(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.COUNTY_MAP_DATA, payload)
      .pipe(catchError(this.handleError));
  }

  getSubTherapeuticArea(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.SUB_THERAPY, payload)
      .pipe(catchError(this.handleError));
  }

  getDateRange(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.DATE_RANGE, payload)
      .pipe(catchError(this.handleError));
  }

  getSavedLocations(): Observable<any> {
    return this.http
      .get(ApiEndpoints.SAVED_LOCATIONS)
      .pipe(catchError(this.handleError));
  }

  saveLocation(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.SAVE_LOCATION, payload);
  }

  deleteLocation(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.DELETE_LOCATION, payload)
      .pipe(catchError(this.handleError));
  }
  getCGDistribution(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.CG_DISTRIBUTION, payload)
      .pipe(catchError(this.handleError));
  }

  getSegmentData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.SEGMENT_SUMMARY, payload)
      .pipe(catchError(this.handleError));
  }
  getSegmentSummaryMap(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.SEGMENT_SUMMARY_MAP, payload);
  }
  getGeographicalSegments(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.GEOGRAPHICAL_SEGMENTS, payload);
  }
  getSegmentProfilerData(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.SEGMENT_PROFILER, payload);
  }
  getCGProfileData(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.CG_PROFILE, payload);
  }

  getCountySummaryData(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PS_THIRD_GEO_LEVEL_SUMMARY, payload);
  }
  getBarrierMapData(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.BARRIER_COUNTY_DATA, payload);
  }
  getBarrierSummary(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.BARRIER_SUMMARY, payload);
  }
  getBarrierCategory(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.BARRIER_CATEGORY, payload);
  }
  getBarrierSummarySelection(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.BARRIER_SUMMARY_SELECTION, payload);
  }

  getHCPDetails(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.HCP_FLYOUT, payload);
  }

  getAccountDetails(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.ACCOUNT_FLYOUT, payload);
  }
  getPracticeSummary(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PRACTICE_SETTING_SUMMARY, payload);
  }
  getPracticeSummaryGraph(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PRACTICE_SETTING_SUMMARY_GRAPH, payload);
  }
  getProviderAccountList(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PROVIDER_ACCOUNT_LIST, payload);
  }
  getProviderHCPList(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PROVIDER_HCP_LIST, payload);
  }
  getProviderOpportunities(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.PROVIDER_OPPORTUNITIES, payload);
  }

  flyoutDownload(payload: any): Observable<any> {
    return this.http.post(ApiEndpoints.FLYOUT_DOWNLOAD, payload);
  }

  getDownloadUrl(filename: string): Observable<any> {
    return this.http.get(ApiEndpoints.DOWNLOAD + '/' + filename);
  }

  getZipData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ZIP_MAP_DATA, payload)
      .pipe(catchError(this.handleError));
  }

  getSegAllSummary(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.SEGMENT_STATE_MAP, payload)
      .pipe(catchError(this.handleError));
  }

  getSegZipSummary(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.SEGMENT_ZIP_MAP, payload)
      .pipe(catchError(this.handleError));
  }

  getBarrierStateMap(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.BARRIER_STATE_MAP, payload)
      .pipe(catchError(this.handleError));
  }

  getBarrierZipMap(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.BARRIER_ZIP_MAP, payload)
      .pipe(catchError(this.handleError));
  }

  getZipSegmentMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ZIP_MAP_SEGMENTS, payload)
      .pipe(catchError(this.handleError));
  }
  getCountySegmentMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.COUNTY_MAP_SEGMENTS, payload)
      .pipe(catchError(this.handleError));
  }

  getZipCaregapMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ZIP_MAP_CAREGAP, payload)
      .pipe(catchError(this.handleError));
  }
  getCountyCaregapMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.COUNTY_MAP_CAREGAP, payload)
      .pipe(catchError(this.handleError));
  }

  getZipBarrierMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.ZIP_MAP_BARRIER, payload)
      .pipe(catchError(this.handleError));
  }
  getCountyBarrierMapData(payload: any): Observable<any> {
    return this.http
      .post(ApiEndpoints.COUNTY_MAP_BARRIER, payload)
      .pipe(catchError(this.handleError));
  }
}
