import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AppPages,
  SIDEBAR_MENU_ITEMS,
  UserDetails,
} from 'src/app/core/models/sider.model';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  private userDetails = new BehaviorSubject<UserDetails>({});

  setUserDetails(userDetails: UserDetails): void {
    this.userDetails.next({ ...userDetails });
  }

  getUserDetails(): Observable<UserDetails> {
    return this.userDetails.asObservable();
  }

  getAvailableScreens(): RdSiderMenuItem[] {
    return [
      SIDEBAR_MENU_ITEMS[AppPages.CAREGAPDEEPDIVE],
      SIDEBAR_MENU_ITEMS[AppPages.SYSTEMIC_BARRIERS],
      SIDEBAR_MENU_ITEMS[AppPages.PATIENT_SEGMENTS],
      SIDEBAR_MENU_ITEMS[AppPages.PROVIDER_INSIGHTS],
    ];
  }
}
