import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-cascader-dropdown',
  templateUrl: './cascader-dropdown.component.html',
  styleUrls: ['./cascader-dropdown.component.scss'],
})
export class CascaderDropdownComponent {
  @Input() placeHolder = 'Select';

  @Input() style = {};

  @Input() nodes = [];

  @Input() selectedValue: string = '';

  @Output() selectedValueEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Input() showDropdown = false;

  showTree = false;

  selectedLabels: string[] = [];

  searchValue = '';
  tempSearchValue = '';

  ngOnChanges() {}

  nzVisibleChange(flag: boolean) {
    this.showTree = flag;
    this.showDropdown = flag;
    this.nodes[0].children.map((node) => (node.expanded = false));
  }

  onNodeClick(event: any) {
    this.selectedLabels = [];
    this.selectedLabels.push(event.node.key);
    this.selectedValueEmitter.emit(event.node.key);
    this.showTree = false;
    this.showDropdown = false;
    this.nodes[0].children.map((node) => (node.expanded = false));
  }

  onSearch() {
    this.searchValue = this.tempSearchValue;
  }

  valueChange(event) {
    if (event.length === 0) {
      this.tempSearchValue = '';
      this.onSearch();
      this.nodes[0].expanded = true;
    }
  }
}
