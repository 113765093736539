/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';
import Supercluster from 'supercluster';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  map: google.maps.Map;
  featureLayer;
  infowindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(0, 0), // offset for icon
  });
  dataLayer;

  private lastZoom = 4;

  showContent;
  showGeoContent;
  removeContent;
  removeGeoContent;
  stateClick;
  countyZipClick;
  markers = [];

  initializeNewMap(mapElement: HTMLElement, options: google.maps.MapOptions) {
    this.map = new google.maps.Map(mapElement, options);
    this.featureLayer = this.map.getFeatureLayer(
      google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1
    );

    const featureStyleOptions = {
      strokeColor: '#bebebe',
      strokeOpacity: 1.0,
      strokeWeight: 0.5,
      fillColor: 'white',
    };

    this.featureLayer.style = (featureStyleFunctionOptions) => {
      return featureStyleOptions;
    };
  }

  clsmarkers = [];

  clickedOnMarker = false;

  async drawClusterMarkers(mapList, mapListFor) {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const componentThis = this;
    for (var i = 0; i < this.clsmarkers?.length; i++) {
      this.clsmarkers[i].setMap(null);
    }

    this.clsmarkers = [];

    const zoom = componentThis.map.getZoom();

    let index = new Supercluster({
      radius: 180,
      maxZoom: 18,
    }).load(mapList);

    let clusteredData = index.getClusters([-180, -90, 180, 90], zoom);

    var infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, 0),
    });

    for (let data of clusteredData) {
      if (data.properties.cluster) {
        const clusterPoints = index.getLeaves(
          data.properties.cluster_id,
          Infinity
        );

        const isSamePosition = clusterPoints.every(
          (point, index, array) =>
            point.geometry.coordinates[0] ===
              array[0].geometry.coordinates[0] &&
            point.geometry.coordinates[1] === array[0].geometry.coordinates[1]
        );

        data.properties['isSame'] = isSamePosition;

        var content = document.createElement('div');
        content.innerHTML = `
        <div class = "cluster-icon">
          <div class="cluster-icon-text">
             ${data.properties.point_count_abbreviated.toLocaleString()}
          </div>
        </div>
        `;

        var marker = new AdvancedMarkerElement({
          position: {
            lat: data.geometry.coordinates[1],
            lng: data.geometry.coordinates[0],
          },
          content,
          map: this.map,
          zIndex: 2,
        });

        marker.addListener('click', () => {
          const zoomToExpand = index.getClusterExpansionZoom(
            data.properties.cluster_id
          );

          if (data.properties['isSame']) {
            this.clickedOnMarker = true;

            this.map.setCenter({
              lat: data.geometry.coordinates[1],
              lng: data.geometry.coordinates[0],
            });

            infoWindow.setContent(
              this.multipleListContent(clusterPoints, mapListFor)
            );
            infoWindow.setOptions({
              pixelOffset: new google.maps.Size(0, -20),
            });
            infoWindow.setPosition(
              new google.maps.LatLng(
                data.geometry.coordinates[1],
                data.geometry.coordinates[0]
              )
            );
            infoWindow.open(this.map);
          } else {
            if (zoom != 18) {
              if (zoomToExpand) {
                this.map.setZoom(zoomToExpand);
              } else {
                this.map.setZoom(18);
              }
            }

            this.map.setCenter({
              lat: data.geometry.coordinates[1],
              lng: data.geometry.coordinates[0],
            });
          }
        });
        this.clsmarkers.push(marker);
      } else {
        var content = document.createElement('div');
        content.innerHTML = `
        <div class = "marker">
          <img class="marker-image" src="../../../assets/images/map-markers/map-marker.svg">
          <div class="marker-title">
             ${
               mapListFor === 'HCP'
                 ? data.properties['hcp_nm'] || data.properties.category_nm
                 : mapListFor === 'Account' || mapListFor === 'Accounts'
                 ? data.properties['account_name'] ||
                   data.properties.category_nm
                 : data.properties['category_nm']
             }
          </div>
        </div>
        `;
        var marker = new AdvancedMarkerElement({
          position: {
            lat: data.geometry.coordinates[1],
            lng: data.geometry.coordinates[0],
          },
          content,
          map: this.map,
          zIndex: 1,
        });

        marker.addListener('click', () => {
          this.clickedOnMarker = true;

          infoWindow.setContent(
            this.createSummaryContent(data.properties, mapListFor)
          );
          infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -60) });
          infoWindow.setPosition(
            new google.maps.LatLng(
              data.geometry.coordinates[1],
              data.geometry.coordinates[0]
            )
          );
          infoWindow.open(this.map);
          this.map.setCenter({
            lat: data.geometry.coordinates[1],
            lng: data.geometry.coordinates[0],
          });
        });
        this.clsmarkers.push(marker);
      }
    }

    for (var i = 0; i < this.clsmarkers?.length; i++) {
      this.clsmarkers[i].setMap(this.map);
    }
  }

  multipleListContent(list, mapListFor) {
    let content;
    content = `
    <div>
      <div class="cluster-title">
        ${mapListFor} List
      </div>
      <div>
        ${list
          .map(
            (item) =>
              '<div style="border-top: 1px solid #dedcde !important; padding-top: 15px;">' +
              this.createSummaryContent(item.properties, mapListFor) +
              '</div>'
          )
          .join('')}
      </div> 
    </div>
    `;
    return content;
  }

  createSummaryContent(summaryObj, mapListFor) {
    var content;
    content = `
    <div  class='map-popout'>
      <div class='summary-name'>
       ${
         mapListFor === 'HCP'
           ? summaryObj.hcp_nm || summaryObj?.category_nm
           : mapListFor === 'Account' || mapListFor === 'Accounts'
           ? summaryObj.account_name || summaryObj?.category_nm
           : summaryObj?.category_nm
       }
      </div>
      <div class='middle-section'>
      ${
        summaryObj.state && summaryObj.county
          ? summaryObj.state + ' ,' + summaryObj.county + '</br>'
          : '<div></div>'
      }
        Total Impacted Patients: ${summaryObj?.total_impacted_pat_ct}
      </div>
    </div>
    `;
    return content;
  }

  drawHeatMap(heatData, selectedRadioValue, calledFrom, summaryToggleValue) {
    const featureStyleOptions = {
      strokeColor: '#bebebe',
      strokeOpacity: 1.0,
      strokeWeight: 0.5,
      fillColor: 'white',
    };

    this.featureLayer.style = (featureStyleFunctionOptions) => {
      if (heatData !== null) {
        const placeFeature =
          featureStyleFunctionOptions.feature as google.maps.PlaceFeature;
        let fillColor;
        if (calledFrom === 'caregap') {
          fillColor = heatData[placeFeature.placeId]
            ? selectedRadioValue == 'Volume'
              ? heatData[placeFeature.placeId].impacted_count_color
              : heatData[placeFeature.placeId].impacted_percent_color
            : '#fff';
        } else if (calledFrom === 'barrier') {
          fillColor =
            heatData[placeFeature.placeId] &&
            heatData[placeFeature.placeId].impacted_count_color
              ? heatData[placeFeature.placeId].impacted_count_color
              : '#fff';
        }
        return {
          fillColor,
          fillOpacity: 1,
          strokeColor: '#bebebe',
          strokeWeight: 0.5,
        };
      } else {
        return featureStyleOptions;
      }
    };

    if (heatData !== null) {
      this.showContent = this.featureLayer.addListener('mousemove', (event) => {
        var content;
        if (calledFrom === 'caregap') {
          if (summaryToggleValue === 'County') {
            content =
              '<div id="content">' +
              '<h4>' +
              heatData[event.features[0].placeId]?.geo_level_2_nm +
              '</h4>' +
              '\n' +
              'Patients Impacted: ' +
              `${
                selectedRadioValue === 'Volume'
                  ? heatData[
                      event.features[0].placeId
                    ]?.total_impacted_pat_ct?.toLocaleString() || 0
                  : (heatData[
                      event.features[0].placeId
                    ]?.percent_impacted_pat_ct?.toLocaleString() || 0) + ' %'
              }` +
              '</div>';
          } else {
            content =
              '<div id="content">' +
              '<h4>' +
              heatData[event.features[0].placeId]?.geo_level_2_nm +
              '</h4>' +
              '\n' +
              'Patients Impacted: ' +
              `${
                selectedRadioValue === 'Volume'
                  ? heatData[
                      event.features[0].placeId
                    ]?.total_impacted_pat_ct?.toLocaleString() || 0
                  : (heatData[
                      event.features[0].placeId
                    ]?.percent_impacted_pat_ct?.toLocaleString() || 0) + ' %'
              }` +
              '<br>' +
              'ZIPs Impacted: ' +
              `${
                selectedRadioValue === 'Volume'
                  ? heatData[
                      event.features[0].placeId
                    ]?.total_impacted_geo_ct?.toLocaleString()
                  : (heatData[
                      event.features[0].placeId
                    ]?.percent_impacted_geo_ct?.toLocaleString() || 0) + ' %'
              } ` +
              '</div>';
          }
        } else if (calledFrom === 'barrier') {
          content =
            '<div id="content">' +
              '<h4>' +
              heatData[event.features[0].placeId]?.geo_level_2_nm +
              '</h4>' +
              '\n' +
              'Patients Impacted: ' +
              heatData[
                event.features[0].placeId
              ]?.total_pat_ct?.toLocaleString() || 0 + '</div>';
        }

        if (heatData[event.features[0].placeId]) {
          this.infowindow.setContent(content);

          this.infowindow.setPosition(
            new google.maps.LatLng(event.latLng.lat() + 0.5, event.latLng.lng())
          );
          this.infowindow.open(this.map);
        } else {
          this.infowindow.close();
        }
      });
      this.removeContent = this.map.addListener('mouseout', (event) => {
        this.infowindow.close();
      });
    }
  }

  geoInfowindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(0, 0), // offset for icon
  });

  drawZipCodeBoundary(zipCodeCoordinates, selectedGeoLevel): void {
    this.map.data.forEach((feature) => {
      this.map.data.remove(feature);
    });

    this.infowindow.close();

    this.map.data.addGeoJson(zipCodeCoordinates);
    this.map.data.setStyle((feature: any) => {
      return {
        fillColor: feature.getProperty('color'),
        fillOpacity: 0.75,
        strokeWeight: 0.5,
        strokeColor: '#C0C0C0',
      };
    });

    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      if (this.map.getZoom() === 5 && this.lastZoom === 6) {
        this.map.setZoom(4);
      }
      if (this.map.getZoom() === 5 && this.lastZoom === 4) {
        this.map.setZoom(6);
      }
      this.lastZoom = this.map.getZoom();
    });

    // this.showGeoContent.remove();
    this.showGeoContent = this.map.data.addListener('mouseover', (event) => {
      const content =
        '<div id="content">' +
        '<h4>' +
        event.feature.getProperty('state') +
        ' : ' +
        `${
          event.feature.getProperty('zip')
            ? event.feature.getProperty('zip')
            : event.feature.getProperty('county')
            ? event.feature.getProperty('county')
            : event.feature.getProperty('county_name')
            ? event.feature.getProperty('county_name')
            : null
        }` +
        '</h4>' +
        '\n' +
        `
        ${
          zipCodeCoordinates.selectedRadio === 'gpv'
            ? 'Total Patients Impacted per Volume :<b>' +
              event.feature.getProperty('value').toLocaleString() +
              '</b>'
            : 'Total Patient Impacted: <b>' +
              event.feature.getProperty('value').toLocaleString() +
              zipCodeCoordinates.selectedRadio +
              '</b>'
        }
        ` +
        '</div>';
      this.geoInfowindow.setContent(content);

      const lat =
        selectedGeoLevel == 3
          ? event.latLng.lat() + 0
          : event.latLng.lat() + 0.2;

      this.geoInfowindow.setPosition(
        new google.maps.LatLng(lat, event.latLng.lng())
      );
      this.geoInfowindow.open(this.map);
    });
    this.removeGeoContent = this.map.data.addListener('mouseout', (event) => {
      this.geoInfowindow.close();
    });
  }

  generateRandomString(length) {
    if (length < 3 || length % 2 === 0) {
      throw new Error(
        'Length must be an odd number greater than or equal to 3.'
      );
    }

    const characters = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < Math.floor(length / 2); i++) {
      const randomIndex = Math.floor(Math.random() * characters?.length);
      result += characters.charAt(randomIndex);
    }

    const dashIndex = Math.floor(Math.random() * Math.floor(result?.length));
    result = result.slice(0, dashIndex) + '-' + result.slice(dashIndex);

    for (let i = 0; i < Math.ceil(length / 2); i++) {
      const randomIndex = Math.floor(Math.random() * characters?.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }
}
