import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ToasterService } from '../../../../app/core/services/toaster.service';
import { RDNotificationService } from '@zs-ca/angular-cd-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent {
  @ViewChild('successNotification', { static: false })
  successNotification!: TemplateRef<any>;
  @ViewChild('errorNotification', { static: false })
  errorNotification!: TemplateRef<any>;
  @ViewChild('warningNotification', { static: false })
  warningNotification!: TemplateRef<any>;
  @ViewChild('infoNotification', { static: false })
  infoNotification!: TemplateRef<any>;
  toastSub: Subscription;
  constructor(
    private toasterService: ToasterService,
    private notification: RDNotificationService
  ) {
    this.toastSub = this.toasterService.listenForToast().subscribe((toast) => {
      if (toast.type === 'success') {
        this.notification.template(this.successNotification, {
          data: {
            message: toast.message,
          },
          style: {
            background: '#F3FCF4',
            border: '1px solid #45C03D',
          },
          duration: 4500,
        });
      } else if (toast.type === 'error') {
        this.notification.template(this.errorNotification, {
          data: {
            message: toast.message,
          },
          style: {
            background: '#fff1f0',
            border: '1px solid #ffa39e',
          },
          duration: 4500,
        });
      } else if (toast.type === 'warning') {
        this.notification.template(this.warningNotification, {
          data: {
            message: toast.message,
          },
          style: {
            background: '#fffd7e6',
            border: '1px solid #fffd591',
          },
          duration: 4500,
        });
      } else if (toast.type === 'info') {
        this.notification.template(this.infoNotification, {
          data: {
            message: toast.message,
          },
          style: {
            background: '#E4F3FB',
            border: '1px solid #3B9FE7',
            width: 'max-content',
          },
          duration: 4500,
        });
      }
    });
  }
  ngOnDestroy() {
    this.toastSub.unsubscribe();
  }
}
