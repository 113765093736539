<!-- <rd-button btnText="alert">Welcome to Care gap</rd-button> -->
<!-- <h1>Welcome to Care gap</h1> -->
<app-sider
  [menuItemsList]="sideBarMenuItemsList"
  [siderData]="launchBarData"
></app-sider>
<div class="app-layout">
  <app-header></app-header>
  <ng-container *ngIf="!isLoading; else loadingSpinner">
    <div class="app-content" *ngIf="!errorMessage">
      <router-outlet></router-outlet>
    </div>
    <div class="place-items-center-absolute" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div class="place-items-center-absolute">
    <rd-spin
      [spinnerStyle]="{
        'font-size': '40px',
        color: '#27a6a4',
        position: 'relative',
        right: '40px'
      }"
    ></rd-spin>
  </div>
</ng-template>
<app-toaster></app-toaster>
