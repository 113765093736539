<rd-drawer
  [isVisible]="isVisible"
  [title]="title"
  [width]="'35vw'"
  (drawerClose)="onDrawerClose($event)"
  [maskClosable]="false"
>
  <ng-template #title>
    <div class="title-container">
      <div class="title">Account Profile</div>
      <rd-button
        [btnType]="'text'"
        [iconStyle]="{ fontSize: '14px', color: '#9C9AA1' }"
        iconType="close"
        (click)="onDrawerClose(true)"
      ></rd-button>
    </div>
  </ng-template>
  <div *ngIf="!isAPIFailed">
    <div class="account-profile">
      <div
        class="basic-details"
        *ngIf="
          isAccountDataLoaded &&
          objectKeys(accountDetails?.account_details).length > 0
        "
      >
        <div class="left-details">
          <div class="name">
            <p [title]="accountDetails?.account_details?.account_nm">
              {{ accountDetails?.account_details?.account_nm }}
            </p>
          </div>
          <label *ngIf="accountDetails?.account_details?.account_address && accountDetails?.account_details?.account_address !== ''" [title]="accountDetails?.account_details?.account_address"
            ><b>Address:</b>
            {{ accountDetails?.account_details?.account_address }}</label
          >
          <label
            *ngIf="
              accountDetails?.account_details?.parent_account_name &&
              accountDetails?.account_details?.parent_account_name !==
                'Not Available'
            "
            [title]="accountDetails?.account_details?.parent_account_name"
            ><b>Parent Account:</b>
            {{ accountDetails?.account_details?.parent_account_name }}</label
          >
        </div>
        <div>
          <rd-button
            [iconType]="'download'"
            [iconStyle]="{ 'font-size': '16px' }"
            (btnClick)="onDownloadClick()"
          ></rd-button>
        </div>
      </div>
      <div
        class="account-details"
        *ngIf="
          isAccountDataLoaded &&
          objectKeys(accountDetails?.account_details).length > 0
        "
      >
        <div class="section-title">
          <rd-icon
            [type]="'custom:hospital-outline'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <h3>Account Details</h3>
        </div>

        <div class="details">
          <div class="item">
            <span>Priority</span>
            <rd-tag
              [mode]="'default'"
              [color]="'#E2FFE3'"
              *ngIf="
                accountDetails?.account_details?.account_priority === 'High'
              "
              [tagStyle]="{ borderRadius: '8px', color: '#24AF29' }"
            >
              <b>{{ accountDetails?.account_details?.account_priority }}</b>
            </rd-tag>
            <rd-tag
              [mode]="'default'"
              [color]="'#FFFADF'"
              [tagStyle]="{ borderRadius: '8px', color: '#E3A900' }"
              *ngIf="
                accountDetails?.account_details?.account_priority === 'Medium'
              "
            >
              <b>{{ accountDetails?.account_details?.account_priority }}</b>
            </rd-tag>
            <rd-tag
              [mode]="'default'"
              [color]="'#FFDEDF'"
              [tagStyle]="{ borderRadius: '8px', color: '#FD595F' }"
              *ngIf="
                accountDetails?.account_details?.account_priority === 'Low'
              "
              ><b>{{
                accountDetails?.account_details?.account_priority
              }}</b></rd-tag
            >
            <rd-tag
              [mode]="'default'"
              [color]="'#D3D3D3'"
              [tagStyle]="{ borderRadius: '8px', color: '#818589' }"
              *ngIf="
                accountDetails?.account_details?.account_priority ===
                'Not Available' || accountDetails?.account_details?.account_priority ===
                ''
              "
            >
              <b>NA</b>
            </rd-tag>
          </div>
          <div class="item">
            <span>Account Type</span>
            <p>
              {{ accountDetails?.account_details?.account_type | titlecase }}
            </p>
          </div>
          <div class="item">
            <span>Practice Setting</span>
            <p>{{ accountDetails?.account_details?.practice_setting }}</p>
          </div>
          <div class="row-item">
            <span>{{ accountDetails?.account_details?.call_activity === -1 ? 'NA' : accountDetails?.account_details?.call_activity }}</span>
            <p>Calls/visits made in the last quarter</p>
          </div>
          <div class="row-item">
            <span>{{ accountDetails?.account_details?.tot_er_rates === -1 ? 'NA' : accountDetails?.account_details?.tot_er_rates }}</span>
            <p>ER Visits</p>
          </div>
          <div class="row-item">
            <span>
              {{
                accountDetails?.account_details?.tot_hospitalisation_rates === -1 ? 'NA' : accountDetails?.account_details?.tot_hospitalisation_rates
              }}</span
            >
            <p>Hospitalization Rates</p>
          </div>
        </div>
      </div>
      <div
        class="hcp-summary"
        *ngIf="
          isAccountDataLoaded &&
          objectKeys(accountDetails?.hcp_summary).length > 0
        "
      >
        <div class="section-title">
          <rd-icon
            [type]="'custom:hcp-summary'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <h3>HCP Summary</h3>
        </div>

        <div class="summary">
          <div class="item-left">
            <span
              >{{ accountDetails?.hcp_summary?.total_hcps_affliated }}
            </span>
            <p>HCPs Affiliated to this Account</p>
          </div>
          <div class="section">
            <div class="item">
              <div>
                <b>{{ accountDetails?.hcp_summary?.tot_kols === -1 ? 'NA' : accountDetails?.hcp_summary?.tot_kols }} </b>KOLs
              </div>
            </div>
            <div class="item">
              <div>
                <b>{{ accountDetails?.hcp_summary?.tot_specialists }}</b>
                Specialists
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="top-hcps"
        *ngIf="isAccountDataLoaded && hcpGrid.tableData.length > 0"
      >
        <h3>Top 5 HCPs</h3>
        <rd-table
          [tableColumns]="hcpGrid.tableColumns"
          [tableData]="hcpGrid.tableData"
          [frontEndPagination]="true"
          (tableRowClick)="onTableRowClick($event)"
        ></rd-table>

        <rd-button
          [btnType]="'link'"
          [btnText]="'View List of HCPs'"
          (btnClick)="viewAllHcps()"
          [btnStyle]="{ margin: '15px 0 15px 0' }"
        ></rd-button>
      </div>
      <div
        class="patient-chart"
        *ngIf="
          isAccountDataLoaded &&
          accountDetails?.account_caregap_population.length !== 0
        "
      >
        <div class="patient-chart-heading section-title">
          <rd-icon
            [type]="'custom:segment-summary'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <h3>Impacted Patients</h3>
        </div>
        <div class="patient-chart-subheading">
          <span class="total-patient">{{
            accountDetails?.account_details?.total_pat_ct | number
          }}</span>
          Total Impacted Patients Managed by the Account
        </div>
        <div class="chart">
          <app-bar-chart
            [chartId]="'accountCgBarChart'"
            [isLoaded]="isAccountDataLoaded"
            [patientData]="accountDetails?.account_caregap_population"
          ></app-bar-chart>
        </div>

        <div
          class="patient-segment"
          *ngIf="
            isAccountDataLoaded &&
            objectKeys(accountDetails?.patient_segments).length > 0
          "
        >
          <h3>Patient Segment</h3>
          <div class="details">
            <div
              class="segment-id"
              [style.background]="accountDetails.patient_segments.color"
            >
              {{ accountDetails.patient_segments.segment_id }}
            </div>
            <div>{{ accountDetails.patient_segments.segment_name }}</div>
          </div>
        </div>
      </div>

      <div class="demographics">
        <rd-collapse
          *ngIf="
            isAccountDataLoaded && accountDetails?.demographics?.length !== 0
          "
          [expandLocation]="'right'"
          [panels]="demographicPanel"
        >
        </rd-collapse>
        <ng-template #panelHeader>
          <div class="panel-header">
            <rd-icon
              [type]="'custom:data'"
              [iconStyle]="{ 'font-size': '24px' }"
            ></rd-icon>
            <span>Demographic Details</span>
          </div>
        </ng-template>
        <ng-template #demographicContent>
          <div class="demographic-section">
            <div class="chart-heading">Demographic Details</div>
            <div
              class="stacked-chart"
              *ngFor="let item of accountDetails?.demographics"
            >
              <app-stacked-bar-chart
                [graphId]="item.category + '_account'"
                [isLoaded]="isAccountDataLoaded"
                [chartData]="item"
                [screen]="'care-gap-popup'"
              ></app-stacked-bar-chart>
            </div>
          </div>
        </ng-template>
      </div>

      <div
        class="top-barriers"
        *ngIf="
          isAccountDataLoaded && accountDetails?.systemic_barriers?.length > 0
        "
      >
        <div class="barrier-label">
          <rd-icon
            [type]="'custom:systematic-barriers'"
            [iconStyle]="{ 'font-size': '24px' }"
          ></rd-icon>
          <span class="label">Top 5 Barriers Across Care Gaps</span>
        </div>
        <div class="barrier-content">
          <div class="sub-heading">
            Barriers correspond to Geography of Account and are applicable for
            prioritized Hotspots only
          </div>
          <div class="barrier-header">
            <div class="title-header-item">Barriers</div>
            <div class="title-header-item">Relative Importance</div>
          </div>
          <div class="care-gap-section">
            <div
              class="caregap-item"
              *ngFor="let item of accountDetails?.systemic_barriers"
            >
              <div class="care-gap-name">{{ item?.care_gap_name }}</div>
              <rd-table
                [tableData]="item?.barriers"
                [tableColumns]="barrierGrid.tableColumns"
                [showPagination]="false"
              ></rd-table>
            </div>
          </div>
          <!-- <rd-table
            [tableColumns]="barrierGrid.tableColumns"
            [tableData]="barrierGrid.tableData"
          ></rd-table> -->
        </div>
      </div>
    </div>
  </div>
  <div class="message-section" *ngIf="isAPIFailed || !isAccountDataLoaded">
    <rd-alert
      [closable]="false"
      [type]="'error'"
      [message]="errorMessage"
      [showIcon]="true"
      *ngIf="isAPIFailed"
    ></rd-alert>
    <rd-spin
      [spinSize]="'large'"
      [spinnerStyle]="{ 'font-size': '40px', color: '#27a6a4' }"
      *ngIf="!isAccountDataLoaded"
    ></rd-spin>
  </div>
</rd-drawer>
