import { formatDate } from '@angular/common';

export class Utils {
  constructor() {}

  static isArrayExists(arr:[]): any {
    if (arr && typeof arr != 'undefined' && Object.keys(arr).length > 0) {
      return true;
    }
    return false;
  }


}
