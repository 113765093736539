export enum ApiEndpoints {
  LAUNCH_BAR = 'launch-bar',
  USER_INFO = 'user-info',
  LOGOUT = 'logout',
  FAQ = 'faq',
  PATIENT_JOURNEY_STAGES = 'patient-journey-stages',
  DEMOGRAPHIC_SUMMARY = 'demographic-summary',
  SUMMARY = 'summary',
  LOCATION = 'location-dropdown',
  ACCOUNT_LIST = 'accounts-list',
  CARE_GAPS = 'care-gaps-dropdown',
  GEOGRAPHY = 'geography-distribution',
  ACCOUNT_DETAILS = 'account-details',
  COUNTY_MAP_DATA = 'geography-distribution-counties',
  ZIP_MAP_DATA = 'zip-map',
  SUB_THERAPY = 'sub-therapeutic-area-dropdown',
  DATE_RANGE = 'data-date-range',
  SAVED_LOCATIONS = 'locations',
  SAVE_LOCATION = 'save-location',
  DELETE_LOCATION = 'delete-location',
  CG_DISTRIBUTION = 'care-gap-distribution',
  SEGMENT_SUMMARY = 'segment-summary',
  SEGMENT_SUMMARY_MAP = 'segment-summary-map',
  GEOGRAPHICAL_SEGMENTS = 'top-geographies',
  SEGMENT_PROFILER = 'segment-demographics',
  CG_PROFILE = 'caregap-profile',
  PS_THIRD_GEO_LEVEL_SUMMARY = 'third-geo-level-segment-summary',
  BARRIER_COUNTY_DATA = 'barrier-summary-graph',
  BARRIER_SUMMARY = 'barrier-summary',
  BARRIER_CATEGORY = 'barrier-categories',
  BARRIER_SUMMARY_SELECTION = 'barrier-summary-selection-graph',
  HCP_FLYOUT = 'hcp-flyout',
  ACCOUNT_FLYOUT = 'account-flyout',
  PRACTICE_SETTING_SUMMARY = 'practice-setting-summary',
  PRACTICE_SETTING_SUMMARY_GRAPH = 'practice-setting-summary-graph',
  PROVIDER_ACCOUNT_LIST = 'account-list',
  PROVIDER_HCP_LIST = 'hcp-list',
  PROVIDER_OPPORTUNITIES = 'provider-opportunities',
  FLYOUT_DOWNLOAD = 'flyout-download',
  DOWNLOAD = 'download',
  SEGMENT_STATE_MAP = 'segment-state-map',
  SEGMENT_ZIP_MAP = 'segment-zip-map',
  BARRIER_STATE_MAP = 'barrier-state-map',
  BARRIER_ZIP_MAP = 'barrier-zip-map',
  ZIP_MAP_SEGMENTS = 'zip-map-segments',
  COUNTY_MAP_SEGMENTS = 'county-map-segments',
  ZIP_MAP_CAREGAP = 'zip-map-caregap',
  ZIP_MAP_BARRIER = 'zip-map-barrier',
  COUNTY_MAP_BARRIER = 'county-map-barrier',

  COUNTY_MAP_CAREGAP = 'county-map-caregap',
}
