import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { SOMETHING_WENT_WRONG, API_ERROR } from 'src/app/core/constants';
import { CareGapService } from 'src/app/core/services/care-gap.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-flyout',
  templateUrl: './account-flyout.component.html',
  styleUrls: ['./account-flyout.component.scss'],
})
export class AccountFlyoutComponent {
  errorMessage = SOMETHING_WENT_WRONG;

  isAPIFailed: boolean = false;
  isAccountDataLoaded: boolean = false;

  accountDetails: any;
  @Input() isVisible = false;
  @Input() subTherapy = '';
  @Input() geoLevel: number = 1;
  @Input() subNationId: number;
  @Input() stage: number;
  @Input() care_gap_id = [];
  @Input() location = [];
  @Input() all_care_gaps_flag = false;
  @Input() account_id;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Output() onHcpNameClick: EventEmitter<any> = new EventEmitter();

  @Output() onViewAllHcp: EventEmitter<any> = new EventEmitter();

  @ViewChild('demographicContent', { read: TemplateRef })
  demographicContent: TemplateRef<any>;
  @ViewChild('panelHeader', { read: TemplateRef })
  panelHeader: TemplateRef<any>;

  objectKeys = Object.keys;

  hcpGrid = {
    tableColumns: [
      {
        key: 'hcp_npi',
        name: 'NPI',
        sortable: true,
        defaultSortOrder: 'asc',
        dataType: 'number',
        sortDirections: ['ascend', 'descend'],
      },
      {
        key: 'hcp_nm',
        name: 'HCP Name',
        sortable: true,
        dataType: 'string',
        sortDirections: ['ascend', 'descend'],
        type: 'clickable',
      },
      {
        key: 'hcp_speciality',
        name: 'Speciality',
        sortable: true,
        dataType: 'string',
        sortDirections: ['ascend', 'descend'],
      },
    ],
    tableData: [],
  };

  barrierGrid = {
    tableColumns: [
      {
        name: '',
        key: 'barrier_sub_cat_nm',
        dataType: 'string',
        align: 'left',
        // ellipsis: true,
        headerStyle: {
          borderBottom: '1px solid black',
        },
        style: {
          fontWeight: 600,
          fontSize: '0.8rem',
        },
        type: 'variableStyleText',
        width: '70%',
      },
      {
        name: '',
        key: 'relative_importance',
        dataType: 'string',
        align: 'right',
        headerStyle: { borderBottom: '1px solid black' },
        style: { fontWeight: 600, fontSize: '0.8rem' },
        type: 'text',
        width: '30%',
      },
    ],
    tableData: [],
  };

  demographicPanel = [];

  constructor(
    private careGapService: CareGapService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    // this.accountDetails = accountFlyout;
    // this.hcpGrid.tableData = accountFlyout.top_hcp;
    this.loadAccountDetails();
  }

  onDrawerClose(event: any) {
    this.isVisible = !event;
    this.onClose.emit(this.isVisible);
  }

  loadAccountDetails() {
    const payload = {
      data_source: '',
      therapeutic_area: '',
      sub_therapeutic_area: this.subTherapy,
      stage_id: this.stage,
      sub_nation_id: this.subNationId,
      geo_level: this.geoLevel,
      care_gap_id: this.care_gap_id,
      all_care_gaps_flag: this.all_care_gaps_flag,
      account_id: this.account_id,
    };
    this.careGapService.getAccountDetails(payload).subscribe({
      next: (res) => {
        this.accountDetails = res;
        this.hcpGrid.tableData = res?.top_hcps || [];
        this.barrierGrid.tableData = res?.systemic_barriers || [];
        this.transformBarrierData();
        this.demographicPanel = [
          {
            showArrow: true,
            active: true,
            content: this.demographicContent,
            header: this.panelHeader,
          },
        ];
        this.isAccountDataLoaded = true;
      },
      error: (error) => {
        this.errorMessage = API_ERROR;
        this.isAPIFailed = true;
        // this.toaster.toast('error', API_ERROR);
        this.toaster.handleApiError();
        this.isAccountDataLoaded = true;
      },
    });
  }

  transformBarrierData() {
    this.accountDetails?.systemic_barriers?.forEach((data) => {
      data?.barriers?.sort((a, b) =>
        Number(a?.relative_importance) > Number(b?.relative_importance)
          ? -1
          : Number(a?.relative_importance) < Number(b?.relative_importance)
      );
      data?.barriers?.forEach((item, index) => {
        item.barrier_sub_cat_nm_variableStyle = [
          {
            style: {
              fontSize: '11px',
              'background-color': '#716E79',
              color: '#fff',
              'border-radius': '50%',
              height: '22px',
              'min-width': '1.5rem',
              'max-width': '100%',
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              'margin-right': '0.5rem',
            },
            value: index + 1,
          },
          {
            style: {
              height: '0.5rem',
              'min-width': '0.5rem',
              'max-width': '100%',
              'border-radius': '50%',
              'background-color': item?.color,
              'margin-right': '5px',
            },
          },
          {
            value: item?.barrier_sub_cat_nm,
          },
        ];
        item.relative_importance = item?.relative_importance + '%';
      });
    });
  }

  onTableRowClick(event: any) {
    if (event.column.key === 'hcp_nm') {
      this.onHcpNameClick.emit({
        hcp_npi: event.data.hcp_npi,
      });
      this.isVisible = false;
    }
  }

  viewAllHcps() {
    this.onViewAllHcp.emit({ account_id: this.account_id });
  }

  onDownloadClick() {
    const payload = {
      ...this.accountDetails,
      type: 'account',
    };
    this.careGapService.flyoutDownload(payload).subscribe({
      next: (res) => {
        this.getDownloadUrl(res.file_name);
      },
      error: (error) => {
        // this.toaster.toast('error', API_ERROR);
        this.toaster.handleApiError();
      },
    });
  }

  getDownloadUrl(filename: string) {
    window.location.href =
      environment.config.serverUrl + '/download/' + filename;
  }
}
