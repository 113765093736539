<rd-modal
  [isVisible]="isVisible"
  [isCloseable]="true"
  [isMaskClosable]="false"
  [modalWidth]="500"
  (modalCloseEvent)="onModalClose()"
>
  <div rdModalTitle class="title-header">Saved Locations</div>

  <div rdModalContent>
    <rd-table
      [tableColumns]="geoLevel2Table.tableColumn"
      [tableData]="geoLevel2Table.tableData"
      [loadingRows]="5"
      [errorMessage]="geoLevel2Table.errorMessage"
      [errorType]="'warning'"
      (tableRowClick)="onTableRowClick($event, 'geo_level_2')"
    >
    </rd-table>

    <rd-table
      [tableColumns]="geoLevel3Table.tableColumn"
      [tableData]="geoLevel3Table.tableData"
      [loadingRows]="5"
      [errorMessage]="geoLevel3Table.errorMessage"
      [errorType]="'warning'"
      (tableRowClick)="onTableRowClick($event, 'geo_level_3')"
    >
    </rd-table>
  </div>
</rd-modal>
