export const API_SUCCESS = 'success';

export const SOMETHING_WENT_WRONG =
  'Something went wrong. Please try again later.';

export const NO_DATA_FOUND = 'DATA NOT AVAILABLE';

export const COE = 'COEs Only';

export const API_ERROR = 'Something went wrong';
