import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toaster } from '../models/constant.model';
import { API_ERROR } from '../constants';
@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private toasterSubject: Subject<any> = new Subject<any>();
  private ApiErrorShown = false;
  private isMappingInfoShown;
  constructor() {}
  toast(type: Toaster, message: string) {
    this.toasterSubject.next({
      type,
      message,
    });
  }

  listenForToast() {
    return this.toasterSubject;
  }

  displayMappingInfo() {
    if (!this.isMappingInfoShown) {
      this.toast(
        'info',
        "Accounts and HCP data don't change when the HCP's are not mapped to multiple accounts"
      );
      this.isMappingInfoShown = true;
    }
    setTimeout(() => {
      this.isMappingInfoShown = false;
    }, 4500);
  }

  handleApiError() {
    if (!this.ApiErrorShown) {
      this.toast('error', API_ERROR);
      this.ApiErrorShown = true;
    }

    setTimeout(() => {
      this.ApiErrorShown = false;
    }, 4500);
  }
}
