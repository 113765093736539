<rd-sider
  [siderMenuItems]="menuItemsList"
  [collapsible]="false"
  [applicationList]="siderData?.applications || []"
  [logoutUrl]="siderData?.signOut || ''"
  [idmAccountUrl]="siderData?.myAccount || ''"
  [expirationMessage]="siderData?.expiry"
  [showAppsList]="true"
  (brandClick)="handleBrandClick()"
  (helpClick)="toggleSupportModal(true)"
  (logoutClick)="onLogoutClick()"
>
  <img rd-sider-brand src="assets/images/zaidyn-logo.svg" alt="ZAIDYN" />
</rd-sider>
<rd-modal
  *ngIf="showHelpModal"
  [isVisible]="showHelpModal"
  [isCloseable]="true"
  [isMaskClosable]="true"
  (modalCloseEvent)="toggleSupportModal(false)"
>
  <div rdModalTitle>Help Center</div>
  <div rdModalContent class="rd-sider__modal-content">
    <!-- <div class="help__section document-list">
      <rd-button
        btnType="link"
        btnText="User Guide"
        btnStyle="helpBtn"
        [isLoading]="documentFetchInProgress"
        (btnClick)="fetchUserGuide()"
      ></rd-button>

      <rd-button
        btnType="link"
        btnText="Glossary"
        btnStyle="helpBtn"
        [isLoading]="documentFetchInProgress"
        (btnClick)="fetchGlossary()"
      ></rd-button>
    </div> -->
    <div class="help__section contact-list">
      <h1 class="help__section-title">Technical Support</h1>
      <div class="contact-list__container">
        <div
          *ngFor="let contact of contactSupportList"
          class="help__item contact-list__item"
        >
          <span class="item__label">
            <rd-icon
              [type]="contact.icon"
              [iconStyle]="{ 'margin-right': '4px' }"
            ></rd-icon>
            {{ contact?.label }}
          </span>
          <a class="item__value item__value--link">
            {{ contact.value }}
            <rd-icon
              class="copy-icon"
              [type]="'copy'"
              [iconStyle]="{ 'margin-right': '4px' }"
              (click)="copyText(contact?.value)"
            ></rd-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="help__section contact-list">
      <h1 class="help__section-title">Have A Business Query?</h1>
      <div class="contact-list__container">
        <div
          *ngFor="let contact of businessQueryList"
          class="help__item contact-list__item"
        >
          <span class="item__label">
            <rd-icon
              [type]="contact.icon"
              [iconStyle]="{ 'margin-right': '4px' }"
            ></rd-icon>
            {{ contact?.label }}
          </span>
          <a class="item__value item__value--link">
            {{ contact.value }}
            <rd-icon
              class="copy-icon"
              [type]="'copy'"
              [iconStyle]="{ 'margin-right': '4px' }"
              (click)="copyText(contact?.value)"
            ></rd-icon>
          </a>
        </div>
      </div>
    </div>
    <div class="help__section">
      <h1 class="help__section-title">Release Version</h1>
      <div class="help__item">
        <p class="item__value release-value">{{ releaseVersion }}</p>
      </div>
    </div>
  </div>
  <div rdModalFooter>
    <rd-button
      btnText="Close"
      btnType="primary"
      (btnClick)="toggleSupportModal(false)"
    ></rd-button>
  </div>
</rd-modal>
