import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'care-gaps',
    loadChildren: () =>
      import('./care-gap/care-gap.module').then((m) => m.CareGapModule),
  },
  {
    path: 'patient-segment',
    loadChildren: () =>
      import('./patient-segment/patient-segment.module').then(
        (m) => m.PatientSegmentModule
      ),
  },
  {
    path: 'systemic-barriers',
    loadChildren: () =>
      import('./systematic-barriers/systematic-barriers.module').then(
        (m) => m.SystematicBarriersModule
      ),
  },
  {
    path: 'provider-insights',
    loadChildren: () =>
      import('./provider-insights/provider-insights.module').then(
        (m) => m.ProviderInsightsModule
      ),
  },
  { path: '', redirectTo: 'care-gaps', pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
