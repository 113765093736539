export const environment = {
  production: false,
  config: {
    serverUrl: 'https://caregap-inequities.dev.zsservices.com/',
    loginUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    logoutUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx',
    idmAccountUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/MyAccount.aspx',
    gtagId: 'G-DXWCZEJ6ZF',
    env: 'dev',
    showCareGapDropdown: true,
  },
};
