import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Utils } from 'src/app/shared/utils';
import { CareGapService } from 'src/app/core/services/care-gap.service';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/core/utils/utils.service';
import { ToasterService } from '../../../core/services/toaster.service';
import { Router } from '@angular/router';
import { DragScrollComponent } from 'ngx-drag-scroll';
import * as LZString from 'lz-string';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-care-gap-filters',
  templateUrl: './care-gap-filters.component.html',
  styleUrls: ['./care-gap-filters.component.scss'],
})
export class CareGapFiltersComponent implements OnInit {
  @ViewChild('headerfilters', { read: DragScrollComponent })
  ds: DragScrollComponent;

  @Input() heading: string;
  stageListStatus = true;
  careGaps = {
    selectedValue: [] as any[],
    items: [] as any[],
    searchText: '',
    isLoading: true,
    selCount: 0,
    allChecked: true,
    indeterminate: false,
    templateValue: [] as any[],
    uiValue: 'All Care Gaps',
    previousSelList: [] as any[],
    previousSelListNames: [] as any[],
    isApplyDisabled: true,
    selectedChips: [] as any[],
  };
  stages = this.careGapService.stages;

  rightHide: boolean = false;
  leftHide: boolean = true;
  scrollLeftPosition = 0;

  location: any = {
    selectedValue: 'All Locations (National View)',
    geo_level_3: [] as any[],
    geo_level_2: [] as any[],
    searchText: '',
    isLoading: true,
    items: [] as any[],
    backendValue: [],
    isSaved: false,
    isSaveIconVisible: false,
    selectedGeoLocation: [] as any,
    subNationId: 100001,
    stateCode: '',
    allNationId: 0,
    selectedGeoLevel: 1,
    geoLevels: {},
  };

  savedLocations = {
    isLoading: true,
    geo_level_2: [],
    geo_level_3: [],
  };

  subTherapySub: Subscription;
  locationDataSub: Subscription;
  stagesDataSub: Subscription;
  selectedStageSub: Subscription;
  careGapsDataSub: Subscription;
  isCareGapOpen = false;
  careGapDropdownSub: Subscription;
  screenSub: Subscription;

  selectedCaregaps: any = [];
  selectedStage = 1;
  previousSelectedStage = 1;
  isFirstload = true;
  geographyData: any = {};

  selectedSubTherapy: any = '';
  currentScreen: any;

  showLocationsModal = false;
  isComponentLoaded = false;

  areCareGapFiltersLoaded = false;

  filterSub: Subscription;

  showCareGapDropdown = environment.config.showCareGapDropdown;

  @Output() isResetClicked = new EventEmitter<boolean>();

  getStagesInfoSub: Subscription;

  isSystematicBarriers = false;

  oldRoute: any = '';

  oldSubTherapyArea: any = '';

  showDropdown = false;

  locationSearchValue = '';

  geolevel3SeachResults = [];

  geolevel2SeachResults = [];

  isSearchResultLoading = false;

  recentSearches = {
    geo_level_2: [],
    geo_level_3: [],
  };

  showMoreStateClicked = false;

  showMoreGeo3Clicked = false;

  isFirstLoad = 'false';

  constructor(
    private careGapService: CareGapService,
    public utils: UtilsService,
    private toaster: ToasterService,
    private router: Router,
    private change: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.isFirstLoad = localStorage.getItem('isFirstLoad');

    if (this.isFirstLoad == null || this.isFirstLoad == undefined) {
      this.isFirstLoad = 'true';
      localStorage.setItem('isFirstLoad', 'true');
    } else {
      this.isFirstLoad = 'false';
      localStorage.setItem('isFirstLoad', 'false');
    }
  }

  ngAfterViewChecked() {
    this.setMaxCardHeight();
  }

  setMaxCardHeight() {
    const cardElements = this.el.nativeElement.querySelectorAll('.card');
    const cardTileElements =
      this.el.nativeElement.querySelectorAll('.card-tile');

    const cardHeights = Array.from(cardElements).map(
      (cardElement: HTMLElement) => cardElement.offsetHeight
    );

    const maxHeight = Math.max(...cardHeights);

    cardElements.forEach((cardElement: HTMLElement) => {
      this.renderer.setStyle(cardElement, 'height', `${maxHeight}px`);
    });
    cardTileElements.forEach((cardElement: HTMLElement) => {
      this.renderer.setStyle(
        cardElement,
        'height',
        `${maxHeight > 55 ? 165 : 140}px`
      );
    });
  }

  ngOnInit(): void {
    this.oldRoute = localStorage.getItem('oldRoute') || '';
    this.oldSubTherapyArea = localStorage.getItem('oldSubTherapyArea') || '';
    if (this.isFirstLoad == 'true') {
      this.toaster.toast(
        'info',
        'For best experience keep your screen resolution to 1920 x 1080 & scale to 125%'
      );
    }
    this.location = localStorage.getItem('locationData')
      ? JSON.parse(
          LZString.decompressFromUTF16(localStorage.getItem('locationData'))
        )
      : {
          selectedValue: 'All Locations (National View)',
          geo_level_3: [],
          geo_level_2: [],
          searchText: '',
          isLoading: true,
          items: [] as any[],
          backendValue: [],
          isSaved: false,
          isSaveIconVisible: false,
          selectedGeoLocation: [] as any,
          subNationId: 100001,
          stateCode: '',
          allNationId: 0,
          selectedGeoLevel: 1,
          geoLevels: {},
        };
    this.savedLocations = localStorage.getItem('savedLocations')
      ? JSON.parse(
          LZString.decompressFromUTF16(localStorage.getItem('savedLocations'))
        )
      : {
          isLoading: false,
          geo_level_2: [],
          geo_level_3: [],
        };

    this.recentSearches = localStorage.getItem('recentSearches')
      ? JSON.parse(
          LZString.decompressFromUTF16(localStorage.getItem('recentSearches'))
        )
      : {
          geo_level_2: [],
          geo_level_3: [],
        };

    this.isSystematicBarriers = this.router.url.includes('systemic-barriers')
      ? true
      : false;
    this.screenSub = this.careGapService.currentScreen$.subscribe(
      (data: any) => {
        this.currentScreen = data;
      }
    );
    this.subTherapySub = this.careGapService.selectedSubTherapy$.subscribe(
      (data: any) => {
        this.selectedSubTherapy = data;
        if (
          (this.selectedSubTherapy !== '' &&
            !this.areCareGapFiltersLoaded &&
            this.selectedSubTherapy !== this.oldSubTherapyArea) ||
          this.location?.geo_level_2.length === 0
        ) {
          this.oldSubTherapyArea = JSON.parse(
            JSON.stringify(this.selectedSubTherapy)
          );
          localStorage.setItem('oldSubTherapyArea', this.oldSubTherapyArea);
          if (this.location.geo_level_2.length === 0) {
            this.careGapService.areCareGapFiltersLoaded$.next(true);
            this.getLocationDropdown();
          }
        } else {
          this.location.callStages = true;
          this.careGapService.locationData$.next(this.location);
        }
      }
    );
    this.filterSub = this.careGapService.areCareGapFiltersLoaded$.subscribe(
      (data: any) => {
        this.areCareGapFiltersLoaded = data;
      }
    );
    this.locationDataSub = this.careGapService.locationData$.subscribe(
      (data: any) => {
        if (
          Object.keys(data).length !== 0 &&
          data.geo_level_2.length > 0 &&
          data.geo_level_3.length > 0
        ) {
          if (
            this.stages?.length === 0 ||
            this.location?.selectedValue !== data?.selectedValue
          ) {
            this.location = JSON.parse(JSON.stringify(data));
            // localStorage.setItem('locationData', JSON.stringify(this.location));
            if (this.location?.callStages) {
              this.getpatientStageinfo();
            }
          } else {
            this.location = JSON.parse(JSON.stringify(data));
            // localStorage.setItem('locationData', JSON.stringify(this.location));
            if (this.location?.callStages) {
              let key: any = {
                stage_id:
                  parseInt(localStorage.getItem('selectedStageID')) ||
                  this.previousSelectedStage,
                stage_name:
                  localStorage.getItem('selectedStageName') ||
                  this.pageParams.previousScreen,
              };
              if (
                this.stages.find((e) => e.stage_id === key.stage_id) &&
                this.stages.find((e) => e.stage_name === key.stage_name)
              ) {
                key = {
                  stage_id: key.stage_id,
                  stage_name: key.stage_name,
                };
                this.previousSelectedStage = key.stage_id;
                this.pageParams.previousScreen = key.stage_name;
              } else {
                if (this.stages[0].stage_id !== this.stages[1].stage_id) {
                  key = {
                    stage_id: this.stages[0].stage_id,
                    stage_name: this.stages[0].stage_name,
                  };
                  this.previousSelectedStage = JSON.parse(
                    JSON.stringify(this.stages[0].stage_id)
                  );
                  this.pageParams.previousScreen = JSON.parse(
                    JSON.stringify(this.stages[0].stage_name)
                  );
                  localStorage.setItem(
                    'selectedStageID',
                    this.stages[0].stage_id
                  );
                  localStorage.setItem(
                    'selectedStageName',
                    this.stages[0].stage_name
                  );
                }
              }

              this.pageParams.screen = key.stage_name;
              this.selectedStage = key.stage_id;
              // this.pageParams.screen = JSON.parse(
              //   JSON.stringify(this.pageParams.previousScreen)
              // );
              // this.selectedStage = JSON.parse(
              //   JSON.stringify(this.previousSelectedStage)
              // );
              this.careGapService.stagesData$.next(this.stages);
              this.onStageChange(key);
            }
          }
          this.location.selectedGeoLocation = this.setGeoLocation();

          localStorage.removeItem('locationData');
          localStorage.setItem(
            'locationData',
            LZString.compressToUTF16(JSON.stringify(this.location))
          );
        }
        if (this.isComponentLoaded) {
          // this.onLocationChange(this.location.selectedValue, false);
        }
      }
    );
    this.careGapService.updateFilter$.subscribe((data: any) => {
      if (data) {
        this.onLocationChange(this.location.selectedValue, true);
      }
    });
    this.stagesDataSub = this.careGapService.stagesData$.subscribe(
      (data: any) => {
        if (Object.keys(data).length !== 0) {
          (this.stages = data), (this.careGapService.stages = data);
        }
        if (this.isComponentLoaded) {
          this.stageListStatus = true;
        }
      }
    );

    this.selectedStageSub = this.careGapService.selectedStage$.subscribe(
      (data: any) => {
        this.selectedStage = data.stage_id;
        this.pageParams.screen = data.stage_name;
      }
    );

    this.careGapsDataSub = this.careGapService.careGapsData$.subscribe(
      (data: any) => {
        if (Object.keys(data).length !== 0) {
          this.careGaps = data;
          // this.careGapService.locationData$.next(this.location);
        }
      }
    );
    if (this.oldRoute !== this.router.url) {
      if (this.router.url.includes('systemic-barriers')) {
        this.isSystematicBarriers = true;
        // if (this.selectedStage) {
        //   this.getCareGapDropdown();
        // }
      } else {
        if (this.oldRoute.includes('systemic-barriers')) {
          this.careGaps.selectedValue = ['all'];
          this.careGaps.previousSelList = [];
          this.careGaps.previousSelListNames = [];
        }
        this.isSystematicBarriers = false;
      }
    }
    this.isComponentLoaded = true;
  }

  onLocationChange(event: any, isTriggeredFromSameComponent?: boolean) {
    if (this.location?.selectedValue !== event) {
      this.showDropdown = false;
      this.location.selectedValue = event;
      if (event === 'All Locations (National View)') {
        this.location.subNationId = this.location.allNationId;
        this.location.selectedGeoLevel = 1;
      } else if (!this.location.selectedValue.includes(',')) {
        this.location.subNationId = this.location.geo_level_2.find(
          (item) => item.name === this.location.selectedValue
        ).sub_nation_id;
        this.location.selectedGeoLevel = 2;
      } else if (this.location.selectedValue.includes(',')) {
        let geo_level_2_nm = this.location.selectedValue.split(',')[0].trim();
        if (this.location?.geoLevels?.geo_level_3 === 'zip') {
          this.location.subNationId = this.location.geo_level_3.find(
            (item) => parseInt(item.name) === parseInt(geo_level_2_nm)
          ).sub_nation_id;
        } else {
          this.location.subNationId = this.location.geo_level_3.find(
            (item) => item.name === geo_level_2_nm
          ).sub_nation_id;
        }
        this.location.selectedGeoLevel = 3;
      }
      this.getSelLocation();
      // let key = {
      //   stage_id: this.previousSelectedStage,
      //   stage_name: this.pageParams.previousScreen,
      // };
      // this.pageParams.screen = key.stage_name;
      // this.selectedStage = key.stage_id;
      // this.careGapService.selectedStage$.next(key);
      if (this.location.selectedValue === 'All Locations (National View)') {
        this.location.subNationId = this.location.allNationId;
      }
      if (isTriggeredFromSameComponent) {
        this.location.selectedGeoLocation = this.setGeoLocation();
        // localStorage.removeItem('locationData');
        // localStorage.setItem(
        //   'locationData',
        //   LZString.compressToUTF16(JSON.stringify(this.location))
        // );
        this.location.callStages = false;
        // sessionStorage.setItem('locationData', JSON.stringify(this.location));
        this.careGapService.locationData$.next(this.location);
        this.getpatientStageinfo();
        // if (this.selectedStage) {
        //   this.getCareGapDropdown();
        // }
        this.careGapService.callAccountList$.next(true);
        // this.careGapService.callGeographyApi$.next(true);
      } else {
        this.getpatientStageinfo();
        // if (this.selectedStage) {
        //   this.getCareGapDropdown();
        // }
      }
    }
  }
  setGeoLocation() {
    let selState = '';
    let selCounty = '';
    if (this.location.selectedValue === 'All Locations (National View)') {
      return [];
    }
    let geo_location = [];
    if (this.location.selectedValue.includes(',')) {
      [selCounty, selState] = this.location.selectedValue?.split(',');
      this.location.geo_level_3.forEach((el) => {
        if (el.county == selCounty) {
          geo_location = [el.county_lat, el.county_long];
        }
      });
    } else {
      selState = this.location.selectedValue;
      this.location.geo_level_2.forEach((el) => {
        if (el.state == selState) {
          geo_location = [el.state_lat, el.state_long];
        }
      });
    }
    return geo_location;
  }
  getLocationDropdown() {
    this.location.isLoading = true;
    this.stageListStatus = false;
    this.careGaps.isLoading = true;
    const payload = {
      data_source: '',
      therapeutic_area: '',
      sub_therapeutic_area: this.selectedSubTherapy,
      // stage_id: this.selectedStage || 1,
      care_gap_id: [],
    };
    this.careGapService.getLocations(payload).subscribe({
      next: (res) => {
        this.location.isLoading = false;
        this.location.geo_level_3 = res.geo_level_3;
        this.location.geo_level_2 = res.geo_level_2;
        this.location.subNationId = res.geo_level_1[0].sub_nation_id;
        this.location.allNationId = res.geo_level_1[0].sub_nation_id;
        this.location.geoLevels = res.geo_levels;
        this.location.items = this.transformLocationData();
        this.getSelLocation();
        // this.careGapService.selectedSubTherapy$.next(this.selectedSubTherapy);
        localStorage.setItem(
          'locationData',
          LZString.compressToUTF16(JSON.stringify(this.location))
        );
        this.location.callStages = true;
        this.careGapService.locationData$.next(this.location);
        this.getSavedLocations();
        // this.getpatientStageinfo();
        // if (this.selectedStage) {
        //   this.getCareGapDropdown();
        // }
      },
      error: (error) => {
        this.location.isLoading = false;
        // this.toaster.toast('error', API_ERROR);
        this.toaster.handleApiError();
      },
    });
  }

  getpatientStageinfo() {
    this.stageListStatus = false;
    if (this.getStagesInfoSub) {
      this.getStagesInfoSub.unsubscribe();
    }
    let stageInfoData = {
      data_source: '',
      therapeutic_area: '',
      sub_therapeutic_area: this.selectedSubTherapy,
      sub_nation_id: this.getSubNationId(),
      geo_level: this.location.selectedGeoLevel,
    };
    this.stages = [];
    this.careGapService.stages = [];
    // stageInfoData['sub_nation_id'] = this.location.subNationId;
    this.getStagesInfoSub = this.careGapService
      .getStagesInfo(stageInfoData)
      .subscribe({
        next: (res) => {
          if (Utils.isArrayExists(res.patient_stages)) {
            // res.patient_stages.map((item: any) => {
            //   item.stage_name = item.stage_name + 'Hi Can we connect over call';
            // });
            this.stages = res.patient_stages;
            this.careGapService.stages = res.patient_stages;
            this.stageListStatus = true;
            // let key = {
            //   stage_id: this.stages[0].stage_id,
            //   stage_name: this.stages[0].stage_name,
            // };
            // let key = { stage_id: 1, stage_name: 'All Stages' };
            let key: any = {
              stage_id:
                parseInt(localStorage.getItem('selectedStageID')) ||
                this.previousSelectedStage,
              stage_name:
                localStorage.getItem('selectedStageName') ||
                this.pageParams.previousScreen,
            };
            if (
              this.stages.find((e) => e.stage_id === key.stage_id) &&
              this.stages.find((e) => e.stage_name === key.stage_name)
            ) {
              key = {
                stage_id: key.stage_id,
                stage_name: key.stage_name,
              };
              this.previousSelectedStage = key.stage_id;
              this.pageParams.previousScreen = key.stage_name;
            } else {
              if (this.stages[0].stage_id !== this.stages[1].stage_id) {
                key = {
                  stage_id: this.stages[0].stage_id,
                  stage_name: this.stages[0].stage_name,
                };
                this.previousSelectedStage = JSON.parse(
                  JSON.stringify(this.stages[0].stage_id)
                );
                this.pageParams.previousScreen = JSON.parse(
                  JSON.stringify(this.stages[0].stage_name)
                );
                localStorage.setItem(
                  'selectedStageID',
                  this.stages[0].stage_id
                );
                localStorage.setItem(
                  'selectedStageName',
                  this.stages[0].stage_name
                );
              }
            }

            this.pageParams.screen = key.stage_name;
            this.selectedStage = key.stage_id;
            // this.pageParams.screen = JSON.parse(
            //   JSON.stringify(this.pageParams.previousScreen)
            // );
            // this.selectedStage = JSON.parse(
            //   JSON.stringify(this.previousSelectedStage)
            // );
            this.careGapService.stagesData$.next(this.stages);
            this.onStageChange(key);
          }
        },
        error: (error) => {
          this.toaster.handleApiError();
        },
      });
  }

  transformLocationData() {
    let tempData = [];
    this.location.geo_level_3.map((county) => {
      const index = tempData.findIndex(
        (data) => data.key == county.geo_level_2
      );
      if (index == -1) {
        tempData.push({
          title: county.geo_level_2,
          key: county.geo_level_2,
          children: [
            {
              title: county.name + ', ' + county.geo_level_2,
              key: county.name + ', ' + county.geo_level_2,
              isLeaf: true,
            },
          ],
        });
      } else {
        tempData[index]['children'].push({
          title: county.name + ', ' + county.geo_level_2,
          key: county.name + ', ' + county.geo_level_2,
          isLeaf: true,
        });
      }
    });
    const defaultLocation = {
      title: 'All Locations (National View)',
      key: 'All Locations (National View)',
      selectable: false,
      expanded: true,
      children: tempData,
    };
    let locationData = JSON.parse(JSON.stringify([defaultLocation]));
    return locationData;
  }
  getCareGapDropdown() {
    if (this.careGapDropdownSub) {
      this.careGapDropdownSub?.unsubscribe();
    }
    this.isFirstload = false;
    this.careGaps.isLoading = true;
    this.careGaps.items = [];
    this.careGaps.selCount = 0;
    this.careGaps.allChecked = true;
    this.careGaps.uiValue = 'All Care Gaps';
    this.careGaps.indeterminate = false;
    this.careGaps.previousSelList = [];
    this.careGaps.previousSelListNames = [];
    const payload = {
      data_source: '',
      therapeutic_area: '',
      sub_therapeutic_area: this.selectedSubTherapy,
      stage_id: this.selectedStage,
      sub_nation_id: this.getSubNationId(),
      geo_level: this.location.selectedGeoLevel,
      page: this.isSystematicBarriers ? 'systemic_barrier' : '',
    };
    payload['sub_nation_id'] = this.location.subNationId;
    this.careGapDropdownSub = this.careGapService
      .getCareGaps(payload)
      .subscribe({
        next: (res) => {
          this.careGaps.isLoading = false;
          this.careGaps.items = [...res.care_gaps];
          let caregapNames = [];
          let oldCaregapNames = [];
          let oldCareGaps = localStorage.getItem('careGaps')
            ? JSON.parse(
                LZString.decompressFromUTF16(localStorage.getItem('careGaps'))
              )
            : { ...this.careGaps };
          if (localStorage.getItem('careGaps')) {
            this.careGaps.items.forEach((item: any) => {
              caregapNames.push(item.care_gap_nm);
            });
            oldCareGaps.items.forEach((item: any) => {
              oldCaregapNames.push(item.care_gap_nm);
            });
            if (
              oldCaregapNames.every((item) => caregapNames.includes(item)) ||
              oldCareGaps.previousSelListNames.every((item) =>
                caregapNames.includes(item)
              )
            ) {
              this.careGaps = JSON.parse(JSON.stringify({ ...oldCareGaps }));
              this.careGaps.items = JSON.parse(
                JSON.stringify([...res.care_gaps])
              );
            }
          }
          this.careGaps.items.map((item: any) => {
            if (this.careGaps.allChecked) {
              item.checked = true;
              localStorage.removeItem('careGaps');
              this.careGaps.previousSelList.includes(item.care_gap_id) ||
                this.careGaps.previousSelList.push(item.care_gap_id);
              this.careGaps.previousSelListNames.includes(item.care_gap_nm) ||
                this.careGaps.previousSelListNames.push(item.care_gap_nm);
            } else {
              if (
                this.careGaps?.previousSelList?.length !== 0 &&
                this.careGaps?.previousSelList.includes(item?.care_gap_id)
              ) {
                item.checked = true;
              } else {
                item.checked = false;
              }
            }
          });
          if (this.careGaps.allChecked) {
            this.careGaps.selectedValue = ['all'];
            this.careGaps.selectedChips = [];
          }
          if (
            oldCaregapNames.every((item) => caregapNames.includes(item)) ||
            oldCareGaps.previousSelListNames.every((item) =>
              caregapNames.includes(item)
            )
          ) {
            this.getSelectedChips();
          }
          // this.careGaps.selectedValue = ['all'];
          // this.careGaps.selectedChips = [];
          this.careGapService.careGapsData$.next(this.careGaps);
          // if (
          //   this.oldRoute.includes('systemic-barriers') &&
          //   this.oldRoute !== this.router.url
          // ) {
          // this.careGapService.careDropdownUpdate$.next(true);
          // }
          localStorage.setItem('oldRoute', this.router.url);
        },
        error: (error) => {
          this.careGaps.isLoading = false;
          // this.toaster.toast('error', API_ERROR);
          this.toaster.handleApiError();
        },
      });
  }
  getSelLocation() {
    if (this.location.selectedValue.includes('All Locations (National View)')) {
      this.location.backendValue = [];
      return [];
    }
    let selLocation = [];
    if (this.location.selectedValue.indexOf(',') > -1) {
      let location = this.location.selectedValue.split(',');
      selLocation.push({
        county: location[0],
        state: location[1],
        sub_nation_id: this.getSubNationId(),
      });
    } else {
      selLocation.push({
        state: this.location.selectedValue,
        sub_nation_id: this.getSubNationId(),
      });
    }
    this.location.backendValue = selLocation;
    return selLocation;
  }
  getSubNationId() {
    let sub_nation_id;
    let state_code;
    let geo_level;
    let selLocation = [];
    if (this.location.selectedValue.includes('All Locations (National View)')) {
      sub_nation_id = this.location.allNationId;
      geo_level = 1;
    } else if (this.location.selectedValue.indexOf(',') > -1) {
      this.location.geo_level_3.some((data) => {
        let [county, state] = this.location.selectedValue
          .split(',')
          .map((item) => item.trim());

        if (data.name == county && data.geo_level_2 == state) {
          sub_nation_id = data.sub_nation_id;
          state_code = data.geo_level_2_code;
          geo_level = data.level;
        }
      });
      let location = this.location.selectedValue.split(',');
      selLocation.push({
        county: location[0],
        state: location[1],
      });
    } else {
      this.location.geo_level_2.some((data) => {
        if (data.name == this.location.selectedValue) {
          sub_nation_id = data.sub_nation_id;
          state_code = data.geo_level_2_code;
          geo_level = data.level;
        }
      });
      selLocation.push({
        state: this.location.selectedValue,
      });
    }
    this.location.subNationId = sub_nation_id;
    this.location.stateCode = state_code;
    this.location.selectedGeoLevel = geo_level;
    this.location.backendValue = selLocation;
    return sub_nation_id;
  }
  getSelectedChips() {
    let totalLength = 0;
    this.careGaps.selectedChips = [];
    for (let i = 0; i < this.careGaps.previousSelListNames.length; i++) {
      totalLength += this.careGaps.previousSelListNames[i].length;
      if (totalLength >= 100) {
        break;
      } else {
        let name = this.careGaps.previousSelListNames[i];
        const selItem = this.careGaps.items.find(
          (item: any) => item.care_gap_nm === name
        );
        this.careGaps.selectedChips.push({
          name: name,
          count: selItem?.total_impacted_pat_ct || 0,
        });
      }
    }
  }

  onResetClick() {
    let key = {
      stage_id: this.stages[0].stage_id,
      stage_name: this.stages[0].stage_name,
    };
    //Resetting Location
    this.location.selectedValue = 'All Locations (National View)';
    this.location.searchText = '';
    this.location.backendValue = [];
    this.location.subNationId = this.location.allNationId;
    this.location.selectedGeoLevel = 1;
    localStorage.removeItem('careGaps');
    localStorage.removeItem('locationData');
    localStorage.setItem(
      'locationData',
      LZString.compressToUTF16(JSON.stringify(this.location))
    );
    this.location.callStages = true;
    this.careGapService.locationData$.next(this.location);
    this.isResetClicked.emit(true);
    //Resetting Stages
    this.pageParams.screen = key.stage_name;
    this.selectedStage = key.stage_id;
    this.pageParams.previousScreen = key.stage_name;
    this.previousSelectedStage = key.stage_id;
    localStorage.setItem('selectedStageID', key.stage_id);
    localStorage.setItem('selectedStageName', key.stage_name);
    // this.careGapService.selectedStage$.next(key);
    this.getpatientStageinfo();
    if (this.currentScreen === 'patientSegment') {
      this.careGapService.updateSegmentProfiler$.next(true);
      this.careGapService.updateCareGapProfile$.next(true);
    } else {
      //Resetting Care Gaps Dropdown
      // this.careGaps = this.careGapService.careGaps;
      // this.careGapService.careGapsData$.next(this.careGaps);
      // this.getCareGapDropdown();
      // this.careGapService.callAccountList$.next(true);
      // this.careGapService.callGeographyApi$.next(true);
    }
  }

  onLocationSearch(event: any) {
    this.location.searchText = event.trim();
  }
  onApplyClick() {
    // event.stopPropagation();
    this.isCareGapOpen = false;
    let currentState = [] as any[];
    let currentNames = [] as any[];
    // this.selectedCaregaps = [];
    this.careGaps.items.map((item: any) => {
      if (item.checked) {
        currentState.push(item.care_gap_id);
        currentNames.push(item.care_gap_nm);
        // this.selectedCaregaps.push(item.care_gap_id);
      }
    });
    if (this.careGaps.allChecked) {
      this.selectedCaregaps = [];
    }
    this.careGaps.previousSelList = [...currentState];
    this.careGaps.previousSelListNames = [...currentNames];
    this.updateSelCount();
    this.updateApplyState();
    this.getSelectedChips();
    this.careGapService.careGapsData$.next(this.careGaps);
    localStorage.setItem(
      'careGaps',
      LZString.compressToUTF16(JSON.stringify(this.careGaps))
    );
    this.careGapService.callAccountList$.next(true);
    this.careGapService.callGeographyApi$.next(true);
  }

  updateAllChecked(): void {
    this.careGaps.indeterminate = false;
    if (this.careGaps.allChecked) {
      this.careGaps.items = this.careGaps.items.map((item) => ({
        ...item,
        checked: true,
      }));
    } else {
      this.careGaps.items = this.careGaps.items.map((item) => ({
        ...item,
        checked: false,
      }));
    }
    this.updateApplyState();
  }
  onTagRemoved(event: any) {
    const index = this.careGaps.previousSelListNames.findIndex(
      (item) => item == event
    );
    if (index !== -1) {
      this.careGaps.previousSelListNames.splice(index, 1);
    }
    const defIndex = this.careGaps.items.findIndex(
      (item) => item.care_gap_nm == event
    );
    if (defIndex !== -1) {
      this.careGaps.items[defIndex].checked = false;
      let id = this.careGaps.items[defIndex].care_gap_id;
      let pIndex = this.careGaps.previousSelList.findIndex(
        (item) => item == id
      );
      if (pIndex !== -1) {
        this.careGaps.previousSelList.splice(pIndex, 1);
      }
    }
    if (this.careGaps.previousSelList.length == 0) {
      // case when 0 selected
      this.careGaps.items.map((item: any) => {
        item.checked = true;
        this.careGaps.previousSelList.push(item.care_gap_id);
        this.careGaps.previousSelListNames.push(item.care_gap_nm);
      });
    }
    this.updateSingleChecked();
    this.updateSelCount();
    this.getSelectedChips();
    this.careGapService.careGapsData$.next(this.careGaps);
    this.careGapService.callGeographyApi$.next(true);
  }
  updateSingleChecked(): void {
    if (this.careGaps.items.every((item) => !item.checked)) {
      this.careGaps.allChecked = false;
      this.careGaps.indeterminate = false;
    } else if (this.careGaps.items.every((item) => item.checked)) {
      this.careGaps.allChecked = true;
      this.careGaps.indeterminate = false;
    } else {
      this.careGaps.indeterminate = true;
      this.careGaps.allChecked = false;
    }
    this.updateApplyState();
  }
  onCareGapsSearch(event: any) {
    this.careGaps.searchText = event.trim();
  }
  pageParams = {
    screen: 'All Stages',
    previousScreen: 'All Stages',
  };
  onStageChange(key: any) {
    if (
      key.total_impacted_pat_ct !== 0 &&
      this.stages[0].stage_id !== this.stages[1].stage_id
    ) {
      this.previousSelectedStage = JSON.parse(JSON.stringify(key.stage_id));
      this.pageParams.previousScreen = JSON.parse(
        JSON.stringify(key.stage_name)
      );
      this.pageParams.screen = key.stage_name;
      this.selectedStage = key.stage_id;
      localStorage.setItem('selectedStageID', key.stage_id);
      localStorage.setItem('selectedStageName', key.stage_name);
      this.careGapService.selectedStage$.next(key);
      this.getCareGapDropdown();
      this.careGapService.callAccountList$.next(true);
      this.careGapService.callGeographyApi$.next(true);
      this.change.detectChanges();
    }
  }
  updateSelCount() {
    this.careGaps.selCount = this.careGaps.items.filter(
      (item) => item.checked
    ).length;
    this.careGaps.uiValue = this.careGaps.allChecked
      ? 'All Care Gaps'
      : `${this.careGaps.selCount} Care Gaps Selected `;
  }
  updateApplyState() {
    let currentState = [] as any[];
    this.careGaps.items.map((item: any) => {
      if (item.checked) {
        currentState.push(item.care_gap_id);
      }
    });
    if (
      currentState.length === this.careGaps.previousSelList.length &&
      currentState.every((x: any) => this.careGaps.previousSelList.includes(x))
    ) {
      this.careGaps.isApplyDisabled = true;
    } else if (currentState.length === 0) {
      this.careGaps.isApplyDisabled = true;
    } else this.careGaps.isApplyDisabled = false;
  }

  onSavedLocationClick() {
    this.showLocationsModal = true;
  }

  getSavedLocations() {
    this.savedLocations.isLoading = false;
    this.careGapService.getSavedLocations().subscribe({
      next: (res) => {
        this.savedLocations.geo_level_2 = res.geo_level_2 || [];
        this.savedLocations.geo_level_3 = res.geo_level_3 || [];
        if (this.savedLocations?.geo_level_3.length > 0) {
          this.savedLocations.geo_level_3.map((item: any) => {
            item.geo_level_2_code = this.location.geo_level_3.find(
              (loc) =>
                loc.geo_level_2 === item.geo_level_2_nm.trim() &&
                loc.name === item.geo_level_3_nm.trim()
            ).geo_level_2_code;
          });
        }
        this.savedLocations.isLoading = false;
        localStorage.setItem(
          'savedLocations',
          LZString.compressToUTF16(JSON.stringify(this.savedLocations))
        );
        // this.location.isSaved = this.isLocationSaved();
      },
      error: (error) => {
        this.savedLocations.isLoading = false;
        // this.toaster.toast('error', API_ERROR);
        this.toaster.handleApiError();
      },
    });
  }

  onSavedLocationsClose(event: any) {
    this.showLocationsModal = !event;
    // this.isLocationSaved();
    this.getSavedLocations();
  }

  isLocationSaved(
    item: any,
    level: string,
    checkSaveLocationArray: boolean = false
  ) {
    if (item?.name === 'All Locations (National View)') {
      return false;
    }
    // let savedLocation = this.getSavedLocationId(this.location.selectedValue);
    // if () return true;
    if (
      level === 'geo_level_2' &&
      this.savedLocations.geo_level_2?.length > 0
    ) {
      if (
        this.savedLocations[level].find((loc) =>
          checkSaveLocationArray
            ? loc?.geo_level_2_nm?.toLowerCase().trim() ===
              item.geo_level_2_nm?.toLowerCase().trim()
            : loc?.geo_level_2_nm?.toLowerCase().trim() ===
              item.name?.toLowerCase().trim()
        )
      ) {
        return true;
      }
    }
    if (
      level === 'geo_level_3' &&
      this.savedLocations.geo_level_3?.length > 0
    ) {
      if (
        this.savedLocations[level].find((loc) =>
          checkSaveLocationArray
            ? loc?.geo_level_3_nm?.toLowerCase().trim() ===
                item.geo_level_3_nm?.toLowerCase().trim() &&
              loc?.geo_level_2_nm?.toLowerCase().trim() ===
                item.geo_level_2_nm?.toLowerCase().trim()
            : loc?.geo_level_3_nm?.toLowerCase().trim() ===
                item.name?.toLowerCase().trim() &&
              loc?.geo_level_2_nm?.toLowerCase().trim() ===
                item.geo_level_2?.toLowerCase().trim()
        )
      ) {
        return true;
      }
    }
    return false;
  }

  getSavedLocationId(savedLocation: any) {
    if (savedLocation.includes(',')) {
      let geo_level_3 = savedLocation.split(',')[0];
      let geo_level_3_index = -1;
      geo_level_3_index = this.savedLocations?.geo_level_3?.findIndex(
        (c) => c.geo_level_3_nm === geo_level_3
      );
      if (
        (geo_level_3_index && geo_level_3_index !== -1) ||
        geo_level_3_index >= 0
      ) {
        return this.savedLocations.geo_level_3[geo_level_3_index];
      } else {
        return {};
      }
    } else {
      let geo_level_2 = savedLocation;
      let geo_level_2_index = -1;
      geo_level_2_index = this.savedLocations?.geo_level_2?.findIndex(
        (c) => c.geo_level_2_nm === geo_level_2
      );
      if (
        (geo_level_2_index && geo_level_2_index !== -1) ||
        geo_level_2_index >= 0
      ) {
        return this.savedLocations.geo_level_2[geo_level_2_index];
      } else {
        return {};
      }
    }
  }

  saveLocation(locationName: string) {
    let geo_level_2_nm = '';
    let geo_level_3_nm = '';
    if (locationName.includes(',')) {
      [geo_level_3_nm, geo_level_2_nm] = locationName.split(',');
    } else {
      geo_level_2_nm = locationName;
    }
    const payload = {
      location_type: locationName.includes(',') ? 'geo_level_3' : 'geo_level_2',
      geo_level_2_nm: geo_level_2_nm.trim(),
      geo_level_3_nm: geo_level_3_nm.trim(),
    };
    this.careGapService.saveLocation(payload).subscribe({
      next: (res) => {
        if ('message' in res) {
          this.location.isSaved = false;
          this.toaster.toast('error', res.message);
        } else if (res.created_id) {
          this.location.isSaved = true;
          this.toaster.toast('success', 'Location saved successfully');
          let addsavedLocation = {
            geo_level_2_nm: payload.geo_level_2_nm,
            geo_level_3_nm: payload.geo_level_3_nm,
            id: res.created_id,
            location_type: payload.location_type,
          };
          if (addsavedLocation.location_type === 'geo_level_3') {
            addsavedLocation['geo_level_2_code'] =
              this.location.geo_level_3.find(
                (loc) =>
                  loc.geo_level_2 === addsavedLocation.geo_level_2_nm.trim() &&
                  loc.name === addsavedLocation.geo_level_3_nm.trim()
              ).geo_level_2_code;
          }
          this.savedLocations[payload.location_type].push(addsavedLocation);
          localStorage.setItem(
            'savedLocations',
            LZString.compressToUTF16(JSON.stringify(this.savedLocations))
          );
          // this.getSavedLocations();
        }
      },
      error: (error) => {
        // this.toaster.toast('error', API_ERROR);
        this.toaster.handleApiError();
      },
    });
  }

  unsaveLocation(locationName: string) {
    let savedLocation = this.getSavedLocationId(locationName);
    if (savedLocation['id']) {
      let id = savedLocation['id'];
      const payload = {
        id: id,
      };
      this.careGapService.deleteLocation(payload).subscribe({
        next: (res) => {
          if ('message' in res) {
            this.toaster.toast('success', 'Location deleted successfully');
            this.location.isSaved = false;
            let updateSavedLocations = this.savedLocations[
              savedLocation.location_type
            ].filter((item) => item?.id !== savedLocation['id']);
            this.savedLocations[savedLocation.location_type] = JSON.parse(
              JSON.stringify(updateSavedLocations)
            );
            localStorage.setItem(
              'savedLocations',
              LZString.compressToUTF16(JSON.stringify(this.savedLocations))
            );
            // if(savedLocation.location_type === 'geo_level_2')
            // this.getSavedLocations();
          }
        },
        error: (error) => {
          // this.toaster.toast('error', API_ERROR);
          this.toaster.handleApiError();
        },
      });
    }
  }

  arrowRight() {
    if (!this.rightHide) {
      this.scrollLeftPosition += 3;
      this.ds.moveTo(this.scrollLeftPosition);
    }
  }

  onDragEndRight(event) {
    this.rightHide = event;
  }

  onDragEndLeft(event) {
    this.leftHide = event;
  }

  arrowLeft() {
    this.scrollLeftPosition = 0;
    this.ds.moveTo(this.scrollLeftPosition);
  }

  visibleDropdownChange(event) {
    this.showDropdown = event;
    this.locationSearchValue = '';
    this.geolevel2SeachResults = [];
    this.geolevel3SeachResults = [];
    if (!this.showDropdown) {
      this.getSavedLocations();
      this.showMoreStateClicked = false;
      this.showMoreGeo3Clicked = false;
    }
  }

  valueChange(event) {
    this.locationSearchValue = event;
    this.geolevel2SeachResults = [];
    this.geolevel3SeachResults = [];
    if (this.locationSearchValue?.length > 2) {
      this.isSearchResultLoading = true;
      let level2Results = [];
      let level3Results = [];
      level2Results = this.location?.geo_level_2.filter((item) =>
        item?.name
          .toLowerCase()
          .includes(this.locationSearchValue.toLowerCase())
      );
      level3Results = this.location?.geo_level_3.filter((item) =>
        item?.name
          .toLowerCase()
          .includes(this.locationSearchValue.toLowerCase())
      );
      if (level2Results?.length > 0) {
        // this.geolevel2SeachResults = [];
        this.geolevel2SeachResults = JSON.parse(JSON.stringify(level2Results));
      }
      if (level3Results?.length > 0) {
        // this.geolevel3SeachResults = [];
        this.geolevel3SeachResults = JSON.parse(JSON.stringify(level3Results));
      }
      this.isSearchResultLoading = false;
    }
  }

  updateRecentSearch(clickedLocation: any, level: string) {
    this.recentSearches[level].push(clickedLocation);
    if (this.recentSearches[level].length > 5) {
      this.recentSearches[level].shift();
    }
    localStorage.setItem(
      'recentSearches',
      LZString.compressToUTF16(JSON.stringify(this.recentSearches))
    );
  }

  displayCareGapDropdown() {
    if (this.currentScreen !== 'patientSegment') {
      if (this.currentScreen !== 'systemicBarriers') {
        return true;
      } else {
        if (this.showCareGapDropdown) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  displaySystemicBarrierTags() {
    if (this.currentScreen !== 'systemicBarriers') {
      return true;
    } else {
      if (this.showCareGapDropdown) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy(): void {
    this.selectedStageSub?.unsubscribe();
    this.stagesDataSub?.unsubscribe();
    this.locationDataSub?.unsubscribe();
    this.careGapsDataSub?.unsubscribe();
  }
}
