import {
  Component,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { CareGapService } from '../../../core/services/care-gap.service';
import { ToasterService } from '../../../core/services/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-saved-locations',
  templateUrl: './saved-locations.component.html',
  styleUrls: ['./saved-locations.component.scss'],
})
export class SavedLocationsComponent {
  locationDataSub: Subscription;
  @Input() savedLocations = {};

  @Input() isVisible = false;

  @Output() onClose = new EventEmitter<boolean>();
  location = {
    selectedValue: 'All Locations (National View)',
    geo_level_3: [] as any[],
    geo_level_2: [] as any[],
    searchText: '',
    isLoading: true,
    items: [] as any[],
    backendValue: [],
    isSaved: false,
    isSaveIconVisible: false,
    selectedGeoLocation: [] as any,
    subNationId: 100001,
    stateCode: '',
    allNationId: 0,
    selectedGeoLevel: 1,
    geoLevels: {},
  };
  geoLevel2Table = {
    tableColumn: [
      {
        name: '',
        key: 'geo_level_2_nm',
        dataType: 'string',
        align: 'left',
        sortOrder: null,
        defaultSortOrder: 'asc',
        width: '40%',
        type: 'clickable',
      },
      {
        key: 'delete',
        type: 'actions',
        width: '10%',
        actionIcons: [
          {
            type: 'custom:delete',
            key: 'delete',
            title: 'Delete',
            style: {
              fontSize: '15px',
            },
          },
        ],
      },
    ],
    tableData: [],
    errorMessage: 'DATA NOT AVAILABLE',
  };

  geoLevel3Table = {
    tableColumn: [
      {
        name: '',
        key: 'geo_level_3_nm',
        dataType: 'string',
        align: 'left',
        sortOrder: null,
        defaultSortOrder: 'asc',
        width: '40%',
        type: 'clickable',
      },
      {
        key: 'delete',
        type: 'actions',
        width: '10%',
        actionIcons: [
          {
            type: 'custom:delete',
            key: 'delete',
            title: 'Delete',
            style: {
              fontSize: '15px',
            },
          },
        ],
      },
    ],
    tableData: [],
    errorMessage: 'DATA NOT AVAILABLE',
  };

  constructor(
    private careGapService: CareGapService,
    private toaster: ToasterService
  ) {}
  ngOnInit(): void {
    this.locationDataSub = this.careGapService.locationData$.subscribe(
      (data: any) => {
        this.location = data;
        this.setTables();
      }
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['savedLocations']) {
      this.setTableData();
    }
  }
  setTables() {
    if (this.geoLevel2Table.tableColumn[0].key == 'geo_level_2_nm') {
      this.geoLevel2Table.tableColumn[0].name = `Saved ${this.pluralizeWord(
        this.location.geoLevels['geo_level_2']
      )}`;
    }
    if (this.geoLevel3Table.tableColumn[0].key == 'geo_level_3_nm') {
      this.geoLevel3Table.tableColumn[0].name = ` Saved ${this.pluralizeWord(
        this.location.geoLevels['geo_level_3']
      )}`;
    }
  }
  setTableData() {
    this.geoLevel2Table.tableData = this.savedLocations['geo_level_2'];
    this.geoLevel3Table.tableData = this.savedLocations['geo_level_3'];
    this.geoLevel3Table.tableData?.map((data) => {
      data['visibleActionIcons'] = ['delete'];
      data['disabledActionIcons'] = [];
    });
    this.geoLevel2Table.tableData?.map((data) => {
      data['visibleActionIcons'] = ['delete'];
      data['disabledActionIcons'] = [];
    });
  }
  pluralizeWord(word) {
    // Handle words ending in 'y'
    if (word.endsWith('y')) {
      word = word.slice(0, -1) + 'ies';
      word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      return word;
    }
    // Add 's' to the end of the word
    word = word + 's';
    word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    return word;
  }
  onTableRowClick(event: any, type) {
    this.location.isSaved = true;
    this.location.isSaveIconVisible = true;
    if (event.column.key === 'geo_level_2_nm') {
      this.location.selectedValue = event.data.geo_level_2_nm;
      this.location.subNationId = this.location.geo_level_2.find(
        (loc) => loc.name === event.data.geo_level_2_nm
      ).sub_nation_id;
      this.location.selectedGeoLevel = 2;
      this.location.backendValue = [
        {
          geo_level_2: event.data.geo_level_2_nm,
        },
      ];

      //updating just selectedValue and backend field in locationData$
      this.careGapService.locationData$.next({
        ...this.careGapService.locationData$.getValue(),
        selectedValue: this.location.selectedValue,
        backendValue: this.location.backendValue,
        subNationId: this.location.subNationId,
        selectedGeoLevel: this.location.selectedGeoLevel,
        isSaved: this.location.isSaved,
        isSaveIconVisible: this.location.isSaveIconVisible,
      });
    } else if (event.column.key === 'geo_level_3_nm') {
      let geo_level_3_nm = event.data.geo_level_3_nm;
      let geo_level_2_nm = event.data.geo_level_2_nm;
      this.location.selectedValue = geo_level_3_nm + ', ' + geo_level_2_nm;
      this.location.selectedGeoLevel = 3;
      this.location.backendValue = [
        {
          geo_level_3: geo_level_3_nm,
          geo_level_2: geo_level_2_nm,
        },
      ];
      this.location.subNationId = this.location.geo_level_3.find(
        (loc) => loc.name === geo_level_3_nm
      ).sub_nation_id;
      //updating just selectedValue and backend value field in locationData$
      this.careGapService.locationData$.next({
        ...this.careGapService.locationData$.getValue(),
        selectedValue: this.location.selectedValue,
        backendValue: this.location.backendValue,
        subNationId: this.location.subNationId,
        selectedGeoLevel: this.location.selectedGeoLevel,
        isSaved: this.location.isSaved,
        isSaveIconVisible: this.location.isSaveIconVisible,
      });
    }

    if (event.column.key === 'delete') {
      this.unsaveLocation(event.data);
    } else {
      this.careGapService.callGeographyApi$.next(true);
      this.onModalClose();
    }
  }

  unsaveLocation(data: string) {
    const payload = {
      id: data['id'],
    };
    this.careGapService.deleteLocation(payload).subscribe({
      next: (res) => {
        if ('message' in res) {
          this.toaster.toast('success', 'Location deleted successfully');

          this.updateData(data);
        }
      },
      error: (error) => {},
    });
  }

  onModalClose() {
    this.onClose.emit(true);
  }

  updateData(data: any) {
    if (data['location_type'] === 'geo_level_2') {
      this.geoLevel2Table.tableData = this.geoLevel2Table.tableData.filter(
        (d) => d.id !== data.id
      );
    } else {
      this.geoLevel3Table.tableData = this.geoLevel3Table.tableData.filter(
        (d) => d.id !== data.id
      );
    }
  }
  ngOnDestroy(): void {
    this.locationDataSub?.unsubscribe();
  }
}
