import { HttpClient } from '@angular/common/http';
import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RdSiderMenuItem } from '@zs-ca/angular-cd-library';
import {
  LaunchBarApiResponse,
  LaunchBarData,
  UserDetails,
} from './core/models/sider.model';
import { AuthService } from './core/services';
import { CustomIconService } from './core/services/custom-icon.service';

import { ApiEndpoints } from './core/constants/api-endpoints';
import { SOMETHING_WENT_WRONG } from './core/constants/messages';
//import { LaunchBarApiResponse, LaunchBarData, UserDetails } from '@core/models';

import { filter, forkJoin } from 'rxjs';
import { ToasterService } from './core/services/toaster.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  errorMessage = '';

  isLoading = false;

  launchBarData?: LaunchBarData;

  sideBarMenuItemsList: RdSiderMenuItem[] = [];

  @ViewChild('successNotification', { static: false })
  successNotification!: TemplateRef<void>;

  @ViewChild('errorNotification', { static: false })
  errorNotification!: TemplateRef<void>;

  @ViewChild('warningNotification', { static: false })
  warningNotification!: TemplateRef<void>;

  @ViewChild('infoNotification', { static: false })
  infoNotification!: TemplateRef<void>;

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private customIconService: CustomIconService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.customIconService.addCustomIconsToNzLibrary();
  }

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(event) {
    localStorage.clear();
  }

  ngOnInit(): void {
    this.fetchAuthData();
  }
  private fetchAuthData(): void {
    //this.customIconService.addCustomIconsToNzLibrary();
    this.sideBarMenuItemsList = this.auth.getAvailableScreens();

    this.isLoading = true;
    const launchBar$ = this.http.get<any>(ApiEndpoints.LAUNCH_BAR);

    const user$ = this.http.get<UserDetails>(ApiEndpoints.USER_INFO);
    forkJoin([launchBar$, user$]).subscribe({
      next: ([launchBarResponse, userDetailsResponse]) => {
        if (launchBarResponse?.applications?.length >= 0) {
          this.launchBarData = { ...launchBarResponse };
          if (userDetailsResponse) {
            this.auth.setUserDetails(userDetailsResponse);
            this.sideBarMenuItemsList = this.auth.getAvailableScreens();
          }
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
        }
        this.isLoading = false;
      },
      error: (error) => {
        if (error?.status === 401) {
          this.errorMessage = '';
        } else {
          this.errorMessage = SOMETHING_WENT_WRONG;
          this.isLoading = false;
        }
      },
    });
  }

  logout() {
    this.http.get(ApiEndpoints.LOGOUT).subscribe({
      next: (res) => {
        window.location.href = res['logout_url'];
      },
      error: () => {},
    });
  }

  ngOnDestroy() {
    localStorage.clear();
    this.logout();
  }
}
