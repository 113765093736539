import {
  summaryRadioButtons,
  careGapDistribution,
  summaryTable,
} from './care-gap.model';

export const CareGapConstants = {
  ALL: 'All',
  ALL_LOCATION: 'All Locations (National View)',
  NEGATIVE: 'Negative',
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral',
  NATIONAL_AVERAGE: 'National Average',
};
export type Toaster = 'error' | 'warning' | 'success' | 'info';

export const summaryRadioButtonData: summaryRadioButtons[] = [
  {
    name: 'States',
    value: 'State',
    type: 'custom:mapView',
    selectedType: 'custom:mapViewSelected',
  },
  {
    name: 'Counties',
    value: 'County',
    type: 'custom:county',
    selectedType: 'custom:countySelected',
  },
  {
    name: 'Providers',
    value: 'Providers',
    type: 'custom:hco',
    selectedType: 'custom:hcoSelected',
  },
];

export const summaryRadioButtonZipData: summaryRadioButtons[] = [
  {
    name: 'States',
    value: 'State',
    type: 'custom:mapView',
    selectedType: 'custom:mapViewSelected',
  },
  {
    name: 'ZIP',
    value: 'Zip',
    type: 'custom:county',
    selectedType: 'custom:countySelected',
  },
  {
    name: 'Providers',
    value: 'Providers',
    type: 'custom:hco',
    selectedType: 'custom:hcoSelected',
  },
];
export const summaryRadioButtonHCPData: summaryRadioButtons[] = [
  {
    name: 'Accounts',
    value: 'Accounts',
    type: 'custom:hco',
    selectedType: 'custom:hcoSelected',
  },
  {
    name: 'HCP',
    value: 'HCP',
    type: 'custom:hcp',
    selectedType: 'custom:hcpSelected',
  },
];

export const chartRadioButtonData: summaryRadioButtons[] = [
  {
    name: 'Volume',
    value: 'Volume',
    type: 'custom:volume',
    selectedType: 'custom:volumeSelected',
  },
  {
    name: 'Percentage',
    value: 'Percentage',
    type: 'custom:percentage',
    selectedType: 'custom:percentageSelected',
  },
];

export const HCPSummaryTableHeader: summaryTable[] = [
  {
    name: 'NPI ID',
    key: 'npi_id',
  },
  {
    name: 'HCP Name',
    key: 'hcp_name',
  },
  {
    name: 'Total Patients',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted Patients',
    key: 'total_impacted_pat_ct',
  },
];
export const HospitalsSummaryTableHeader: summaryTable[] = [
  {
    name: 'Account',
    key: 'category_nm',
  },
  {
    name: 'Total HCPs',
    key: 'total_hcp_ct',
  },
  {
    name: 'Total Patients',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted Patients',
    key: 'total_impacted_pat_ct',
  },
];
export const stateSummaryTableHeader: summaryTable[] = [
  {
    name: 'Top 10 States',
    key: 'category_nm',
  },
  {
    name: 'Patients',
    key: '',
  },
  {
    name: 'Geos',
    key: '',
  },
];
export const stateSummaryTableSubHeader: summaryTable[] = [
  {
    name: 'Total',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted',
    key: 'total_impacted_pat_ct',
  },
  {
    name: 'Total',
    key: 'total_geo_ct',
  },
  {
    name: 'Impacted',
    key: 'total_impacted_geo_ct',
  },
];
export const stateSummaryTableExtendedSubHeader: summaryTable[] = [
  {
    name: 'Total',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted',
    key: 'total_impacted_pat_ct',
  },
  {
    name: '% Impacted',
    key: 'percent_impacted_pat_ct',
  },
  {
    name: 'Vs National Average',
    key: 'percent_national_avg',
  },
  {
    name: 'Total',
    key: 'total_geo_ct',
  },
  {
    name: 'Impacted',
    key: 'total_impacted_geo_ct',
  },
  {
    name: '% Impacted',
    key: 'percent_impacted_geo_ct',
  },
];
export const countySummaryTableHeader: summaryTable[] = [
  {
    name: 'Top 10 Counties',
    key: 'category_nm',
  },
  {
    name: 'Total Patients',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted Patients',
    key: 'total_impacted_pat_ct',
  },
  {
    name: 'Vs National Average',
    key: 'percent_national_avg',
  },
];
export const zipSummaryTableHeader: summaryTable[] = [
  {
    name: 'Top 10 ZIPs',
    key: 'category_nm',
  },
  {
    name: 'Total Patients',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted Patients',
    key: 'total_impacted_pat_ct',
  },
  {
    name: 'Vs National Average',
    key: 'percent_national_avg',
  },
];
export const providersSummaryTableHeader: summaryTable[] = [
  {
    name: 'Top 10 Providers',
    key: 'category_nm',
  },
  {
    name: 'Total Patients',
    key: 'total_pat_ct',
  },
  {
    name: 'Impacted Patients',
    key: 'total_impacted_pat_ct',
  },
  {
    name: 'Vs National Average',
    key: 'percent_national_avg',
  },
];

export const segmentSummaryGrid = {
  name: 'Segment-Summary',
  tableColumns: [
    {
      name: 'Segments',
      key: 'segment_name',
      dataType: 'string',
      align: 'left',
      headerStyle: {
        borderBottom: '1px solid black',
      },
      style: {
        fontWeight: 600,
        fontSize: '0.8rem',
        color: '#454250',
      },
      type: 'variableStyleText',
      width: '30%',
      ellipsis: true,
    },
    {
      name: 'No. of Hotspots',
      key: 'total_hotspots',
      dataType: 'string',
      align: 'left',
      headerStyle: {
        borderBottom: '1px solid black',
      },
      style: {
        fontWeight: 600,
        fontSize: '0.8rem',
        color: '#454250',
      },
      type: 'variableStyleText',
      width: '10%',
    },
    {
      name: 'Total Patients',
      key: 'percent_pat_cnt',
      dataType: 'string',
      align: 'left',
      headerStyle: {
        borderBottom: '1px solid black',
      },
      style: {
        fontWeight: 600,
        fontSize: '0.8rem',
        color: '#454250',
      },
      type: 'variableStyleText',
      width: '10%',
    },
  ],
  pageIndex: 1,
  pageRange: [],
  noOfRecords: 0,
  sortColumn: 'lastUpdated',
  sortOrder: 'desc',
  tableData: [],
  isLoading: false,
  showError: false,
  errorMessage: 'DATA NOT AVAILABLE',
};

//progress bar constants
export const primaryColor = '#27a6a4';
export const pgHeight = 15;
export const pgFontSize = 14;
//progress bar constants ends here
export const barrierSummaryTable = [
  {
    name: 'Top 10 Barriers',
    key: 'barrier_sub_cat_nm',
  },
  {
    name: 'Relative Importance',
    key: 'relative_importance',
  },
  {
    name: 'Hotspots Impacted',
    key: 'impacted_hotspots',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
  },
];
export const barrierTableColumns = [
  {
    name: 'Top 10 Barriers',
    key: 'barrier_sub_cat_nm',
    dataType: 'string',
    align: 'left',
    // ellipsis: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 600, fontSize: '0.8rem' },
    type: 'variableStyleText',
    width: '10%',
  },
  {
    name: 'Relative Importance',
    key: 'relative_importance',
    dataType: 'string',
    align: 'left',
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 600, fontSize: '0.8rem' },
    type: 'text',
    width: '5%',
  },
  {
    name: 'Hotspots Impacted',
    key: 'impacted_hotspots',
    dataType: 'string',
    align: 'left',
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 600, fontSize: '0.8rem' },
    type: 'text',
    width: '5%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 600, fontSize: '0.8rem' },
    type: 'text',
    width: '5%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
];
export const barrierSummaryGeoLevel3Table = [
  {
    name: 'Top 10 barriers',
    key: 'barrier_sub_cat_nm',
  },
  {
    name: 'Relative Importance',
    key: 'relative_importance',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
  },
  // {
  //   name: 'Vs. National Average',
  //   key: 'nat_avg',
  // },
];

export const monthMapping = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const accountSettingSummaryGrid = [
  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    ellipsis: true,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '11%',
  },
  {
    name: 'Patients Managed',
    key: 'total_managed_pat_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '9%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#2F2C3C',
      // marginLeft: '1rem',
    },
    type: 'text',
    width: '11%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'Accounts',
    key: 'total_account_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '7%',
  },
  {
    name: 'HCPs',
    key: 'total_hcp_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '7%',
  },
];

export const expandedAccountSettingSummaryGrid = [
  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    ellipsis: true,
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '14%',
  },
  {
    name: 'Patients Managed',
    key: 'total_managed_pat_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '14%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '14%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'Accounts',
    key: 'total_account_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '12%',
  },
  {
    name: 'HCPs',
    key: 'total_hcp_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '12%',
  },
  {
    name: '% of Patients Impacted',
    key: 'percent_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '16%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'Vs. National Average',
    key: 'percent_national_avg',
    dataType: 'string',
    align: 'left',
    defaultSortOrder: null,
    sortDirections: ['ascend', 'descend'],
    sortable: true,
    headerStyle: { borderBottom: '1px solid black' },
    style: { fontWeight: 400, fontSize: '0.8rem', color: '#2F2C3C' },
    type: 'text',
    width: '14%',
  },
];

export const expandedAccountGrid = [
  {
    name: 'Rank',
    key: 'rank',
    dataType: 'number',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
    },
    type: 'text',
    width: '5%',
  },
  {
    name: 'Child Accounts',
    key: 'account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#27A6A4',
      cursor: 'pointer',
    },
    type: 'clickable',
    // ellipsis: true,
    width: '15%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '9%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'HCPs',
    key: 'total_hcp_ct',
    dataType: 'string',
    rowspan: 4,
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '7%',
  },
  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'Parent Accounts',
    key: 'parent_account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    // ellipsis: true,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    width: '15%',
  },
  {
    name: 'Patients Managed',
    key: 'total_managed_pat_ct',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '9%',
  },
  {
    name: 'Address',
    key: 'account_address',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '15%',
  },
  {
    name: 'Top Care Gap (Selected Only)',
    key: 'top_caregap_nm',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },

    type: 'text',
    width: '15%',
  },
];
export const accountGrid = [
  {
    name: 'Rank',
    key: 'rank',
    dataType: 'number',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'Child Accounts',
    key: 'account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    // ellipsis: true,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#27A6A4',
      cursor: 'pointer',
    },
    type: 'clickable',
    width: '25%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '14%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'HCPs',
    key: 'total_hcp_ct',
    dataType: 'string',
    rowspan: 4,
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '12%',
  },
  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '19%',
  },
  {
    name: 'Parent Accounts',
    key: 'parent_account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    // ellipsis: true,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    width: '20%',
  },
];
export const expandedHCPGrid = [
  {
    name: 'Rank',
    key: 'rank',
    dataType: 'number',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
      cursor: 'pointer',
    },
    type: 'text',
    width: '8%',
  },
  {
    name: 'HCP',
    key: 'hcp_nm',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#27A6A4',
      cursor: 'pointer',
    },
    type: 'clickable',
    width: '12%',
  },
  {
    name: 'NPI ID',
    key: 'hcp_npi',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'Specialty',
    key: 'hcp_speciality',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '7%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },

  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'Parent Accounts',
    key: 'parent_account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    // ellipsis: true,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    width: '10%',
  },
  {
    name: 'Address',
    key: 'hcp_address',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '12%',
  },
  {
    name: 'Location',
    key: 'location',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '9%',
  },
  {
    name: 'Top Care Gap (Selected Only)',
    key: 'top_caregap_nm',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },

    type: 'text',
    width: '12%',
  },
];
export const HCPGrid = [
  {
    name: 'Rank',
    key: 'rank',
    dataType: 'number',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
      cursor: 'pointer',
    },
    type: 'text',
    width: '10%',
  },
  {
    name: 'HCP',
    key: 'hcp_nm',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#27A6A4',
      cursor: 'pointer',
    },
    type: 'clickable',
    width: '15%',
  },
  {
    name: 'NPI ID',
    key: 'hcp_npi',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '15%',
  },
  {
    name: 'Specialty',
    key: 'hcp_speciality',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '13%',
  },
  {
    name: 'Patients Impacted',
    key: 'total_impacted_pat_ct',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '15%',
    showHeaderIconBefore: true,
    headerIconBefore: {
      type: 'custom:danger',
      style: { 'margin-right': '5px' },
    },
  },
  {
    name: 'Practice Setting',
    key: 'practice_setting',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    prefix: '',
    suffix: '',
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    type: 'text',
    width: '17%',
  },
  {
    name: 'Parent Accounts',
    key: 'parent_account_name',
    dataType: 'string',
    align: 'left',
    sortable: true,
    sortOrder: null,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: null,
    // ellipsis: true,
    headerStyle: {
      borderBottom: '1px solid black',
    },
    style: {
      fontWeight: 400,
      fontSize: '0.8rem',
      color: '#454250',
    },
    width: '15%',
  },
];

export const accountColumn = {
  name: 'Account',
  key: 'account_name',
  dataType: 'string',
  align: 'left',
  sortable: true,
  sortOrder: null,
  sortDirections: ['ascend', 'descend'],
  defaultSortOrder: null,
  prefix: '',
  suffix: '',
  headerStyle: {
    borderBottom: '1px solid black',
  },
  style: {
    fontWeight: 400,
    fontSize: '0.8rem',
    color: '#27A6A4',
  },
  type: 'text',
  width: '7%',
};
