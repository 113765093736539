import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CareGapService } from 'src/app/core/services/care-gap.service';
import { GoogleMapsService } from 'src/app/core/services/google-maps.service';

@Component({
  selector: 'app-state-google-map',
  templateUrl: './state-google-map.component.html',
  styleUrls: ['./state-google-map.component.scss'],
})
export class StateGoogleMapComponent implements OnInit, OnChanges {
  @Input() geographyData: any;
  @Input() isLoading: boolean = false;
  @Input() location: any;

  @Input() selectedRadio: any = null;
  @Input() mapFor: any = null;
  @Input() calledFrom: any = null;
  @Input() barrierCategory: any = null;
  @Input() toggleValue: any = null;
  @Input() mapListFor: any = null;

  geocoder: any;
  data: any;
  stateCoordinates = null;

  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;

  constructor(
    private mapsService: GoogleMapsService,
    private ngZone: NgZone,
    private careGapService: CareGapService
  ) {}

  private calculateZoomLevel(
    ne: google.maps.LatLng,
    sw: google.maps.LatLng
  ): number {
    const lngDiff = ne.lng() - sw.lng();
    const latDiff = ne.lat() - sw.lat();

    const adjustedLngDiff = lngDiff < 0 ? 360 + lngDiff : lngDiff;
    const mapWidthPx = 600;
    const worldWidthPx = 256;

    const horizontalZoom = Math.log2(
      (360 / adjustedLngDiff) * (mapWidthPx / worldWidthPx)
    );

    const mapHeightPx = 400;
    const worldHeightPx = 256;

    const adjustedLatDiff = latDiff !== 0 ? Math.max(latDiff, 0) : 1;
    const verticalZoom = Math.log2(
      (180 / adjustedLatDiff) * (mapHeightPx / worldHeightPx)
    );

    return Math.min(horizontalZoom, verticalZoom);
  }

  ngOnChanges(changes: SimpleChanges): void {
    var imgName;
    var x;
    var divElement;
    this.geocoder = new google.maps.Geocoder();

    if (
      changes['selectedRadio'] &&
      this.calledFrom === 'caregap' &&
      this.mapsService?.map
    ) {
      imgName = 'Primary-ZS-Teal.svg';
      x = `<div id="legend" > ${
        this.selectedRadio === 'Volume' ? 'Number of' : 'Percentage'
      } Patients Impacted: <span><img src=../../../assets/images/${imgName} alt=${imgName} /></span> </div>`;
      divElement = document.createElement('div');
      divElement.innerHTML = x;

      if (
        this.mapsService.map.controls[
          google.maps.ControlPosition.LEFT_BOTTOM
        ].getLength() > 0
      ) {
        this.mapsService.map.controls[
          google.maps.ControlPosition.LEFT_BOTTOM
        ].pop();
      }
      this.mapsService.map.controls[
        google.maps.ControlPosition.LEFT_BOTTOM
      ].push(divElement);

      if (this.mapFor === 'heat') {
        this.mapsService.featureLayer.style = (featureStyleFunctionOptions) => {
          if (this.geographyData !== null) {
            const placeFeature =
              featureStyleFunctionOptions.feature as google.maps.PlaceFeature;
            let fillColor;

            fillColor = this.geographyData[placeFeature.placeId]
              ? this.selectedRadio == 'Volume'
                ? this.geographyData[placeFeature.placeId].impacted_count_color
                : this.geographyData[placeFeature.placeId]
                    .impacted_percent_color
              : '#fff';
            return {
              fillColor,
              fillOpacity: 1,
              strokeColor: '#bebebe',
              strokeWeight: 0.5,
            };
          } else {
            return {
              fillColor: '#fff',
              fillOpacity: 1,
              strokeColor: '#bebebe',
              strokeWeight: 0.5,
            };
          }
        };
        this.mapsService?.removeContent?.remove();
        this.mapsService?.showContent?.remove();
        this.mapsService.showContent =
          this.mapsService.featureLayer.addListener('mousemove', (event) => {
            var content;
            if (this.toggleValue === 'County') {
              content =
                '<div id="content">' +
                '<h4>' +
                this.geographyData[event.features[0].placeId]?.geo_level_2_nm +
                '</h4>' +
                '\n' +
                'Patients Impacted: ' +
                `${
                  this.selectedRadio === 'Volume'
                    ? this.geographyData[
                        event.features[0].placeId
                      ]?.total_impacted_pat_ct?.toLocaleString() || 0
                    : (this.geographyData[
                        event.features[0].placeId
                      ]?.percent_impacted_pat_ct?.toLocaleString() || 0) + ' %'
                }` +
                '<br>' +
                '</div>';
            } else {
              content =
                '<div id="content">' +
                '<h4>' +
                this.geographyData[event.features[0].placeId]?.geo_level_2_nm +
                '</h4>' +
                '\n' +
                'Patients Impacted: ' +
                `${
                  this.selectedRadio === 'Volume'
                    ? this.geographyData[
                        event.features[0].placeId
                      ]?.total_impacted_pat_ct?.toLocaleString() || 0
                    : (this.geographyData[
                        event.features[0].placeId
                      ]?.percent_impacted_pat_ct?.toLocaleString() || 0) + ' %'
                }` +
                '<br>' +
                'ZIPs Impacted: ' +
                `${
                  this.selectedRadio === 'Volume'
                    ? this.geographyData[
                        event.features[0].placeId
                      ]?.total_impacted_geo_ct?.toLocaleString()
                    : (this.geographyData[
                        event.features[0].placeId
                      ]?.percent_impacted_geo_ct?.toLocaleString() || 0) + ' %'
                } ` +
                '</div>';
            }

            if (this.geographyData[event.features[0].placeId]) {
              this.mapsService?.infowindow.setContent(content);

              this.mapsService?.infowindow.setPosition(
                new google.maps.LatLng(
                  event.latLng.lat() + 0.5,
                  event.latLng.lng()
                )
              );
              this.mapsService?.infowindow.open(this.mapsService.map);
            } else {
              this.mapsService?.infowindow.close();
            }
          });
        this.mapsService.removeContent = this.mapsService.map.addListener(
          'mouseout',
          (event) => {
            this.mapsService.infowindow.close();
          }
        );
      }
    }

    if (
      ((changes['geographyData'] && !changes['geographyData'].firstChange) ||
        changes['location']) &&
      this.mapsService?.map
    ) {
      this.geocoder = new google.maps.Geocoder();
      if (this.location?.selectedValue !== 'All Locations (National View)') {
        var searchAddress: string;
        if (
          this.location.geoLevels['geo_level_3'] === 'county' &&
          this.location.selectedGeoLevel == 3
        ) {
          searchAddress =
            this.location.selectedValue.split(',')[0].trim() +
            ' county, ' +
            this.location.selectedValue.split(',')[1].trim();
        } else {
          searchAddress = this.location.selectedValue;
        }
        this.geocoder.geocode(
          { address: searchAddress + ', USA' },
          (results: any, status: any) => {
            if (status === 'OK' && results[0].geometry) {
              this.stateCoordinates = results[0].geometry?.location;

              this.mapsService.map.setCenter(this.stateCoordinates);

              const bounds = results[0].geometry.bounds;

              if (bounds) {
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();

                const zoomLevel = this.calculateZoomLevel(ne, sw);

                if (this.location?.selectedValue.includes(',')) {
                  this.mapsService.map.setZoom(zoomLevel);
                } else {
                  this.mapsService.map.setZoom(zoomLevel + 1);
                }
              }
            } else {
              console.error(
                'Geocode was not successful for the following reason: ' + status
              );
            }
          }
        );
      } else {
        if (this.mapsService.map) {
          this.mapsService.map.setCenter({ lat: 39.8283, lng: -98.5795 });
          this.mapsService.map.setZoom(4);
        }
      }

      if (this.calledFrom === 'patientSegment') {
        this.mapsService?.removeGeoContent?.remove();
        this.mapsService?.showGeoContent?.remove();

        this.mapsService.showGeoContent =
          this.mapsService?.map.data.addListener('mouseover', (event) => {
            const content =
              '<div id="content">' +
              '<h4>' +
              event.feature.getProperty('state') +
              ' : ' +
              `${
                event.feature.getProperty('zip')
                  ? event.feature.getProperty('zip')
                  : event.feature.getProperty('county')
                  ? event.feature.getProperty('county')
                  : event.feature.getProperty('county_name')
                  ? event.feature.getProperty('county_name')
                  : null
              }` +
              '</h4>' +
              '\n' +
              `
            ${
              this.geographyData.selectedRadio === 'gpv'
                ? 'Total Patients Impacted per Volume :<b>' +
                  event.feature.getProperty('value').toLocaleString() +
                  '</b>'
                : 'Total Patient Impacted: <b>' +
                  event.feature.getProperty('value').toLocaleString() +
                  this.geographyData.selectedRadio +
                  '</b>'
            }
            ` +
              '</div>';
            this.mapsService?.geoInfowindow.setContent(content);

            const lat =
              this.location.selectedGeoLevel == 3
                ? event.latLng.lat() + 0
                : event.latLng.lat() + 0.2;

            this.mapsService?.geoInfowindow.setPosition(
              new google.maps.LatLng(lat, event.latLng.lng())
            );
            this.mapsService?.geoInfowindow.open(this.mapsService.map);
          });
        this.mapsService.removeGeoContent =
          this.mapsService.map.data.addListener('mouseout', (event) => {
            this.mapsService?.geoInfowindow.close();
          });
      }

      if (changes['geographyData'] && !changes['geographyData'].firstChange) {
        // this.prepareChart();
        this.mapsService.map.setOptions({ maxZoom: 22 });

        for (var i = 0; i < this.mapsService.clsmarkers.length; i++) {
          this.mapsService.clsmarkers[i].setMap(null);
        }
        this.mapsService.clsmarkers = [];

        if (this.mapsService.showGeoContent) {
          this.mapsService.showGeoContent.remove();
        }
        if (this.mapsService.removeGeoContent) {
          this.mapsService.removeGeoContent.remove();
        }

        if (this.mapsService.removeContent) {
          this.mapsService.removeContent.remove();
        }
        if (this.mapsService.showContent) {
          this.mapsService.showContent.remove();
        }

        this.mapsService.featureLayer.style = (featureStyleFunctionOptions) => {
          return {
            fillColor: '#fff',
            fillOpacity: 1,
            strokeColor: '#bebebe',
            strokeWeight: 0.5,
          };
        };
        this.mapsService.map.data.forEach((feature) => {
          this.mapsService.map.data.remove(feature);
        });

        if (this.mapFor === 'clusterMarking') {
          this.mapsService.map.setOptions({ maxZoom: 18 });

          if (this.geographyData) {
            var filteredFeatures = this.filterFeaturesData(this.geographyData);
            this.mapsService.drawClusterMarkers(
              filteredFeatures,
              this.mapListFor
            );
          }

          this.mapsService.map.addListener('bounds_changed', () => {
            if (this.mapsService.clickedOnMarker) {
              this.mapsService.clickedOnMarker = false;
            } else {
              if (this.geographyData) {
                var filteredFeatures = this.filterFeaturesData(
                  this.geographyData
                );
                this.mapsService.drawClusterMarkers(
                  filteredFeatures,
                  this.mapListFor
                );
              }
            }
          });
        } else if (this.mapFor === 'heat') {
          this.mapsService.drawHeatMap(
            this.geographyData,
            this.selectedRadio,
            this.calledFrom,
            this.toggleValue
          );

          if (this.mapsService.stateClick) {
            this.mapsService.stateClick.remove();
          }
          this.mapsService.stateClick =
            this.mapsService.featureLayer.addListener('click', (event) => {
              if (
                this.geographyData[event.features[0].placeId] &&
                this.geographyData[event.features[0].placeId].geo_level_2_nm !=
                  this.location.selectedValue
              ) {
                this.location.selectedValue =
                  this.geographyData[event.features[0].placeId]?.geo_level_2_nm;
                this.location.backendValue = [
                  {
                    state:
                      this.geographyData[event.features[0].placeId]
                        ?.geo_level_2_nm,
                  },
                ];
                this.location.selectedGeoLevel = 2;
                this.location.subNationId = this.location.geo_level_2.find(
                  (c) =>
                    c.name ===
                    this.geographyData[event.features[0].placeId]
                      ?.geo_level_2_nm
                )['sub_nation_id'];
                this.location.backendValue = [
                  { state: this.location?.selectedValue },
                ];
                //updating just selectedValue and backend field in locationData$
                this.careGapService.locationData$.next({
                  ...this.careGapService.locationData$.getValue(),
                  selectedValue:
                    this.geographyData[event.features[0].placeId]
                      ?.geo_level_2_nm,
                  backendValue: this.location.backendValue,
                  selectedGeoLevel: this.location.selectedGeoLevel,
                  subNationId: this.location.subNationId,
                  callStages: true,
                });
              }
            });
        } else {
          if (this.mapsService.showGeoContent) {
            this.mapsService.showGeoContent.remove();
          }
          if (this.mapsService.removeGeoContent) {
            this.mapsService.removeGeoContent.remove();
          }

          if (Object.keys(this.geographyData).length > 0) {
            this.mapsService.drawZipCodeBoundary(
              this.geographyData,
              this.location.selectedGeoLevel
            );
          }

          if (this.mapsService.countyZipClick) {
            this.mapsService.countyZipClick.remove();
          }
          this.mapsService.countyZipClick =
            this.mapsService.map.data.addListener('click', (eve) => {
              let geo_level_3 = eve.feature.getProperty('zip')
                ? eve.feature.getProperty('zip')
                : eve.feature.getProperty('county')
                ? eve.feature.getProperty('county')
                : eve.feature.getProperty('county_name')
                ? eve.feature.getProperty('county_name')
                : null;
              let city = eve.feature.getProperty('state');
              let geo_level_2 = '';
              if (this.toggleValue === 'County') {
                geo_level_2 = this.location.geo_level_3.find(
                  (c) => c.name === geo_level_3 && c.geo_level_2 === city.trim()
                )['geo_level_2'];

                this.location.subNationId = this.location.geo_level_3.find(
                  (c) => c.name === geo_level_3 && c.geo_level_2 === city.trim()
                )['sub_nation_id'];
              } else {
                geo_level_2 = this.location.geo_level_3.find(
                  (c) => c.name === geo_level_3
                )['geo_level_2'];

                this.location.subNationId = this.location.geo_level_3.find(
                  (c) => c.name === geo_level_3
                )['sub_nation_id'];
              }

              if (
                geo_level_3 + ', ' + geo_level_2 !==
                this.location.selectedValue
              ) {
                this.location.selectedValue = geo_level_3 + ', ' + geo_level_2;
                this.location.backendValue = [
                  {
                    county: geo_level_3,
                    state: geo_level_2,
                  },
                ];
                this.location.selectedGeoLevel = 3;
                //updating just selectedValue and backend value field in locationData$
                this.careGapService.locationData$.next({
                  ...this.careGapService.locationData$.getValue(),
                  selectedValue: this.location.selectedValue,
                  backendValue: this.location.backendValue,
                  selectedGeoLevel: this.location.selectedGeoLevel,
                  subNationId: this.location.subNationId,
                  callStages: true,
                });
              }
            });
        }

        if (this.calledFrom === 'caregap' || this.calledFrom === 'barrier') {
          if (this.calledFrom === 'caregap') {
            imgName = 'Primary-ZS-Teal.svg';
            x = `<div id="legend" > ${
              this.selectedRadio === 'Volume' ? 'Number of' : 'Percentage'
            } Patients Impacted: <span><img src=../../../assets/images/${imgName} alt=${imgName} /></span> </div>`;
            divElement = document.createElement('div');
            divElement.innerHTML = x;
          } else if (this.calledFrom === 'barrier') {
            imgName = this.getBarrierImage(this.barrierCategory);
            x = `<div id="legend" > Number of Patients Impacted: <span><img src=../../../assets/images/${imgName} alt=${imgName} /></span> </div>`;
            divElement = document.createElement('div');
            divElement.innerHTML = x;
          }

          if (
            this.mapsService.map.controls[
              google.maps.ControlPosition.LEFT_BOTTOM
            ].getLength() > 0
          ) {
            this.mapsService.map.controls[
              google.maps.ControlPosition.LEFT_BOTTOM
            ].pop();
          }
          if (this.mapFor !== 'clusterMarking') {
            this.mapsService.map.controls[
              google.maps.ControlPosition.LEFT_BOTTOM
            ].push(divElement);
          }
        }
      }
    }
  }
  ngOnInit(): void {
    this.geocoder = new google.maps.Geocoder();

    // this.location = localStorage.getItem()
    // this.prepareChart();
  }

  filterFeaturesData(geoData) {
    var rawBounds = this.mapsService.map?.getBounds();

    var margin =
      this.mapsService.map.getZoom() > 10
        ? 0
        : this.mapsService.map.getZoom() > 7
        ? 2
        : 5;
    var bounds = {
      north: rawBounds?.getNorthEast().lat() + margin,
      south: rawBounds?.getSouthWest().lat() - margin,
      east: rawBounds?.getNorthEast().lng() + margin,
      west: rawBounds?.getSouthWest().lng() - margin,
    };

    var filteredFeatures = geoData?.features?.filter(function (feature) {
      var geometry = feature.geometry;

      if (geometry.type === 'Point') {
        var coordinates = new google.maps.LatLng(
          geometry.coordinates[1],
          geometry.coordinates[0]
        );
        return (
          coordinates.lat() >= bounds.south &&
          coordinates.lat() <= bounds.north &&
          coordinates.lng() >= bounds.west &&
          coordinates.lng() <= bounds.east
        );
      }

      return false;
    });

    return filteredFeatures;
  }

  ngAfterViewInit() {
    this.prepareChart();
  }

  getBarrierImage(category) {
    switch (category) {
      case 'All Categories':
        return 'Primary-ZS-Teal.svg';

      case 'Access to HIV Care':
      case 'SDoH Factor':
        return 'Dutch.svg';

      case 'Clinical Features':
      case 'Clinical Events':
        return 'Verditer.svg';

      case 'Demographics and Lifestyle':
      case 'Hospitals & HCPs':
        return 'Carmine.svg';

      case 'HIV Promotions':
      case 'Access and Affordability':
        return 'ZS_Orange_60.svg';

      case 'Socioeconomic Status':
        return 'Canterbury.svg';

      case 'Insurance Coverage':
        return 'Ruby.svg';

      default:
        return 'Primary-ZS-Teal.svg';
    }
  }

  prepareChart(): void {
    var mapStyle = [
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'all',
        elementType: 'all',
        stylers: [{ visibility: 'off' }, { color: '#C0C0C0' }],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }, { color: '#fcfcfc' }],
      },
      {
        featureType: 'water',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'administrative.country',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.province',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ];

    const mapOptions: google.maps.MapOptions = {
      center:
        this.location?.selectedValue !== 'All Locations (National View)'
          ? this.stateCoordinates
          : { lat: 39.8283, lng: -98.5795 },
      zoom: 4,
      styles: mapStyle,
      streetViewControl: false,
      mapId: '77608e3685d9b0f5',
      disableDefaultUI: true,
      scaleControl: true,
      zoomControl: true,
      fullscreenControl: true,
      gestureHandling: 'cooperative',
      backgroundColor: 'white',
    } as google.maps.MapOptions;

    this.ngZone.runOutsideAngular(() => {
      this.mapsService.initializeNewMap(
        this.mapContainer.nativeElement,
        mapOptions
      );

      // this.ngZone.run(() => {
      //   if (this.mapFor !== 'heat') {
      //     this.mapsService.drawZipCodeBoundary(zipCodeCoordinates);
      //   }
      // });
    });
  }
}
