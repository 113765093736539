import { Component, OnInit, ElementRef, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
});
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @Input() patientData: any;
  @Input() isLoaded = false;
  @Input() chartId: string;
  chart;
  options: any = {
    chart: {
      type: 'bar',
      marginTop: 20,
      marginBottom: 20,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: '400',
          color: '#000000',
          wordWrap: 'break-word',
          width: '120px',
          'min-width': '120px',
        },
      },
    },
    yAxis: {
      title: {
        text: null,
        style: {
          fontSize: '14px',
          color: '#000000',
          fontWeight: '400',
        },
      },
      labels: {
        format: undefined,
      },
      visible: false,
      gridLineWidth: '20',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        color: '#27A6A4',
        data: [],
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return this.x + ': ' + this.y;
      },
    },
  };
  constructor(private elementRef: ElementRef) {}
  ngOnInit() {
    if (this.isLoaded) {
      this.createElement();
      // this.generateChartData();
      // this.displayBarChart();
    }
  }

  createElement(): void {
    const elem = this.elementRef.nativeElement.querySelector('.cgBarGraph');
    elem.setAttribute('id', this.chartId);
    this.generateChartData();
  }
  generateChartData() {
    this.patientData.forEach((item) => {
      this.options.xAxis.categories.push(item.caregap_name);
      this.options.series[0].data.push(item.total_impacted_pat_ct);
    });
    this.options.chart.height = this.options.series[0].data.length * 20 + 160;
    this.displayBarChart();
  }
  displayBarChart(): void {
    this.chart = Highcharts.chart(this.chartId, this.options);
  }
}
